export enum ReportType {
  Completion,
  BulkCertificates,
  Assessment,
  Score,
  SSRS_Assessment,
  SuperIndendent,
  Ineligibility,
  ProgramActivity,
}

export interface Reports {
  title: string;
  subTitle: string;
  description: string;
  generationLabel: string;
  contentTypeId: number;
  reportType: ReportType;
  showDates: boolean;
  selectMultiple: boolean;
  showHelperText: boolean;
  showFailuresFlag: boolean;
  roles?: string[];
  formats: string[];
}

export const TranslateReportName = ({
  reportName,
  contentTypeId,
}: {
  reportName: string;
  contentTypeId?: string | number | undefined | null;
}): string => {
  switch (contentTypeId) {
    case 14:
      return 'Craft Completions';
    case 13:
      return 'Level Completions';
    case 9:
      return 'Module Completions';
  }

  if (reportName == 'Reports::Ineligibility') {
    return 'Retest Ineligibility';
  }

  if (reportName == 'Reports::FullProgramActivityReport') {
    return 'Full Program Activity';
  }

  if (reportName === 'PerformanceSubmissionReport') {
    return 'Performance Report';
  }

  return 'Undetermined';
};

export default Reports;
