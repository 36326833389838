import routes from 'store/configs/Routes';
import TenantConfig from 'store/types/TenantConfig';
import { INTERNAL_IDP_TYPE } from 'config';
import SiteModule from 'store/enums/SiteModule';
import MenuConfigItem from 'store/types/MenuConfigItem';
import { CurrentUser } from 'store/types/User';
import { SidebarSiteConfigItem } from 'store/types/SiteConfig';
import moduleRoutesConfig from 'store/configs/ModuleRoutesConfig';
import { tenantConfig } from 'config';
import {
  getLocalStorageValue,
  IMPERSONATION_FLAG_LS_LABEL,
  SUPER_ADMIN_DETAILS_FLAG_LS_LABEL,
  SUPER_ADMIN_FLAG_LS_LABEL,
} from './LocalStorage';
import AuthService from '../services/AuthService';
import authClient from '../services/AuthService';

export const getHashRouteUrl = (route: string): string => `${routes.root}${route}`;

export const isInternalIdpType = ({ idpType }: TenantConfig): boolean => idpType === INTERNAL_IDP_TYPE;

export const getExternalUrl = (url: string): string => (url.slice(0, 4).includes('http') ? url : `//${url}`);

export const getAppMenuConfig = (
  currentUser: CurrentUser,
  sidebar: SidebarSiteConfigItem[] = [],
  isNccerBillingApp?: boolean
): MenuConfigItem[] => {
  const result: MenuConfigItem[] = [];

  const getItemProps = ({ title, icon, type, url = '', tooltipText }: SidebarSiteConfigItem): MenuConfigItem => ({
    title,
    icon,
    type,
    tooltipText,
    ...(url
      ? {
          onClick: () => {
            window.open(getExternalUrl(url), '_blank');
          },
        }
      : {
          to: moduleRoutesConfig[type as SiteModule] || '',
        }),
  });

  const isMenuItemVisible = (menuItem: SidebarSiteConfigItem, user: CurrentUser): boolean => {
    let isVisibile = false;
    if (menuItem.visibleTo == null || menuItem.visibleTo.length == 0) {
      isVisibile = true;
    } else {
      user.roles?.forEach((role) => {
        if (menuItem.visibleTo.indexOf(role.role) > -1) {
          isVisibile = true;
        }
      });
    }
    return isVisibile;
  };

  sidebar.forEach((menuConfigItem) => {
    const canSee = isMenuItemVisible(menuConfigItem, currentUser);

    if (canSee) {
      const { children } = menuConfigItem;

      result.push({
        ...getItemProps(menuConfigItem),
        ...(children && {
          children: children
            .filter((item) => {
              return isMenuItemVisible(item, currentUser);
            })
            .map((item) => ({ ...getItemProps(item) })),
        }),
      });
    }
  });

  const isImpersonation = !!getLocalStorageValue(IMPERSONATION_FLAG_LS_LABEL);
  const isUserSuperAdmin = !!getLocalStorageValue(SUPER_ADMIN_FLAG_LS_LABEL);
  const superAdminDetails = getLocalStorageValue(SUPER_ADMIN_DETAILS_FLAG_LS_LABEL);

  if (!isImpersonation && isUserSuperAdmin) {
    result.push({
      title: 'Impersonation',
      key: 'impersonation',
      icon: 'camera_front',
      divider: false,
      onClick: () => window.open(tenantConfig.impersonationUrl, '_self'),
    });
  }

  const token: string = AuthService.getIdToken();
  const logoutLink: string = isImpersonation
    ? tenantConfig.reLoginUrl + encodeURIComponent(superAdminDetails)
    : isNccerBillingApp
    ? `${tenantConfig.logoutUrl}&id_token_hint=${token}`
    : '';

  if (!isImpersonation || !isNccerBillingApp) {
    result.push({
      title: 'Sign Out',
      key: 'logout',
      icon: 'logout',
      divider: true, //!isUserSuperAdmin || isNccerBillingApp,
      onClick: () => authClient.logout(logoutLink),
    });
  }

  return result;
};

export const getDefaultHomeRoute = (enabledModules: SiteModule[]): string =>
  enabledModules.includes(SiteModule.Home) ? routes.home : routes.profile;

export const handleOpenTabClick = (
  e: React.MouseEvent<HTMLElement> | null,
  link: string,
  addOpenTab: (window: Window | null) => void
) => {
  e?.preventDefault();
  const win = window.open(link, '_blank');
  addOpenTab(win);
};
