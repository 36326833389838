import { AUTH_API_URL, API_URL, tenantConfig } from 'config';
import { HttpError, HttpService } from 'services/HttpService';
import { httpRequestWrapper } from 'util/Request';
import { UserFormValues } from 'store/types/FormValues';

interface SetPasswordRequestData {
  email: string;
  password: string;
  token: string;
}

export interface LoginRequestData {
  username: string;
  password: string;
}

export interface LoginResponse {
  AccessToken: string;
  IdToken: string;
}

export interface FirstLoginResponse {
  Session: string;
}

export type LoginResponseData = LoginResponse | FirstLoginResponse;

class LoginService {
  public static login({ username, password }: LoginRequestData): Promise<LoginResponseData> {
    return new Promise<LoginResponseData>(
      (resolve: (data: LoginResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<LoginResponseData>(
          HttpService.post(`${AUTH_API_URL}/login`, {
            data: {
              Username: username,
              Password: password,
              Admin: false,
            },
            headers: {
              TenantId: tenantConfig.id,
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static firstLogin({ username, password }: LoginRequestData): Promise<LoginResponseData> {
    return new Promise<LoginResponseData>(
      (resolve: (data: LoginResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<LoginResponseData>(
          HttpService.post(`${AUTH_API_URL}/login`, {
            data: {
              Username: username,
              Password: password,
              Admin: false,
            },
            headers: {
              TenantId: tenantConfig.id,
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static signup(data: UserFormValues): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: HttpError) => void) => {
      HttpService.post(`${API_URL}/accounts`, {
        data,
        headers: {
          TenantId: tenantConfig.id,
        },
      })
        .toPromise()
        .then(() => {
          resolve(null);
        })
        .catch((error: HttpError) => {
          reject(error);
        });
    });
  }

  public static forgotPassword(email: string): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.post(`${API_URL}/account/forgot-password`, {
          data: { email },
          headers: {
            TenantId: tenantConfig.id,
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static setPassword(data: SetPasswordRequestData): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.post(`${API_URL}/account/password`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
          },
        }),
        resolve,
        reject
      );
    });
  }
}
export default LoginService;
