import { ThemeProvider } from '@mui/material/styles';
import AppPublicRoutes from 'components/AppPublicRoutes';
import { ConfigContext } from 'components/ConfigGuard';
import RouteScrollToTop from 'components/layout/RouteScrollToTop';
import React, { useContext, useEffect } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { addAppFavicons, nccerTheme } from 'util/Layout';

const AppContent: React.FunctionComponent = () => {
  const siteConfig = useContext(ConfigContext);

  useEffect(() => {
    document.body.classList.add('theme-nccer');
    addAppFavicons();
  }, [siteConfig]);

  const content: React.ReactNode = (
    <Router>
      <RouteScrollToTop />
      <AppPublicRoutes />
    </Router>
  );

  return <ThemeProvider theme={nccerTheme}>{content}</ThemeProvider>;
};
export default AppContent;
