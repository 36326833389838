import {
  Alert,
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { RefObject, useCallback, useContext, useEffect, useMemo } from 'react';
import { UserContext } from 'components/UserGuard';
import { getRequiredValidationRule, getValidationProps, defaultFormProps, getAddressFormValues } from 'util/Form';

import styles from './EditProfileForm.module.scss';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { UserFormValues } from 'store/types/FormValues';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import DateFormItem from 'components/shared/DateFormItem';
import moment from 'moment';
import { emptyUserFormValues } from 'util/User';
import UserService from 'services/api/UserService';
import { useSnackbar } from 'notistack';
import AddressFormSection from 'components/shared/AddressFormSection';
import { FileUploader } from 'react-drag-drop-files';
import { genders, nativeLanguages, races } from 'store/configs/Demographics';
import { alternateIds } from 'store/configs/AlternateIDs';
import Roles from 'store/enums/Roles';
import { useWindowSize } from 'util/Window';

const defaultValues: UserFormValues = { ...emptyUserFormValues };
interface EditProfileProps {
  submitRef: RefObject<HTMLButtonElement>;
  editFlag: () => void;
  setLoading: (flag: boolean) => void;
}

const EditProfileForm: React.FunctionComponent<EditProfileProps> = ({ submitRef, editFlag, setLoading }) => {
  const { isMobile } = useWindowSize();
  const userData = useContext(UserContext);
  const methods = useForm<UserFormValues>({ ...defaultFormProps, defaultValues });
  const {
    reset,
    control,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = methods;
  const validationProps = (label: keyof UserFormValues) => getValidationProps(label, errors);
  const { enqueueSnackbar } = useSnackbar();

  // Force re-render when user changes their CLMA release acceptance.
  watch('releaseCLMA');

  const [personRace, setPersonRace] = React.useState<string[]>(userData.race?.split(',') ?? []);
  const [personEthnicity, setPersonEthnicity] = React.useState<string>(userData.ethnicity ?? '');
  const [personGender, setPersonGender] = React.useState<string>(userData.gender ?? '');
  const [personNatLang, setPersonNatLang] = React.useState<string>(userData.nativeLanguage ?? '');
  const [personAltIdType, setPersonAltIdType] = React.useState<string>(userData.alternateIdType ?? '');

  const [releaseClmaNotice, setReleaseClmaNotice] = React.useState('');

  const isInstructor = useMemo(
    () => userData.roles?.some((role) => role.roleIsActive && role.role === Roles.Instructor),
    [userData.roles]
  );

  const handleRaceChange = (event: SelectChangeEvent<string[]>) => {
    setPersonRace(event.target.value as string[]);
  };
  const handleEthnicityChange = (event: SelectChangeEvent) => {
    setPersonEthnicity(event.target.value);
  };
  const handleGenderChange = (event: SelectChangeEvent) => {
    setPersonGender(event.target.value);
  };
  const handleNatLangChange = (event: SelectChangeEvent) => {
    setPersonNatLang(event.target.value);
  };
  const handleAltIdTypeChange = (event: SelectChangeEvent) => {
    setPersonAltIdType(event.target.value);
  };

  const handlePhotoSelection = (file: File) => {
    userData.photoData = file;
  };

  const handleChangeReleaseClma = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (userData.releaseCLMA === true) {
      // Display notice if user is already accepted.
      setReleaseClmaNotice("To opt-out please contact NCCER's customer support team.");
    } else {
      // Modify CLMA release acceptance.
      setValue('releaseCLMA', event.target.checked, { shouldDirty: true, shouldTouch: true });
    }
  };

  useEffect(() => {
    const {
      firstName,
      lastName,
      middleInitial,
      email,
      phone,
      address,
      birthDate,
      gender,
      birthCity,
      ethnicity,
      title,
      race,
      nativeLanguage,
      alternateId,
      alternateIdType,
      releaseCLMA,
    } = userData;

    reset({
      firstName: firstName || '',
      lastName: lastName || '',
      middleInitial: middleInitial || '',
      phone: phone || '',
      email: email || '',
      address: getAddressFormValues(address),
      birthDate: birthDate || null,
      birthCity: birthCity || null,
      gender: gender || null,
      ethnicity: ethnicity || null,
      title: title || null,
      race: race || null,
      nativeLanguage: nativeLanguage || null,
      alternateId: alternateId || null,
      alternateIdType: alternateIdType || null,
      releaseCLMA: releaseCLMA || false,
    });
  }, [userData, reset]);

  const saveUserProfile = useCallback(async () => {
    setLoading(true);
    const userToSave = { id: userData.id, ...methods.getValues() };
    userToSave.race = personRace.join(',');
    userToSave.ethnicity = personEthnicity;
    userToSave.gender = personGender;
    userToSave.nativeLanguage = personNatLang;
    userToSave.alternateIdType = personAltIdType;
    userToSave.photoData = userData.photoData;
    try {
      await UserService.updateCurrentUser(userToSave);
      if (userToSave.photoData !== null && userToSave.photoData !== undefined) {
        await UserService.updateCurrentPhoto(userToSave.photoData);
      }
      userData.refetchUser();
      editFlag();
      setLoading(false);
      enqueueSnackbar('Updates saved successfully.');
    } catch (error) {
      editFlag();
      setLoading(false);
      enqueueSnackbar(error);
    }
  }, [
    setLoading,
    userData,
    methods,
    personRace,
    personEthnicity,
    personGender,
    personNatLang,
    personAltIdType,
    enqueueSnackbar,
    editFlag,
  ]);

  return (
    <FormProvider {...methods}>
      <div className={styles.profileEditContainer}>
        {/* NCCER Number */}
        <Grid {...defaultGridContainerProps} className={styles.row}>
          <Grid {...defaultGridItemProps} md={5}>
            <Typography variant={'h6'} className={styles.rowLabel}>
              NCCER Number
            </Typography>
          </Grid>
          <Grid {...defaultGridItemProps} md={7}>
            <Typography paragraph={true} className={styles.standaloneValue}>
              {userData.nccerCardNumber}
            </Typography>
          </Grid>
        </Grid>
        {!isMobile && <div className={styles.divider}></div>}
        {/* Title */}
        <Grid {...defaultGridContainerProps} className={styles.row}>
          <Grid {...defaultGridItemProps} md={5}>
            <Typography variant={'h6'} className={styles.rowLabel}>
              Title
            </Typography>
          </Grid>
          <Grid {...defaultGridItemProps} md={7}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  {...validationProps('title')}
                  required={true}
                  disabled={false}
                  className={styles.inputField}
                />
              )}
              name={'title'}
              control={control}
              rules={{
                required: getRequiredValidationRule('title', false, true),
              }}
            />
          </Grid>
        </Grid>
        {!isMobile && <div className={styles.divider}></div>}
        {/* Contact Information Section */}
        <Grid {...defaultGridContainerProps} className={styles.row}>
          <Grid {...defaultGridItemProps} md={5}>
            <Typography variant={'h6'} className={styles.rowLabel}>
              Contact Information
            </Typography>
          </Grid>
          <Grid {...defaultGridItemProps} {...defaultGridContainerProps} md={7}>
            <Grid {...defaultGridItemProps} md={6}>
              <Typography variant={'h5'} className={styles.fieldName}>
                First Name
              </Typography>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    {...validationProps('firstName')}
                    required={true}
                    disabled={false}
                    className={styles.inputFieldLeft}
                  />
                )}
                name={'firstName'}
                control={control}
                rules={{
                  required: getRequiredValidationRule('first name', false, true),
                }}
              />
            </Grid>
            <Grid {...defaultGridItemProps} md={6}>
              <Typography variant={'h5'} className={styles.fieldName}>
                Last Name
              </Typography>
              <Typography paragraph={true} className={styles.fieldValue}>
                {userData.lastName}
              </Typography>
            </Grid>
            <Grid {...defaultGridItemProps} md={6}>
              <Typography variant={'h5'} className={styles.fieldName}>
                Email Address
              </Typography>
              <Typography paragraph={true} className={styles.bottomFieldValue}>
                {userData.email}
              </Typography>
            </Grid>
            <Grid {...defaultGridItemProps} md={6}>
              <Typography variant={'h5'} className={styles.fieldName}>
                Phone Number
              </Typography>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    {...validationProps('phone')}
                    required={true}
                    disabled={false}
                    className={styles.inputFieldLeft}
                  />
                )}
                name={'phone'}
                control={control}
                rules={{
                  required: getRequiredValidationRule('phone', false, true),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {!isMobile && <div className={styles.divider}></div>}
        {/* Personal Information */}
        <Grid {...defaultGridContainerProps} className={styles.row}>
          <Grid {...defaultGridItemProps} md={5}>
            <Typography variant={'h6'} className={styles.rowLabel}>
              Personal Details
            </Typography>
            <Grid {...defaultGridItemProps} md={5} className={styles.rowDescription}>
              {'We use this info to better understand our users.'}
            </Grid>
          </Grid>
          <Grid {...defaultGridItemProps} {...defaultGridContainerProps} md={7}>
            <Grid {...defaultGridItemProps}>
              <Typography variant={'h5'} className={styles.fieldName}>
                Date of Birth
              </Typography>
              <DateFormItem
                disabled={false}
                fieldName={'birthDate'}
                maxDate={moment(new Date()).startOf('day')}
                required={false}
                className={styles.inputField}
              />
            </Grid>
            <Grid {...defaultGridItemProps} md={4} className={styles.selectFieldWrapper}>
              <Typography variant={'h5'} className={styles.fieldName}>
                Race
              </Typography>
              <Controller
                render={({ field }) => (
                  <Select
                    {...field}
                    multiple
                    value={personRace}
                    onChange={handleRaceChange}
                    className={styles.inputField}
                    renderValue={(selected) => selected.join(', ')}
                  >
                    {races.map((value) => (
                      <MenuItem key={value} value={value}>
                        <Checkbox checked={personRace.indexOf(value) > -1} />
                        <ListItemText primary={value} />
                      </MenuItem>
                    ))}
                  </Select>
                )}
                name={'race'}
                control={control}
                rules={{
                  required: getRequiredValidationRule('race', true, false),
                }}
              />
            </Grid>
            <Grid {...defaultGridItemProps} md={4} className={styles.selectFieldWrapper}>
              <Typography variant={'h5'} className={styles.fieldName}>
                Ethnicity
              </Typography>
              <Controller
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth={true}
                    value={personEthnicity}
                    className={styles.inputField}
                    onChange={handleEthnicityChange}
                  >
                    <MenuItem value={'Hispanic or Latino'}>Hispanic or Latino</MenuItem>
                    <MenuItem value={'Not Hispanic or Latino'}>Not Hispanic or Latino</MenuItem>
                  </Select>
                )}
                name={'ethnicity'}
                control={control}
                rules={{
                  required: getRequiredValidationRule('ethnicity', true, false),
                }}
              />
            </Grid>
            <Grid {...defaultGridItemProps} md={4} className={styles.selectFieldWrapper}>
              <Typography variant={'h5'} className={styles.fieldName}>
                Gender
              </Typography>
              <Controller
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth={true}
                    value={personGender}
                    className={styles.inputField}
                    onChange={handleGenderChange}
                  >
                    {genders.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value === 'Undisclosed' ? <>{'Prefer not to say'}</> : value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                name={'gender'}
                control={control}
                rules={{
                  required: getRequiredValidationRule('gender', true, false),
                }}
              />
            </Grid>
            <Grid {...defaultGridItemProps} className={styles.selectFieldWrapper}>
              <Typography variant={'h5'} className={styles.fieldName}>
                Native Language
              </Typography>
              <Controller
                render={({ field }) => (
                  <Select {...field} value={personNatLang} className={styles.inputField} onChange={handleNatLangChange}>
                    {nativeLanguages.map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                name={'nativeLanguage'}
                control={control}
                rules={{
                  required: getRequiredValidationRule('native language', true, false),
                }}
              />
            </Grid>
            {!userData.applyFerpaAgeRestriction && (
              <Grid {...defaultGridItemProps}>
                {/* make this header grey and match styling*/}
                <Typography variant={'h5'} className={styles.parentFieldName}>
                  Current Address
                </Typography>
                <AddressFormSection />
              </Grid>
            )}
            {/* bottom of section */}
            <Grid {...defaultGridItemProps}>
              <Typography variant={'h5'} className={styles.fieldName}>
                Birth Place
              </Typography>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    {...validationProps('birthCity')}
                    required={false}
                    disabled={false}
                    className={styles.inputField}
                  />
                )}
                name={'birthCity'}
                control={control}
                rules={{
                  required: getRequiredValidationRule('birth city', false, true),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {!isMobile && <div className={styles.divider}></div>}
        {/* Construction Labor Market Analyzer (CLMA) Release */}
        {isInstructor && (
          <>
            <Stack spacing={2}>
              <Typography variant={'h6'} className={styles.rowLabel}>
                Construction Labor Market Analyzer (CLMA) Release
                <Typography variant={'caption'} pl={1} fontStyle={'italic'}>
                  (For secondary, post secondary, technical, and career college instructors.)
                </Typography>
              </Typography>
              {releaseClmaNotice && <Alert severity={'info'}>{releaseClmaNotice}</Alert>}
              <Stack direction={'row'} spacing={1} alignItems={'start'}>
                <Checkbox checked={getValues('releaseCLMA')} onChange={handleChangeReleaseClma} />
                <Stack spacing={1}>
                  <Typography>
                    I hereby authorize NCCER to share the personal identifiable information (PII) listed in the contact
                    information section of my profile with Construction Industry Resources, LLC (CIR) for use in the
                    Construction Labor Market Analyzer (CLMA) platform. The CLMA is a powerful, predictive analytics
                    platform that helps owners, contractors, and labor providers confidently understand the construction
                    labor market. Through data analysis, the CLMA can foresee labor shortages in specific sectors of the
                    construction industry.
                  </Typography>
                  <Typography>
                    By sharing my information with CIR for use in the CLMA platform, construction stakeholders
                    (contractors, owners, etc.) will be able to contact me to offer great career opportunities to my
                    students to help bridge that gap.
                  </Typography>
                  <Typography>
                    To opt-out after checking the box, please contact NCCER's customer support team.
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            {!isMobile && <div className={styles.divider}></div>}
          </>
        )}
        {/* Alternate ID */}
        <Grid {...defaultGridContainerProps} className={styles.row}>
          <Grid {...defaultGridItemProps} md={5}>
            <Typography variant={'h6'} className={styles.rowLabel}>
              Alternate ID
            </Typography>
            <Grid {...defaultGridItemProps} md={5} className={styles.rowDescription}>
              {'Use your alternate ID to retrieve your NCCER number when you forget your password.'}
            </Grid>
          </Grid>
          <Grid {...defaultGridItemProps} {...defaultGridContainerProps} md={7}>
            <Grid {...defaultGridItemProps} md={6}>
              <Typography variant={'h5'} className={styles.fieldName}>
                Type
              </Typography>
              <Controller
                render={({ field }) => (
                  <Select
                    {...field}
                    value={personAltIdType}
                    className={styles.inputField}
                    onChange={handleAltIdTypeChange}
                  >
                    {alternateIds.map((value) => (
                      <MenuItem key={value.code} value={value.code}>
                        {value.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                name={'alternateIdType'}
                control={control}
                rules={{
                  required: getRequiredValidationRule('alternateIdType', true, false),
                }}
              />
            </Grid>
            <Grid {...defaultGridItemProps} md={6}>
              <Typography variant={'h5'} className={styles.fieldName}>
                Alternate ID Number
              </Typography>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    {...validationProps('alternateId')}
                    required={false}
                    disabled={false}
                    className={styles.inputField}
                  />
                )}
                name={'alternateId'}
                control={control}
                rules={{
                  required: getRequiredValidationRule('alternateId', false, true),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {!isMobile && <div className={styles.divider}></div>}
        {/* Photo */}
        <Grid {...defaultGridContainerProps} className={styles.row}>
          <Grid {...defaultGridItemProps} md={5}>
            <Typography variant={'h6'} className={styles.rowLabel}>
              Photo
            </Typography>
          </Grid>
          <Grid {...defaultGridItemProps} {...defaultGridContainerProps} md={7}>
            <Grid {...defaultGridItemProps} className={styles.fileUploader}>
              <Controller
                render={({ field }) => (
                  <FileUploader
                    {...field}
                    handleChange={handlePhotoSelection}
                    name="file"
                    types={['JPG', 'JPEG', 'PNG', 'SVG']}
                  />
                )}
                name={'photoData'}
                control={control}
              />
            </Grid>
            <Grid {...defaultGridItemProps}>
              <Typography variant={'h5'} className={styles.fieldName}>
                Suggested Photo Guidelines
              </Typography>
            </Grid>
            <Grid {...defaultGridItemProps}>
              <ul className={styles.photoHelperList}>
                <li>{'Plain white or light gray background'}</li>
                <li>{'Subject facing directly toward camera; head and tops of shoulders visible'}</li>
                <li>{'Face should occupy about 1/2 of the width of the photo'}</li>
                <li>{'No hats, headgear, sunglasses, safety goggles, etc.'}</li>
                <li>{'Avoid highest quality setting; 70% to 80% max.'}</li>
              </ul>
            </Grid>
            {/* photo uploader and description */}
          </Grid>
        </Grid>
      </div>
      <button ref={submitRef} onClick={saveUserProfile} type="submit" style={{ display: 'none' }} />
    </FormProvider>
  );
};

export default EditProfileForm;
