export const pipelineTypes = [
  'ATMOS Pipeline',
  'Liquid Pipeline Field Ops',
  'Liquid Pipeline Control Ctr Ops',
  'Pipeline Core',
  'Pipeline Corrosion Control',
  'Pipeline Corrosion Technician',
  'Pipeline E&I',
  'Pipeline Electrical and Instrumentation Technician',
  'Pipeline Electrical & Instrumentation Technician',
  'Pipeline Maintenance',
  'Pipeline Maintenance Technician',
  'Pipeline Mechanical',
  'Pipeline Mechanical Technician',
  'Pipeline Operations Technician',
  'Pipeline OQ',
  'Pipeline Training and Qualifications Program',
  'Pipeline Training & Qualifications Program',
];
