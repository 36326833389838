import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { tenantConfig } from 'config';
import claimWalletCardIcon from 'assets/icons/claimWalletCard.svg';
import replaceWalletCardIcon from 'assets/icons/replaceWalletCard.svg';
import processingWalletCardIcon from 'assets/icons/processingWalletCard.svg';
import cardpos from 'assets/icons/card-pos.svg';
import { UserContext } from 'components/UserGuard';
import Modal from 'components/shared/Modal';
import CredentialService from 'services/api/CredentialService';
import routes from 'store/configs/Routes';
import Address from 'store/types/Address';
import { CurrentUser } from 'store/types/User';
import { defaultFormProps, isAddressInternational, willAddressShip } from 'util/Form';
import { isDefined } from 'util/Filter';
import { WALLET_CARD_POPUP_HIDDEN_LABEL, getExpDate } from 'util/LocalStorage';
import { errorMessage } from 'util/Request';

export function WalletCardButton() {
  const { pathname } = useLocation();
  const user = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(() => {
    const { isWalletCardClaimed, isWalletCardBatched } = walletCardStatusCalculation(user.walletCardStatus);

    return (
      user.canClaimWalletCard &&
      !isWalletCardClaimed &&
      !isWalletCardBatched &&
      isDefined(user.birthDate) &&
      pathname === routes.home &&
      !isSuppressedWalletCardPopup(user.nccerCardNumber)
    );
  });
  const [workflow, setWorkflow] = useState(() => ({ step: 0, steps: getWorkflow(user) }));

  const current = useMemo(() => workflow.steps[workflow.step], [workflow.step, workflow.steps]);

  const reset = useCallback(() => {
    setWorkflow({ step: 0, steps: getWorkflow(user) });
  }, [user]);

  const openModal = useCallback(() => {
    reset();
    setOpen(true);
  }, [reset]);

  const closeModal = useCallback(() => setOpen(false), []);

  const dismissModal = useCallback(() => {
    suppressWalletCardPopup(user.nccerCardNumber);
    closeModal();
  }, [closeModal, user.nccerCardNumber]);

  const moveNext = useCallback(() => {
    setWorkflow((e) => ({
      step: Math.max(0, Math.min(e.step + 1, e.steps.length - 1)),
      steps: e.steps,
    }));
  }, []);

  if (!workflow.steps.length) {
    return null;
  }

  return (
    <WalletCardContext.Provider value={{ setLoading, closeModal, dismissModal, moveNext }}>
      <Button
        variant="outlined"
        color="secondary"
        disabled={loading}
        onClick={openModal}
        sx={{ display: 'flex', gap: 1, width: { xs: '100%', sm: 'auto' } }}
      >
        <img src={cardpos} />
        <Box display={{ xs: 'inline', sm: 'none', md: 'inline' }}>Claim Wallet Card</Box>
      </Button>
      <Modal loading={loading} open={open} title={current.title} subTitleText={current.subtitle} onClose={closeModal}>
        {current.component}
      </Modal>
    </WalletCardContext.Provider>
  );
}

/**
 * Get the specific Claim Wallet Card workflow according to specific user criteria.
 *
 * @returns Array of workflow steps.
 *
 * @remarks
 * Unclaimed wallet card (default)
 *   Claim Card -> Address Confirmation (claim) -> Success
 * Unclaimed wallet card (age restricted / international)
 *   Batch card (batch) -> Success
 *
 * Claimed wallet card (default)
 *   Replace -> Address Confirmation (order replacement)
 * Claimed wallet card (age restricted / international)
 *   Batch replace (batch) -> Success
 *
 * Batched wallet card (age restricted / international)
 *   Success
 */
function getWorkflow({
  address,
  applyFerpaAgeRestriction,
  birthDate,
  canClaimWalletCard,
  walletCardStatus,
}: CurrentUser) {
  // Wallet card cannot be claimed.
  if (!canClaimWalletCard) {
    return [];
  }

  // Date of birth is not defined.
  if (!isDefined(birthDate)) {
    return [
      {
        title: 'Claim Wallet Card',
        subtitle: '',
        component: <EmptyDateOfBirth />,
      },
    ];
  }

  const { isWalletCardClaimed, isWalletCardBatched } = walletCardStatusCalculation(walletCardStatus);

  /// Wallet card has been batched but not processed yet.
  if (isWalletCardBatched) {
    return [
      {
        title: 'Claim Card through Organization',
        subtitle: batchSubtitle(address, applyFerpaAgeRestriction),
        component: <Success />,
      },
    ];
  }

  /// Wallet card has already been claimed.
  if (isWalletCardClaimed) {
    // Age restricted or international user (batch replace card).
    if (applyFerpaAgeRestriction || isAddressInternational(address)) {
      return [
        {
          title: 'Replace Card',
          subtitle: 'Looks like you have already claimed your wallet card. Click below to order a replacement card.',
          component: <BatchWalletCard icon={replaceWalletCardIcon} submitText="Replace Card" />,
        },
        {
          title: 'Claim Card through Organization',
          subtitle: batchSubtitle(address, applyFerpaAgeRestriction),
          component: <Success />,
        },
      ];
    }

    // Default user (replace card -> order replacement).
    return [
      {
        title: 'Replace Card',
        subtitle:
          'Looks like you have already claimed your free wallet card. Click below to purchase a replacement card.',
        component: <WalletCardIntro icon={replaceWalletCardIcon} />,
      },
      {
        title: 'Address confirmation',
        subtitle: 'Please confirm your address.',
        component: <ReplaceWalletCardAddressConfirmation address={address} />,
      },
    ];
  }

  /// Wallet card is neither claimed nor batched.
  // Age restricted or international user (batch card).
  if (applyFerpaAgeRestriction || isAddressInternational(address)) {
    return [
      {
        title: 'Claim Wallet Card',
        subtitle:
          'Good News! Your first wallet card is FREE and ready to be claimed. It will be sent to your organization or school.',
        component: <BatchWalletCard icon={claimWalletCardIcon} submitText="Claim My Card" />,
      },
      {
        title: 'Claim Card through Organization',
        subtitle: batchSubtitle(address, applyFerpaAgeRestriction),
        component: <Success />,
      },
    ];
  }

  // Default user (claim card).
  return [
    {
      title: 'Claim Wallet Card',
      subtitle:
        'Good News! Your first wallet card is FREE and ready to be claimed. It will be sent to the address in your account profile.',
      component: <WalletCardIntro icon={claimWalletCardIcon} />,
    },
    {
      title: 'Address confirmation',
      subtitle: 'Please confirm your address.',
      component: <ClaimWalletCardAddressConfirmation address={address} />,
    },
    {
      title: 'Your Card Is On Its Way',
      subtitle: 'Hang tight - we are working on printing your wallet card and it should be at your door step soon.',
      component: <Success />,
    },
  ];
}

interface WalletCardIntroProps {
  icon: string;
}

function WalletCardIntro({ icon }: WalletCardIntroProps) {
  const { dismissModal, moveNext } = useContext(WalletCardContext);

  return (
    <Box display="flex" flexDirection="column" gap={2} alignItems="center">
      <img src={icon} />

      <Box display="flex" flexDirection="column" gap={2} width={1} pt={4}>
        <Button color="primary" variant="contained" onClick={moveNext}>
          Continue
        </Button>
        <Button color="secondary" variant="outlined" onClick={dismissModal}>
          No Thanks
        </Button>
      </Box>
    </Box>
  );
}

function ClaimWalletCardAddressConfirmation({ address }: AddressProps) {
  const claimWalletCard = useClaimWalletCard();

  return <AddressConfirmation address={address} submitText="Claim My Card" onSubmit={claimWalletCard} />;
}

function ReplaceWalletCardAddressConfirmation({ address }: AddressProps) {
  const { closeModal } = useContext(WalletCardContext);

  const replaceWalletCard = useCallback(() => {
    // Route to nopCommerce.
    window.open(tenantConfig.replaceWalletCardUrl, '_blank')?.focus();
    closeModal();
  }, [closeModal]);

  return <AddressConfirmation address={address} submitText="Replace Card" onSubmit={replaceWalletCard} />;
}

interface BatchWalletCardProps {
  icon: string;
  submitText: string;
}

function BatchWalletCard({ icon, submitText }: BatchWalletCardProps) {
  const { dismissModal } = useContext(WalletCardContext);
  const batchWalletCard = useClaimWalletCard();

  return (
    <Box display="flex" flexDirection="column" gap={2} alignItems="center">
      <img src={icon} />

      <Button color="primary" variant="contained" fullWidth onClick={batchWalletCard} sx={{ mt: 2 }}>
        {submitText}
      </Button>
      <Button color="secondary" variant="outlined" fullWidth onClick={dismissModal}>
        No Thanks
      </Button>
    </Box>
  );
}

interface AddressProps {
  address: Address;
}

interface AddressConfirmationProps<T> extends AddressProps {
  submitText: string;
  onSubmit: () => T | Promise<T>;
}

function AddressConfirmation<T>({ address, submitText, onSubmit }: AddressConfirmationProps<T>) {
  const { closeModal } = useContext(WalletCardContext);

  const form = useForm({
    ...defaultFormProps,
    defaultValues: { addressAcknowledged: false },
  });
  const { formState, handleSubmit } = form;

  const validAddress = willAddressShip(address);
  const submitDisabled = !formState.isValid || !validAddress;

  return (
    <FormProvider {...form}>
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography variant="h5" pb={2}>
          Address
          {address ? (
            <>
              <Typography pt={1}>{address.street1}</Typography>
              <Typography>
                {address.city} {address.stateRegion} {address.postalCode}
              </Typography>
              <Typography>{address.country}</Typography>
            </>
          ) : (
            <Typography pt={1}>No address on file</Typography>
          )}
        </Typography>
        {validAddress ? (
          <>
            <Controller
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  inputRef={field.ref}
                  control={<Checkbox />}
                  label="I acknowledge that the above address is correct for shipping my wallet card."
                />
              )}
              name="addressAcknowledged"
              rules={{ required: true }}
            />
            <Typography>Click 'Settings' to edit your address.</Typography>
          </>
        ) : (
          <Typography>
            The address on your account profile is incomplete. Click 'Settings' to edit your address.
          </Typography>
        )}

        <Button
          color="secondary"
          variant="outlined"
          fullWidth
          component={Link}
          to={routes.settings}
          onClick={closeModal}
          sx={{ mt: 2 }}
        >
          Settings
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={submitDisabled}
          fullWidth
          onClick={handleSubmit(onSubmit)}
        >
          {submitText}
        </Button>
      </Box>
    </FormProvider>
  );
}

function EmptyDateOfBirth() {
  const { closeModal } = useContext(WalletCardContext);

  return (
    <Box display="flex" flexDirection="column" gap={2} alignItems="center">
      <img src={claimWalletCardIcon} />

      <Typography pt={2}>
        Looks like there is no date of birth on your account profile. Click 'Settings' to edit your profile and enter
        your date of birth.
      </Typography>

      <Button
        color="secondary"
        variant="outlined"
        fullWidth
        component={Link}
        to={routes.settings}
        onClick={closeModal}
        sx={{ mt: 2 }}
      >
        Settings
      </Button>
    </Box>
  );
}

function Success() {
  const { closeModal } = useContext(WalletCardContext);

  return (
    <Box display="flex" flexDirection="column" gap={2} alignItems="center">
      <img src={processingWalletCardIcon} />

      <Button color="secondary" variant="outlined" fullWidth sx={{ mt: 2 }} onClick={closeModal}>
        Close
      </Button>
    </Box>
  );
}

function batchSubtitle(address: Address | undefined, applyFerpaAgeRestriction: boolean) {
  if (isAddressInternational(address)) {
    return 'Wallet cards for individuals with international mailing addresses will be sent to their NCCER training program.';
  } else if (applyFerpaAgeRestriction) {
    return 'In accordance with privacy regulations, NCCER cannot collect or store home addresses for individuals under the age of 18. Your wallet card will be sent to your school.';
  } else {
    return 'Your wallet card is being mailed to your organization. Please claim it from them.';
  }
}

// Suppress automatic wallet card popup on page load.
function suppressWalletCardPopup(nccerCardNumber: string | undefined): void {
  const key = WALLET_CARD_POPUP_HIDDEN_LABEL + nccerCardNumber;
  // Suppress popup for a day.
  const timestamp = getExpDate(1);
  localStorage.setItem(key, String(timestamp));
}

function isSuppressedWalletCardPopup(nccerCardNumber: string | undefined): boolean {
  const key = WALLET_CARD_POPUP_HIDDEN_LABEL + nccerCardNumber;
  const value = localStorage.getItem(key);
  const expiry = value ? parseFloat(value) : 0;

  return Boolean(expiry) && expiry > Date.now();
}

function walletCardStatusCalculation(walletCardStatus: string | null) {
  const walletCardStatusLower = walletCardStatus?.toLowerCase();
  return {
    isWalletCardClaimed: walletCardStatusLower === 'claimed' || walletCardStatusLower === 'issued',
    isWalletCardBatched: walletCardStatusLower === 'batched',
  };
}

function useClaimWalletCard(): () => Promise<void> {
  const { enqueueSnackbar } = useSnackbar();
  const { refetchUser } = useContext(UserContext);
  const { setLoading, moveNext } = useContext(WalletCardContext);

  return useCallback(async () => {
    setLoading(true);
    try {
      // Claim wallet card.
      await CredentialService.claimWalletCard();
      refetchUser();
      moveNext();
    } catch (error) {
      enqueueSnackbar(errorMessage(error), { variant: 'error' });
    }
    setLoading(false);
  }, [enqueueSnackbar, moveNext, refetchUser, setLoading]);
}

interface WalletCardContext {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  closeModal: () => void;
  dismissModal: () => void;
  moveNext: () => void;
}

const WalletCardContext = createContext<WalletCardContext>({
  setLoading: () => undefined,
  closeModal: () => undefined,
  dismissModal: () => undefined,
  moveNext: () => undefined,
});
