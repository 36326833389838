import React, { useCallback, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import Modal from 'components/shared/Modal';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ModalProps } from 'store/types/ComponentProps';
import { defaultFormProps, getRequiredValidationRule, getValidationPropsFromError } from 'util/Form';
import Feedback from 'store/types/Feedback';

import styles from './FeedbackModal.module.scss';
import UserService from 'services/api/UserService';
import { useWindowSize } from 'util/Window';

const FeedbackModal: React.FunctionComponent<ModalProps> = ({ open, loading, onClose }) => {
  const { isMobile } = useWindowSize();
  const { enqueueSnackbar } = useSnackbar();
  const [submittingForm, setSubmittingForm] = useState<boolean>(false);

  const form = useForm<Feedback>({
    ...defaultFormProps,
    defaultValues: {
      rating: '',
      message: '',
    },
  });
  const { handleSubmit, reset, control, formState } = form;

  const submitButtonDisabled: boolean = useMemo(
    () => !formState.isValid || !formState.isDirty || (loading ?? false),
    [formState.isValid, formState.isDirty, loading]
  );

  const handleFeedbackSubmit = useCallback(
    (formValues: Feedback) => {
      setSubmittingForm(true);
      UserService.submitFeedback(formValues)
        .then(() => {
          setSubmittingForm(false);
          enqueueSnackbar('Your feedback has been submitted. Thank you!', { variant: 'success' });
          reset();
          onClose();
        })
        .catch((error: string) => {
          setSubmittingForm(false);
          enqueueSnackbar(error, { variant: 'error' });
        });
    },
    [enqueueSnackbar, onClose, reset]
  );

  return (
    <Modal
      loading={loading || submittingForm}
      open={open}
      title={'Feedback'}
      subTitleText={"We'd love to hear your thoughts on our new account portal."}
      maxWidth={'sm'}
      onClose={onClose}
      disableBackdropClick={false}
      headerBodyDivider={true}
      actions={
        <Button
          color={'primary'}
          variant={'contained'}
          fullWidth={true}
          type={'submit'}
          onClick={handleSubmit(handleFeedbackSubmit)}
          disabled={submitButtonDisabled}
        >
          {'Submit Feedback'}
        </Button>
      }
    >
      <FormProvider {...form}>
        <Controller
          render={({ field }) => (
            <FormControl>
              <FormLabel id="rating-label">How would you rate your overall experience within the portal?</FormLabel>
              <RadioGroup {...field} row aria-labelledby="rating-label">
                {Array.from(Array(10)).map((_, i) => {
                  return <FormControlLabel key={`rating-${i}`} value={i + 1} control={<Radio />} label={i + 1} />;
                })}
              </RadioGroup>
            </FormControl>
          )}
          name={'rating'}
          control={control}
          rules={{
            required: getRequiredValidationRule('rating', false, true),
          }}
        />
        {!isMobile && <Divider className={styles.divider} />}
        <Typography paragraph={true}>
          {
            'Please tell us why you chose the above rating. What features do you like? What do you think we can do better?'
          }
        </Typography>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              {...getValidationPropsFromError(error)}
              inputRef={field.ref}
              fullWidth={true}
              required
              multiline
              rows={6}
            />
          )}
          name={'message'}
          control={control}
          rules={{
            required: getRequiredValidationRule('message', false, true),
          }}
        />
      </FormProvider>
    </Modal>
  );
};
export default FeedbackModal;
