import { useLayoutEffect, useState } from 'react';

export const MOBILE_WINDOW_WIDTH = 768;
export const SMALL_WINDOW_WIDTH = 960;
export const MEDIUM_WINDOW_WIDTH = 1100;

export const useWindowSize = (): { width: number; height: number; isMobile: boolean } => {
  const [size, setSize] = useState<[number, number]>([window.innerWidth, window.innerHeight]);

  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return {
    width: size[0],
    height: size[1],
    isMobile: size[0] <= MOBILE_WINDOW_WIDTH,
  };
};
