import React, { useCallback } from 'react';
import authClient from 'services/AuthService';
import useInterval from 'hooks/useInterval';
import { tenantConfig } from 'config';

import commonStyles from 'styles/common.module.scss';

enum SessionStatus {
  Changed = 'changed',
  Unchanged = 'unchanged',
}

const SESSION_CHECK_IFRAME_ID = 'iframeOP';

const SessionChecker: React.FunctionComponent = () => {
  const { idsUrl, clientId, checkSessionUrl } = tenantConfig;

  const checkStatus = useCallback(() => {
    const sessionState: string = authClient.getSessionState();
    const iframe: HTMLElement | null = document.getElementById(SESSION_CHECK_IFRAME_ID);

    if (authClient.isAuthenticated() && sessionState && iframe && idsUrl) {
      const message = `${clientId} ${sessionState}`;

      ((iframe as HTMLIFrameElement).contentWindow as WindowProxy).postMessage(message, idsUrl);
    }
  }, [idsUrl, clientId]);

  const receiveMessage = useCallback(
    (event: MessageEvent) => {
      if (event.origin === idsUrl && event.data === SessionStatus.Changed) {
        authClient.logout();
      }
    },
    [idsUrl]
  );

  const handleIframeLoad = useCallback(() => {
    window.addEventListener('message', receiveMessage, false);
    checkStatus();
  }, [checkStatus, receiveMessage]);

  // Check status every 60 seconds
  useInterval(checkStatus, 1000 * 60);

  return (
    <iframe
      src={checkSessionUrl}
      className={commonStyles.hidden}
      id={SESSION_CHECK_IFRAME_ID}
      title={'Check Session'}
      frameBorder="0"
      width="0"
      height="0"
      onLoad={handleIframeLoad}
    />
  );
};
export default SessionChecker;
