import React, { useCallback } from 'react';
import { Button } from '@mui/material';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import printer from 'assets/icons/printer.svg';
import CredentialService from 'services/api/CredentialService';
import { errorMessage } from 'util/Request';
import commonStyles from 'styles/common.module.scss';

interface PrintTranscriptButtonProps {
  providedNccerNumber: string;
  isFullWidth?: boolean;
  setLoading: (flag: boolean) => void;
  loading: boolean;
  className?: string;
  isPipeline?: boolean;
}

const PrintTranscriptButton: React.FunctionComponent<PrintTranscriptButtonProps> = ({
  providedNccerNumber,
  setLoading,
  loading,
  isFullWidth = true,
  className = '',
  isPipeline = false,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  //const [loading, setLoading] = useState<boolean>(false);

  const handleTranscriptRequest = useCallback(() => {
    setLoading(true);
    if (isPipeline) {
      CredentialService.getTranscriptPipelineLink(providedNccerNumber)
        .then((response) => {
          setLoading(false);
          window.location.assign(response);
        })
        .catch((error) => {
          setLoading(false);
          enqueueSnackbar(error.body, { variant: 'error' });
        });
    } else {
      CredentialService.getTranscriptLink(providedNccerNumber)
        .then((response) => {
          setLoading(false);
          window.location.assign(response);
        })
        .catch((error) => {
          setLoading(false);
          enqueueSnackbar(errorMessage(error), { variant: 'error' });
        });
    }
  }, [providedNccerNumber, isPipeline, enqueueSnackbar, setLoading]);

  return (
    <Button
      variant={'contained'}
      color={'primary'}
      className={classNames(commonStyles.printTranscriptButton, className)}
      disabled={loading}
      fullWidth={isFullWidth}
      onClick={handleTranscriptRequest}
    >
      <img src={printer} className={commonStyles.buttonIcon} />
      {'View/Print Transcript'}
    </Button>
  );
};
export default PrintTranscriptButton;
