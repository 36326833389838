export const races: string[] = [
  'American Indian or Alaska Native',
  'Asian',
  'Black or African American',
  'Hispanic or Latino',
  'Native Hawaiian or Other Pacific Islander',
  'White',
  'Some other Race',
];

export const ethnicities: string[] = ['Hispanic or Latino', 'Not Hispanic or Latino'];

export const genders: string[] = ['Male', 'Female', 'Undisclosed'];

export const nativeLanguages: string[] = [
  'English',
  'Chinese (Mandarin or Cantonese)',
  'French (France)',
  'Portuguese (Portugal)',
  'Portuguese (Brazil or other)',
  'Russian',
  'Spanish (Mexico)',
  'Spanish (South America)',
  'Spanish (Spain)',
  'Other language',
];
