import { useCallback, useContext, useMemo, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { UserContext } from 'components/UserGuard';
import Modal from 'components/shared/Modal';
import UserService from 'services/api/UserService';
import routes from 'store/configs/Routes';
import { getLocalStorageValue, IMPERSONATION_FLAG_LS_LABEL } from 'util/LocalStorage';
import { errorMessage } from 'util/Request';

export function HomeConfirmProfile() {
  const { enqueueSnackbar } = useSnackbar();
  const user = useContext(UserContext);
  const { address, applyFerpaAgeRestriction, email, lastCustomerProfileUpdate, phone, userLoading } = user;
  const [loading, setLoading] = useState(false);

  // If profile has not been updated for a year and user is not impersonating show confirm / update profile modal.
  const confirmProfileRequired = useMemo(() => {
    const updateRequired = lastCustomerProfileUpdate
      ? moment().isAfter(moment(lastCustomerProfileUpdate).add(1, 'year'))
      : true;
    const isImpersonation = Boolean(getLocalStorageValue(IMPERSONATION_FLAG_LS_LABEL));
    return updateRequired && !isImpersonation;
  }, [lastCustomerProfileUpdate]);

  const handleConfirm = useCallback(async () => {
    setLoading(true);
    try {
      await UserService.updateCurrentUser(user);
      user.refetchUser();
      enqueueSnackbar('Confirmed account information.');
    } catch (error) {
      enqueueSnackbar(errorMessage(error), { variant: 'error' });
    }
    setLoading(false);
  }, [enqueueSnackbar, user]);

  return (
    <Modal
      title="Confirm Account Information"
      open={user && confirmProfileRequired}
      loading={loading || userLoading}
      disableBackdropClick={true}
      actions={
        <>
          <Button color="secondary" variant="outlined" component={Link} to={routes.settings}>
            Update
          </Button>
          <Button color="primary" variant="contained" onClick={handleConfirm}>
            Confirm
          </Button>
        </>
      }
    >
      <Box display="flex" flexDirection="column" gap={3}>
        {!applyFerpaAgeRestriction && (
          <Typography variant="h5">
            Address
            {address ? (
              <>
                <Typography pt={1}>{address.street1}</Typography>
                <Typography>
                  {address.city} {address.stateRegion} {address.postalCode}
                </Typography>
                <Typography>{address.country}</Typography>
              </>
            ) : (
              <Typography pt={1}>No address on file</Typography>
            )}
          </Typography>
        )}

        <Typography variant="h5">
          Email Address
          <Typography pt={1}>{email}</Typography>
        </Typography>

        <Typography variant="h5">
          Phone Number
          <Typography pt={1}>{phone}</Typography>
        </Typography>
      </Box>
    </Modal>
  );
}
