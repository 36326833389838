import React from 'react';
import { Button, Typography } from '@mui/material';
import Table from 'components/shared/Table';
import { TableColumn } from 'store/types/Table';
import type { ReportRow } from '../ReportsPage';

interface ReportsTableProps {
  reports: ReportRow[];
  className?: string;
  onClick: (row: ReportRow) => void;
}

export function ReportsTable({ reports, className = '', onClick }: ReportsTableProps) {
  const tableColumns: Array<TableColumn<ReportRow>> = [
    {
      dataIndex: 'title',
      label: <Typography fontWeight="bold">Report Title</Typography>,
      render: (_, record) => <Typography>{record.title}</Typography>,
    },
    {
      dataIndex: 'description',
      label: <Typography fontWeight="bold">Description</Typography>,
      render: (_, record) => <Typography>{record.description}</Typography>,
    },
    {
      dataIndex: 'generationLabel',
      label: '',
      render: (_, record) => (
        <Button variant="text" color={'secondary'} onClick={() => onClick(record)}>
          <Typography fontWeight="bold" color={'secondary'}>
            {record.generationLabel}
          </Typography>
        </Button>
      ),
      align: 'right',
    },
  ];

  return <Table list={reports} columns={tableColumns} noResultsText="No reports found" className={className} />;
}
