import React, { useEffect, useState } from 'react';
import { Collapse, List, ListItemButton } from '@mui/material';
import { getIconSvg } from 'util/Layout';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import styles from '../Sidebar.module.scss';

interface SidebarMenuListButtonProps {
  icon: string | undefined;
  title: React.ReactNode;
  isOpen?: boolean;
}

const SidebarMenuListButton: React.FunctionComponent<SidebarMenuListButtonProps> = ({
  title,
  icon,
  isOpen,
  children,
}) => {
  const [open, setOpen] = useState<boolean>(true);

  useEffect(() => {
    if (isOpen) {
      setOpen(true);
    }
  }, [isOpen]);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <List className={styles.link}>
        <ListItemButton onClick={handleClick} className={styles.content}>
          {icon && getIconSvg(icon)}
          <span className={styles.label}>{title}</span>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </List>
      <Collapse in={open} unmountOnExit={true}>
        {children}
      </Collapse>
    </>
  );
};

export default SidebarMenuListButton;
