export enum LOG_SEVERITY {
  // noinspection JSUnusedGlobalSymbols
  DEFAULT = 'DEFAULT',
  DEBUG = 'DEBUG',
  INFO = 'INFO',
  NOTICE = 'NOTICE',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  CRITICAL = 'CRITICAL',
  ALERT = 'ALERT',
  EMERGENCY = 'EMERGENCY',
}

export interface LogEntry {
  severity: LOG_SEVERITY;
  payload: string;
}

export interface LogEntryRequest {
  data: {
    type: string;
    description: string;
  };
}
