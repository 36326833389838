import { StyledEngineProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import React, { useEffect } from 'react';
import I18nService from 'services/i18n/i18nService';
import ConfigGuard from 'components/ConfigGuard';
import AppContent from 'components/AppContent';
import { tenantConfig } from 'config';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';

import './App.module.scss';

const App: React.FunctionComponent = () => {
  const { environment, theme } = tenantConfig;

  useEffect(() => {
    const title = document.getElementById('app-title');

    if (title) {
      title.textContent = tenantConfig.appTitle;
    }

    I18nService.init();
  }, [environment, theme]);

  return (
    <StyledEngineProvider injectFirst={true}>
      <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ConfigGuard>
            <AppContent />
          </ConfigGuard>
        </LocalizationProvider>
      </SnackbarProvider>
    </StyledEngineProvider>
  );
};
export default App;
