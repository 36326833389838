import HomePage from 'components/home/HomePage';
import ProfilePage from 'components/profile/ProfilePage';
import SettingsPage from 'components/settings/SettingsPage';
import React, { useContext, useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import routes from 'store/configs/Routes';
import NotFoundPage from 'components/layout/NotFoundPage';
import SiteModule from 'store/enums/SiteModule';
import { ConfigContext } from 'components/ConfigGuard';
import { getDefaultHomeRoute } from 'util/Route';
import ViewCredentials from './credentials/ViewCredentials/ViewCredentials';
import BadgeDetails from './credentials/BadgeDetails';
import VerifyCredentials from './credentials/VerifyCredentials/VerifyCredentials';
import ViewMyCredentials from './credentials/ViewMyCredentials';
import { ReportsPage } from './reports/ReportsPage';
import PrintCredentials from './credentials/PrintCredentials';

const AppProtectedRoutes: React.FunctionComponent = () => {
  const { enabledModules = [], sidebar } = useContext(ConfigContext);

  const appRoutes: React.ReactNode[] = useMemo(() => {
    let result: React.ReactNode[] = [
      <Route key={'default'} path={'/'} exact={true}>
        <Redirect to={getDefaultHomeRoute(enabledModules)} />
      </Route>,
    ];

    if (enabledModules.includes(SiteModule.Home)) {
      result = [
        ...result,
        <Route key={'home'} path={routes.home} exact={true}>
          <HomePage />
        </Route>,
        // <Route key={"reports"} path={[routes.reports, routes.reports + "/:reportCode"]} exact={true}>
        //   <ReportsPage />
        // </Route>,
        // <Route key={"settings"} path={routes.settings} exact={true}>
        //   <SettingsPage />
        // </Route>,
      ];
    }

    if (enabledModules.includes(SiteModule.Credentials)) {
      result = [
        ...result,
        <Route key={'myCredentials'} path={routes.myCredentials} exact={true}>
          <ViewMyCredentials />
        </Route>,
        <Route
          key={'viewCredentials'}
          path={[routes.viewCredentials, routes.viewCredentials + '/:nccerNumber']}
          exact={true}
        >
          <ViewCredentials />
        </Route>,
        <Route key={'printCredentials'} path={routes.printCredentials} exact={true}>
          <PrintCredentials />
        </Route>,
        <Route key={'badgeDetails'} path={[routes.badgeDetails, routes.badgeDetails + '/:nccerNumber']} exact={true}>
          <BadgeDetails />
        </Route>,
        <Route key={'verifyCredentials'} path={routes.verifyCredentials} exact={true}>
          <VerifyCredentials />
        </Route>,
      ];
    }

    // Currently, no profile module for NCCER (3/14)
    // if (enabledModules.includes(SiteModule.Profile)) {
    //   sidebar.forEach((item) => {
    //     if (item.type === SiteModule.Profile) {
    //       result = [
    //         ...result,
    //         <Route key={"profile"} path={routes.profile} exact={true}>
    //           <ProfilePage />
    //         </Route>,
    //       ];
    //     }
    //   });
    // }

    if (enabledModules.includes(SiteModule.Reports)) {
      result = [
        ...result,
        <Route key={'reports'} path={[routes.reports, routes.reports + '/:reportCode']} exact={true}>
          <ReportsPage />
        </Route>,
      ];
    }

    if (enabledModules.includes(SiteModule.Settings)) {
      result = [
        ...result,
        <Route key={'settings'} path={routes.settings} exact={true}>
          <SettingsPage />
        </Route>,
      ];
    }

    return [
      ...result,
      <Route key={'not-found'} path={'*'} exact={true}>
        <NotFoundPage />
      </Route>,
    ];
  }, [enabledModules, sidebar]);

  return <Switch>{appRoutes}</Switch>;
};
export default AppProtectedRoutes;
