import React, { useCallback, useEffect, useState } from 'react';
import Spinner from 'components/shared/Spinner';
import authClient from 'services/AuthService';
import {
  IMPERSONATION_FLAG_LS_LABEL,
  SUPER_ADMIN_DETAILS_FLAG_LS_LABEL,
  getLocalStorageValue,
} from 'util/LocalStorage';
import { tenantConfig } from 'config';
import { useSessionTabsContext } from 'components/SessionTabsContext';

const LogoutLandingPage: React.FunctionComponent = () => {
  const { openTabs } = useSessionTabsContext();
  const [readyToLogout, setReadyToLogout] = useState<boolean>(false);

  const isImpersonation = !!getLocalStorageValue(IMPERSONATION_FLAG_LS_LABEL);
  const superAdminDetails = getLocalStorageValue(SUPER_ADMIN_DETAILS_FLAG_LS_LABEL);

  const closeOpenTabs = useCallback(() => {
    if (openTabs.length > 0) {
      openTabs.map((tab) => tab?.close());
      setReadyToLogout(true);
    }
    setReadyToLogout(true);
  }, [openTabs]);

  useEffect(() => {
    if (readyToLogout)
      authClient.logout(isImpersonation ? tenantConfig.reLoginUrl + encodeURIComponent(superAdminDetails) : '');
  }, [isImpersonation, readyToLogout, superAdminDetails]);

  useEffect(() => {
    closeOpenTabs();
  }, [closeOpenTabs]);

  return <Spinner loading={true}></Spinner>;
};
export default LogoutLandingPage;
