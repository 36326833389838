import { Container, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import NccerLogo from 'assets/images/nccer/logo-white.png';
import SetPasswordModal from 'components/login/SetPasswordModal';
import SignupModal from 'components/login/SignupModal';
import { useLocation } from 'react-router';
import routes from 'store/configs/Routes';
import { getEmailLink, getPhoneLink } from 'util/Format';
import { LOGIN_SECTION_ID } from 'util/Form';
import LoginSection from './LoginSection';

import styles from './LoginPage.module.scss';

const LoginPage: React.FunctionComponent = () => {
  const { pathname } = useLocation();
  const [setPasswordModalOpen, setSetPasswordModalOpen] = useState<boolean>(false);
  const [signupModalOpen, setSignupModalOpen] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (pathname.includes(routes.setPassword) || pathname.includes(routes.firstLogin)) {
      setSetPasswordModalOpen(true);
    }
  }, [pathname]);

  const handleSignupModalClose = useCallback(() => {
    setSignupModalOpen(false);
  }, []);

  const handleSignupClick = useCallback(() => {
    setSignupModalOpen(true);
  }, []);

  const handleSetPasswordModalClose = useCallback(() => {
    setSetPasswordModalOpen(false);
  }, []);

  return (
    <>
      <div className={styles.container}>
        <Container maxWidth={false}>
          <img src={NccerLogo} alt={'logo'} className={styles.nccerLogo} />
        </Container>
        <div id={LOGIN_SECTION_ID} className={styles.loginWrapper}>
          <Container maxWidth={'lg'}>
            <div className={styles.card}>
              <h1 className={styles.cardTitle}>{'Sign In'}</h1>
              <Typography paragraph={true} color={'textPrimary'} className={styles.cardDescription} component={'div'}>
                <p>'If you have an existing NCCER account, sign in below.'</p>
                <p>
                  <span className={styles.highlight}>{'Important Note for First-Time Users: '}</span>
                  {
                    'If you are signing in here for the first time and have a Registry login, use your NCCER card number and password. If you do not have a Registry login, use your Assessment Platform or Shop email and password.'
                  }
                </p>
              </Typography>
              <LoginSection onSignupClick={handleSignupClick} />
              <Typography paragraph={true} color={'textPrimary'} className={styles.helperText}>
                <>
                  {'If you require further assistance, please contact us at '}
                  {getPhoneLink('(888) 622-3720')}
                  {' or '}
                  {getEmailLink('support@nccer.org')}.
                </>
              </Typography>
            </div>
          </Container>
        </div>
      </div>
      <SetPasswordModal open={setPasswordModalOpen} onClose={handleSetPasswordModalClose} />
      <SignupModal open={signupModalOpen} onClose={handleSignupModalClose} />
    </>
  );
};
export default LoginPage;
