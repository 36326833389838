import { Grid, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Skeleton from '@mui/material/Skeleton';
import { UserContext } from 'components/UserGuard';
import React, { useContext } from 'react';
import { getUserFullName } from 'util/Format';
import { WalletCardButton } from 'components/shared/WalletCardButton';

import styles from './Header.module.scss';

const Header: React.FunctionComponent = () => {
  const {
    userLoading = false,
    firstName,
    lastName,
    nccerCardNumber,
    logoUrl,
    canClaimWalletCard,
  } = useContext(UserContext);

  const userTextSection: React.ReactNode = (
    <Grid container justifyContent={'center'}>
      <Grid item md={4} sm={5} xs={6}>
        <Typography variant={'h1'}>
          {userLoading ? (
            <Skeleton width={240} variant={'text'} className={styles.skeleton} />
          ) : (
            'Welcome, ' + firstName + '.'
          )}
        </Typography>
      </Grid>
      <Grid item md={8} sm={7} xs={6} className={styles.accountInfo} alignSelf={'center'}>
        <Grid container alignItems={'center'}>
          <Grid item xs={12} className={styles.textItems}>
            {canClaimWalletCard && (
              <>
                <div className={styles.walletCardWrapper}>
                  <WalletCardButton />
                </div>
                <div className={styles.nameplateDivider}></div>
              </>
            )}
            <Grid item flexDirection={'row'}>
              <div className={styles.nameplate}>{getUserFullName({ firstName, lastName })}</div>
              <div className={styles.nccerNumberLabel}>{'NCCER Number: ' + (nccerCardNumber ?? ' ')}</div>
            </Grid>
            <div className={styles.profileImageWrapper}>
              {logoUrl !== null ? (
                <img className={styles.profileImage} src={logoUrl} />
              ) : (
                <AccountCircleIcon className={styles.profileImage}></AccountCircleIcon>
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      <header className={styles.header} data-testid={'header'}>
        {userTextSection}
      </header>
    </>
  );
};
export default Header;
