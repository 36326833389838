import React from 'react';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';

import styles from './SuccessModalMessage.module.scss';

interface SuccessModalMessageProps {
  message: React.ReactNode;
}

const SuccessModalMessage: React.FunctionComponent<SuccessModalMessageProps> = ({ message = '' }) => {
  return (
    <div className={styles.confirmContainer}>
      <CheckCircleOutline className={styles.confirmIcon} />
      <p>{message}</p>
    </div>
  );
};
export default SuccessModalMessage;
