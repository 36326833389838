import React, { useEffect } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import moment from 'moment';
import { defaultGridItemProps } from 'util/Layout';
import { DocumentRetrieval, isErrorStatus, isRetrievable } from 'store/types/DocumentRetrieval';
import { TranslateReportName } from 'store/types/Reports';

import commonStyles from 'styles/common.module.scss';

interface MobileReportRetrievalViewProps {
  documents: Array<DocumentRetrieval>;
  handlePrintClick: (document: DocumentRetrieval) => void;
  refreshDocumentList: () => void;
}

export function MobileReportRetrievalView({
  documents,
  handlePrintClick,
  refreshDocumentList,
}: MobileReportRetrievalViewProps) {
  // Refresh document list on page load.
  useEffect(() => {
    refreshDocumentList();
  }, [refreshDocumentList]);

  return (
    <Grid {...defaultGridItemProps}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box className={commonStyles.contentCard}>
          <Typography paragraph={true}>
            Depending on the size of your report, please allow up to 24 hours for your report to generate.{' '}
            <strong>Retrieve File</strong> will appear when your report is ready. <strong>Processing</strong> will
            appear if your report is still generating.
          </Typography>
          <Typography paragraph={true}>
            NOTE: reports will remain on this tab for 30 days, after which time the report will need to be regenerated,
            if needed.
          </Typography>
          <Button variant="contained" color="primary" fullWidth onClick={refreshDocumentList}>
            Refresh
          </Button>
        </Box>

        {documents.map((document, index) => (
          <Box key={`document-${index}`} className={commonStyles.contentCard}>
            <Typography fontWeight="bold">Report Type</Typography>
            <Typography>{TranslateReportName(document)}</Typography>
            <Typography fontWeight="bold">Program</Typography>
            <Typography>{document.programName}</Typography>
            <Typography fontWeight="bold">Selected Date Range</Typography>
            <Typography>{document.dateRange}</Typography>
            <Typography fontWeight="bold">Date Generated</Typography>
            <Typography>
              {document.dateGenerated
                ? moment(document.dateGenerated).local().format('MM/DD/yyyy h:mm A').toString()
                : 'Invalid Generation Date'}
            </Typography>
            <Box sx={{ width: '100%', textAlign: 'center', paddingTop: 2 }}>
              {isErrorStatus(document) ? (
                <Typography>
                  {document.error?.includes('ReportNotGenerated') ? 'No data found' : 'Failed to generate'}
                </Typography>
              ) : isRetrievable(document) ? (
                <Button variant={'contained'} color={'secondary'} fullWidth onClick={() => handlePrintClick(document)}>
                  Retrieve File
                </Button>
              ) : (
                <Typography>Processing...</Typography>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Grid>
  );
}
