import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useState } from 'react';
import { Button, Grid } from '@mui/material';
import Modal from 'components/shared/Modal';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router';
import { defaultFormProps } from 'util/Form';
import { defaultGridContainerProps } from 'util/Layout';
import { SetPasswordFormValues } from 'store/types/FormValues';
import { ModalProps } from 'store/types/ComponentProps';
import SetPasswordFormSection from 'components/shared/SetPasswordFormSection';
import LoginService, { FirstLoginResponse, LoginResponseData } from 'services/api/LoginService';
import authClient from 'services/AuthService';
import routes from 'store/configs/Routes';
import { UserContext } from 'components/UserGuard';

import styles from './SetPasswordModal.module.scss';

const SetPasswordModal: React.FunctionComponent<ModalProps> = ({ open }) => {
  const {
    username = '',
    tempPassword = '',
    requestCode = '',
  } = useParams<{ username: string; tempPassword: string; requestCode: string }>();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { refetchUser } = useContext(UserContext);
  const [passwordsValid, setPasswordsValid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const form = useForm<SetPasswordFormValues>({
    ...defaultFormProps,
    defaultValues: {
      newPassword: '',
      confirmNewPassword: '',
    },
  });
  const { handleSubmit, formState } = form;
  const submitButtonDisabled: boolean = !formState.isValid || !formState.isDirty || loading || !passwordsValid;

  const setPassword = useCallback(
    (password: string, token: string) => {
      LoginService.setPassword({ email: username, password, token })
        .then(() => {
          enqueueSnackbar('Password successfully updated', { variant: 'success' });
          return authClient.login({ username, password });
        })
        .then(() => {
          refetchUser();
          setLoading(false);
          history.replace('/');
        })
        .catch((errorMessage: string) => {
          enqueueSnackbar(errorMessage, { variant: 'error' });
          setLoading(false);
        });
    },
    [enqueueSnackbar, username, history, refetchUser]
  );

  const handleFormSubmit = useCallback(
    ({ newPassword }: SetPasswordFormValues) => {
      setLoading(true);

      if (pathname.includes(routes.firstLogin)) {
        let initialPassword = tempPassword;

        if (search) {
          initialPassword = `${initialPassword}${search}`;
        }
        LoginService.firstLogin({ username, password: initialPassword })
          .then((response: LoginResponseData) => {
            const { Session } = response as FirstLoginResponse;

            setPassword(newPassword, Session);
          })
          .catch((errorMessage: string) => {
            enqueueSnackbar(errorMessage, { variant: 'error' });
            setLoading(false);
          });
      } else {
        let token = requestCode;

        if (search) {
          token = `${token}${search}`;
        }
        setPassword(newPassword, token);
      }
    },
    [pathname, requestCode, tempPassword, search, username, enqueueSnackbar, setPassword]
  );

  const handlePasswordsValidChange = useCallback((valid: boolean) => {
    setPasswordsValid(valid);
  }, []);

  return (
    <Modal
      loading={loading}
      open={open}
      title={'Set Your Password'}
      maxWidth={'xs'}
      actions={
        <Button
          color={'secondary'}
          variant={'contained'}
          type={'submit'}
          onClick={handleSubmit(handleFormSubmit)}
          disabled={submitButtonDisabled}
        >
          Confirm
        </Button>
      }
    >
      <p className={styles.description}>{'Please enter your new password.'}</p>
      <Grid {...defaultGridContainerProps}>
        <FormProvider {...form}>
          <SetPasswordFormSection onValidChange={handlePasswordsValidChange} />
        </FormProvider>
      </Grid>
    </Modal>
  );
};
export default SetPasswordModal;
