import React from 'react';
import { Button, Typography } from '@mui/material';
import Table from 'components/shared/Table';
import { TableColumn } from 'store/types/Table';
import Reports, { ReportType } from 'store/types/Reports';

interface GenerationTableProps {
  reports: Array<Reports>;
  className?: string;
  onClick: (
    e: React.MouseEvent<HTMLElement>,
    title: string,
    subTitle: string,
    contentTypeId: number,
    reportType: ReportType
  ) => void;
}

const GenerationTable: React.FunctionComponent<GenerationTableProps> = ({ reports, className = '', onClick }) => {
  const tableColumns: Array<TableColumn<Reports>> = [
    {
      dataIndex: 'title',
      label: <Typography fontWeight="bold">Document</Typography>,
      render: (_, record) => <Typography>{record.title}</Typography>,
    },
    {
      dataIndex: 'description',
      label: <Typography fontWeight="bold">Description</Typography>,
      render: (_, record) => <Typography>{record.description}</Typography>,
    },
    {
      dataIndex: 'contentTypeId',
      label: <Typography fontWeight="bold"></Typography>,
      render: (_, record) => (
        <Button
          variant="text"
          color={'secondary'}
          onClick={(e) => onClick(e, record.title, record.subTitle, record.contentTypeId, record.reportType)}
        >
          <Typography fontWeight="bold" color={'secondary'}>
            {record.generationLabel}
          </Typography>
        </Button>
      ),
      align: 'right',
    },
  ];

  return (
    <Table list={reports} columns={tableColumns} noResultsText="No documents can be generated" className={className} />
  );
};

export default GenerationTable;
