import React, { SyntheticEvent, useCallback, useContext, useMemo, useState } from 'react';
import Header from 'components/layout/Header';
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';

import commonStyles from 'styles/common.module.scss';
import styles from './PrintCredentials.module.scss';
import CredentialService from 'services/api/CredentialService';
import { UserContext } from 'components/UserGuard';
import Spinner from 'components/shared/Spinner';
import { useWindowSize } from 'util/Window';
import { defaultGridContainerProps, defaultGridItemProps, getPageTitle } from 'util/Layout';
import TabPanel from 'components/shared/TabPanel';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import ReportService, { ReportCountResponse } from 'services/api/ReportService';
import { GenerateReportFormValues } from 'store/types/FormValues';
import Reports, { ReportType } from 'store/types/Reports';
import Roles from 'store/enums/Roles';
import GenerationTable from './GenerationTable';
import { DocumentRetrieval, DocumentSource } from 'store/types/DocumentRetrieval';
import { RetrievalTable, MobilePrintRetrievalView } from './RetrievalTable';
import GenerateDocumentModal from './GenerateDocumentModal';
import Modal from 'components/shared/Modal';
import SiteModule from 'store/enums/SiteModule';
import { ConfigContext } from 'components/ConfigGuard';
import { errorMessage } from 'util/Request';

const DATE_FORMAT = 'YYYY-MM-DD';

function createRow(
  title: string,
  subTitle: string,
  description: string,
  generationLabel: string,
  contentTypeId: number,
  reportType: ReportType,
  roles?: string[],
  showDates = false,
  selectMultiple = false,
  showHelperText = false,
  showFailuresFlag = false,
  formats = []
): Reports {
  return {
    title,
    subTitle,
    description,
    generationLabel,
    contentTypeId,
    reportType,
    roles,
    showDates,
    selectMultiple,
    showHelperText,
    showFailuresFlag,
    formats,
  };
}

const documentTypes: Array<Reports> = [
  createRow(
    'Print Credentials',
    'Select the program and date range to generate a PDF file.',
    'Print credentials in bulk for your training and/or assessment program(s).',
    'Generate File',
    0,
    ReportType.BulkCertificates,
    [
      Roles.Director,
      Roles.Manager,
      Roles.ProgramContact,
      Roles.AccredApplicant,
      Roles.MasterTrainer,
      Roles.Instructor,
      Roles.AssessmentProctor,
      Roles.ModuleTestProctor,
      // Certified
      Roles.CertifiedMasterTrainer,
      Roles.CertifiedInstructor,
      Roles.CertifiedAssessmentProctor,
      Roles.CertifiedModuleTestProctor,
    ]
  ),
];

const PrintCredentials: React.FunctionComponent = () => {
  const { isMobile } = useWindowSize();
  const { enqueueSnackbar } = useSnackbar();
  const { userLoading = false, roles, nccerCardNumber, programs } = useContext(UserContext);
  const { sidebar } = useContext(ConfigContext);
  const [tabValue, setTabValue] = useState(0);
  const [programModalOpen, setProgramModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [reportTitle, setReportTitle] = useState('');
  const [reportSubTitle, setReportSubTitle] = useState<string>('');
  const [reportContentTypeId, setReportContentTypeId] = useState(0);
  const [reportType, setReportType] = useState(ReportType.BulkCertificates);
  const [documentList, setDocumentList] = useState<DocumentRetrieval[]>([]);

  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [bcCount, setBcCount] = useState<ReportCountResponse>({ documents: 0, files: 0 });
  const [selectedData, setSelectedData] = useState<GenerateReportFormValues | null>(null);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const handleDropdownChange = (event: SelectChangeEvent) => {
    setTabValue(Number(event.target.value));
  };

  const handleConfirmModalOpen = useCallback(() => {
    setConfirmModalOpen(true);
  }, []);
  const handleConfirmModalClose = useCallback(() => {
    setConfirmModalOpen(false);
  }, []);

  const handleGenerateReportClick = (
    e: React.MouseEvent<HTMLElement>,
    title: string,
    subTitle: string,
    contentTypeId: number,
    reportType: ReportType
  ) => {
    e.preventDefault();
    setReportTitle(title);
    setReportSubTitle(subTitle);
    setReportContentTypeId(contentTypeId);
    setReportType(reportType);
    setProgramModalOpen(true);
  };

  const handleModalClose = () => {
    setReportTitle('');
    setReportSubTitle('');
    setReportContentTypeId(0);
    setReportType(ReportType.BulkCertificates);
    setProgramModalOpen(false);
  };

  const refreshDocumentList = useCallback(() => {
    setLoading(true);
    ReportService.getRequestedBulkCertificatesByNccerNumber(nccerCardNumber ?? '', programs ?? [])
      .then((response) => {
        setDocumentList(response);
        setLoading(false);
      })
      .catch((error) => {
        enqueueSnackbar(error?.body, { variant: 'error' });
        setLoading(false);
      });
  }, [enqueueSnackbar, nccerCardNumber, programs]);

  const handleCountRequestSubmit = useCallback(
    ({ programId, startDate, endDate }: GenerateReportFormValues) => {
      setLoading(true);
      if (reportType === ReportType.BulkCertificates) {
        ReportService.requestBulkCertificateCount(
          programId,
          moment(startDate).format(DATE_FORMAT),
          moment(endDate).format(DATE_FORMAT),
          nccerCardNumber ?? ''
        )
          .then((response) => {
            if (response.documents == 0) {
              enqueueSnackbar('There was no data found in your search parameters.', { variant: 'warning' });
            } else {
              setBcCount(response);
              setSelectedData({ programId, startDate, endDate, format: 'pdf', showFailures: false });
              handleConfirmModalOpen();
            }
          })
          .catch((error) => {
            enqueueSnackbar(error?.body, { variant: 'error' });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [reportType, nccerCardNumber, enqueueSnackbar, handleConfirmModalOpen]
  );

  const handleConfirmationModalSubmit = useCallback(
    ({ programId, startDate, endDate }: GenerateReportFormValues) => {
      setLoading(true);
      ReportService.queueBulkCertificate(
        programId,
        moment(startDate).format(DATE_FORMAT),
        moment(endDate).format(DATE_FORMAT),
        nccerCardNumber ?? ''
      )
        .then(() => {
          enqueueSnackbar('Report queued successfully.', { variant: 'success' });
          refreshDocumentList();
          handleConfirmModalClose();
          handleModalClose();
        })
        .catch((error) => {
          enqueueSnackbar(error?.body, { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [nccerCardNumber, enqueueSnackbar, refreshDocumentList, handleConfirmModalClose]
  );

  const handleRequestConfirmation = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      if (!selectedData?.programId) {
        enqueueSnackbar('There was an error processing your request. Please try again or contact customer service.', {
          variant: 'error',
        });
      } else {
        handleConfirmationModalSubmit(selectedData);
      }
    },
    [enqueueSnackbar, handleConfirmationModalSubmit, selectedData]
  );

  const validDocumentTypes = useMemo(
    () => documentTypes.filter((dt) => roles && dt.roles?.some((dtr) => roles.some((r) => r.role === dtr))),
    [roles]
  );

  const handlePrintClick = useCallback(
    async (document: DocumentRetrieval) => {
      if (document.documentSource === DocumentSource.CredentialEngine) {
        if (document.link) {
          setLoading(true);
          try {
            const response = await CredentialService.getDocumentLink(document.link);
            setLoading(false);
            window.location.assign(response);
          } catch (error) {
            setLoading(false);
            enqueueSnackbar(errorMessage(error), { variant: 'error' });
          }
        }
      }
    },
    [enqueueSnackbar]
  );

  const mobileReportView = useCallback((report: Reports, index: number) => {
    return (
      <Grid {...defaultGridItemProps} key={`report-${index}`}>
        <Box className={commonStyles.contentCard}>
          <Typography variant="h5">{report.title}</Typography>
          <Typography sx={{ my: 2 }}>{report.description}</Typography>
          <Button
            variant="outlined"
            color={'secondary'}
            fullWidth
            onClick={(e) =>
              handleGenerateReportClick(e, report.title, report.subTitle, report.contentTypeId, report.reportType)
            }
          >
            <Typography fontWeight="bold" color={'secondary'}>
              {report.generationLabel}
            </Typography>
          </Button>
        </Box>
      </Grid>
    );
  }, []);

  return (
    <>
      {!isMobile && <Header />}
      <div className={commonStyles.pageSubHeader}>
        <Grid {...defaultGridContainerProps}>
          <Grid {...defaultGridItemProps} md={12}>
            {/* <Typography variant={"h2"} className={commonStyles.title}>
              {"Print Credentials"}
            </Typography> */}
            <Typography variant={'h2'} className={commonStyles.pageTitle}>
              {getPageTitle(sidebar, SiteModule.PrintCredentials) || 'Print Credentials'}
            </Typography>
            {/* <Typography paragraph={true} className={commonStyles.description}>
              {"Once you have generated your file, you will need to click on "}
              <strong>{"Retrieve File"}</strong>
              {" to download your pdf. Please allow up to 24 hours depending on the size of the file to be generated."}
            </Typography> */}
          </Grid>
        </Grid>
        {isMobile && (
          <Box sx={{ mt: 3 }}>
            <FormControl fullWidth>
              <Select value={tabValue.toString()} onChange={handleDropdownChange}>
                {validDocumentTypes && <MenuItem value={0}>{'Generate File'}</MenuItem>}
                {documentList && <MenuItem value={1}>{'Retrieve File'}</MenuItem>}
              </Select>
            </FormControl>
          </Box>
        )}
      </div>
      <Spinner loading={userLoading || loading}>
        <div className={commonStyles.pageContent}>
          {!isMobile && (
            <>
              <div className={commonStyles.contentCard}>
                <Box sx={{ width: '100%' }}>
                  <Box sx={{ borderBottom: 1, borderColor: '#CAD2E6' }}>
                    <Tabs value={tabValue} onChange={handleTabChange}>
                      {validDocumentTypes && <Tab label={'Generate File'} className={commonStyles.tabText} />}
                      {documentList && <Tab label={'Retrieve File'} className={commonStyles.tabText} />}
                    </Tabs>
                  </Box>
                  {tabValue == 1 && (
                    <>
                      <Typography paragraph={true} className={styles.reportsDescription} sx={{ paddingTop: '16px' }}>
                        {
                          'Depending on the size of your print file(s), please allow up to 24 hours for your file(s) to generate. '
                        }
                        <strong>{'Retrieve File'}</strong>
                        {' will appear in the far right column when your print file is ready. '}
                        <strong>{'Processing'}</strong>
                        {' will appear if your print file is still generating.'}
                      </Typography>
                      <Typography paragraph={true} className={styles.reportsDescription}>
                        {
                          'NOTE: print files will remain on this tab for 30 days, after which time the print file will need to be regenerated, if needed.'
                        }
                      </Typography>
                    </>
                  )}
                </Box>
              </div>
              <TabPanel value={tabValue} index={0}>
                <Grid container className={commonStyles.subHeaderTextWrapper}>
                  <Grid item xs={12}>
                    <GenerationTable
                      reports={validDocumentTypes}
                      className={styles.reportsTable}
                      onClick={handleGenerateReportClick}
                    ></GenerationTable>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <Grid container className={commonStyles.subHeaderTextWrapper}>
                  <Grid item xs={12}>
                    <RetrievalTable
                      documents={documentList}
                      className={styles.reportsTable}
                      handlePrintClick={handlePrintClick}
                      refreshDocumentList={refreshDocumentList}
                    ></RetrievalTable>
                  </Grid>
                </Grid>
              </TabPanel>
            </>
          )}

          {isMobile && (
            <>
              <Grid {...defaultGridContainerProps}>
                {tabValue === 0 &&
                  validDocumentTypes.map((report, index) => {
                    return mobileReportView(report, index);
                  })}
                {tabValue === 1 && (
                  <MobilePrintRetrievalView
                    documents={documentList}
                    handlePrintClick={handlePrintClick}
                    refreshDocumentList={refreshDocumentList}
                  />
                )}
              </Grid>
            </>
          )}
        </div>
        <GenerateDocumentModal
          title={reportTitle}
          subTitle={reportSubTitle}
          contentTypeId={reportContentTypeId}
          open={programModalOpen}
          onSubmit={handleCountRequestSubmit}
          onClose={handleModalClose}
          loading={loading}
        ></GenerateDocumentModal>
        <Modal
          loading={loading}
          open={confirmModalOpen}
          maxWidth={'sm'}
          actions={
            <>
              <Button variant={'outlined'} color={'primary'} onClick={handleConfirmModalClose}>
                {'Close'}
              </Button>
              <Button variant={'contained'} color={'primary'} onClick={handleRequestConfirmation}>
                {'OK'}
              </Button>
            </>
          }
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography paragraph={true} variant={'body1'}>
                {`Your request includes ${bcCount.documents} `}
                {bcCount.documents > 1 ? 'certificates' : 'certificate'}
                {` and will generate a total of ${bcCount.files} `}
                {bcCount.files > 1 ? 'files.' : 'file.'}
              </Typography>
              <Typography variant={'body1'}>
                {'Click OK to confirm your request, or Cancel to adjust your search parameters.'}
              </Typography>
            </Grid>
          </Grid>
        </Modal>
      </Spinner>
    </>
  );
};
export default PrintCredentials;
