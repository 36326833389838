import { getAddressFormValues } from 'util/Form';
import { UserFormValues } from 'store/types/FormValues';

export const emptyUserFormValues: UserFormValues = {
  firstName: '',
  lastName: '',
  middleInitial: '',
  phone: '',
  email: '',
  address: getAddressFormValues(),
  birthDate: null,
  birthCity: null,
  alternateId: null,
  alternateIdType: null,
  title: null,
  gender: null,
  race: null,
  ethnicity: null,
  nativeLanguage: null,
  logoUrl: null,
  photoData: null,
  walletCardStatus: null,
  walletCardStatusDate: null,
  synchronizeWithNetSuite: false,
  applyFerpaAgeRestriction: true,
  craftProJobsiteEvaluatorStatus: 'n/a',
  canClaimWalletCard: false,
  releaseCLMA: false,
};
