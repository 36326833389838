import { Button, Typography } from '@mui/material';
import classNames from 'classnames';
import { Completion } from 'store/types/Completions';
import React, { useCallback, useState } from 'react';
import CredentialService from 'services/api/CredentialService';
import { useSnackbar } from 'notistack';

import commonStyles from 'styles/common.module.scss';
import styles from './CompletionCard.module.scss';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import routes from 'store/configs/Routes';

interface CompletionCardProps {
  item: Completion;
  showContentType?: boolean;
}

const CompletionCard: React.FunctionComponent<CompletionCardProps> = ({ item, showContentType = true }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const handlePrintClick = useCallback(
    (docLink) => {
      setLoading(true);
      CredentialService.getDocumentLink(docLink)
        .then((response) => {
          setLoading(false);
          window.location.assign(response);
        })
        .catch((error) => {
          setLoading(false);
          enqueueSnackbar(error?.body, { variant: 'error' });
        });
    },
    [enqueueSnackbar]
  );

  const getFamilyFormatText = (item: Completion) => {
    if (item.Content.Family && item.Content.Format) {
      return item.Content.Family + ' : ' + item.Content.Format;
    } else if (!item.Content.Format) {
      return item.Content.Family;
    } else if (!item.Content.Family) {
      return item.Content.Format;
    } else {
      return '';
    }
  };

  return (
    <div className={classNames(commonStyles.contentCard, styles.credentialCard)}>
      <Typography className={styles.cardTitle} variant={'body1'}>
        {item.Content.Name}
      </Typography>
      {item.SubmissionDate && (
        <Typography variant={'body2'}>Date Earned: {moment(item.SubmissionDate).format('L')}</Typography>
      )}
      <Typography variant={'body2'}>{getFamilyFormatText(item)}</Typography>
      <Typography variant={'body2'}>{item.Content.Type}</Typography>
      {item.Content.Type === 'Module' && showContentType && (
        <Typography variant={'body2'}>{item.Content.ContentId}</Typography>
      )}
      {/* <NavLink
        to={
          routes.badgeDetails +
          "?name=" +
          item.Content.Name +
          (item.Document?.Link ? "&docLink=" + item.Document?.Link : "")
        }
      >
        {item.Document?.Link ? "View/Print Details" : "View Details"}
      </NavLink> */}
      {item.Document?.Link ? (
        <>
          <br />
          <Button
            onClick={() => handlePrintClick(item.Document.Link)}
            variant={'contained'}
            color={'primary'}
            className={styles.printCredentialBtn}
          >
            {'View/Print'}
          </Button>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
export default CompletionCard;
