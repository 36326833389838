import SiteModule from 'store/enums/SiteModule';
import routes from 'store/configs/Routes';

const moduleRoutesConfig: Partial<Record<SiteModule, string>> = {
  [SiteModule.Home]: routes.home,
  [SiteModule.Profile]: routes.profile,
  [SiteModule.Settings]: routes.settings,
  [SiteModule.Credentials]: routes.myCredentials,
  [SiteModule.Reports]: routes.reports,
  [SiteModule.VerifyCredentials]: routes.verifyCredentials,
  [SiteModule.PrintCredentials]: routes.printCredentials,
};

export default moduleRoutesConfig;
