import { Button, Typography } from '@mui/material';
import classNames from 'classnames';
import { Credential } from 'store/types/Credentials';
import React, { useCallback, useState } from 'react';
import CredentialService from 'services/api/CredentialService';
import { useSnackbar } from 'notistack';

import commonStyles from 'styles/common.module.scss';
import styles from 'components/credentials/CompletionCard/CompletionCard.module.scss';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import routes from 'store/configs/Routes';
// import craftProStamp from "assets/images/craftPro/craftProCredCardStamp.svg";

interface CredentialCardProps {
  item: Credential;
  showContentType?: boolean;
}

const CredentialCard: React.FunctionComponent<CredentialCardProps> = ({ item, showContentType = true }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const docLink = item.Documents[0]?.Link;

  const handlePrintClick = useCallback(
    (docLink) => {
      setLoading(true);
      CredentialService.getDocumentLink(docLink)
        .then((response) => {
          setLoading(false);
          window.location.assign(response);
        })
        .catch((error) => {
          setLoading(false);
          enqueueSnackbar(error?.body, { variant: 'error' });
        });
    },
    [enqueueSnackbar]
  );

  return (
    <div className={classNames(commonStyles.contentCard, styles.credentialCard)}>
      <Typography className={styles.cardTitle} variant={'body1'}>
        {/* <img src={craftProStamp} className={styles.stamp} /> */}
        {item.CredentialName}
      </Typography>
      {item.CredentialDate && (
        <Typography variant={'body2'}>Date Earned: {moment(item.CredentialDate).format('L')}</Typography>
      )}
      {item.ExpireDate && (
        <Typography variant={'body2'}>Expiration Date: {moment(item.ExpireDate).format('L')}</Typography>
      )}
      {/* <Typography variant={"body1"}>{getFamilyFormatText(item)}</Typography> */}
      <Typography variant={'body2'}>{item.ContentType}</Typography>
      {/* {item.Content.Type === "Module" && showContentType && (
        <Typography variant={"body1"}>{item.Content.ContentId}</Typography>
      )} */}
      {/* <NavLink
        to={routes.badgeDetails + "?name=" + item.CredentialName + (docLink ? "&docLink=" + docLink : "")}
        className={styles.detailsLink}
      >
        <Button variant={"contained"} color={"primary"} fullWidth={true} className={styles.printCredentialBtn}>
          {docLink ? "View/Print Details" : "View Details"}
        </Button>
      </NavLink> */}
      {item.Documents && item.Documents[0]?.Link ? (
        <>
          <br />
          <Button
            onClick={() => handlePrintClick(item.Documents[0].Link)}
            variant={'contained'}
            color={'primary'}
            className={styles.printCredentialBtn}
          >
            {'View/Print'}
          </Button>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
export default CredentialCard;
