import classNames from 'classnames';
import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import ComponentProps from 'store/types/ComponentProps';

import styles from './Spinner.module.scss';

interface SpinnerProps extends ComponentProps {
  loading?: boolean;
  fullPage?: boolean;
  transparent?: boolean;
  label?: string;
}

const Spinner: React.FunctionComponent<SpinnerProps> = ({
  children,
  loading = false,
  fullPage = false,
  transparent = true,
  label = '',
  className = '',
}) => (
  <>
    {fullPage ? (
      <>
        {children}
        <Backdrop
          data-testid={'spinner-backdrop'}
          open={loading}
          className={classNames(styles.backdrop, className, { [styles.filled]: !transparent })}
        >
          <CircularProgress />
          {label && <span className={styles.label}>{label}</span>}
        </Backdrop>
      </>
    ) : (
      <div
        className={classNames(styles.container, className, { [styles.loading]: loading })}
        data-testid={'default-spinner'}
      >
        {loading && (
          <div
            className={classNames(styles.spinnerWrapper, { [styles.filled]: !transparent })}
            data-testid={'spinner-wrapper'}
          >
            <div className={styles.spinner}>
              <CircularProgress />
            </div>
          </div>
        )}
        {children}
      </div>
    )}
  </>
);
export default Spinner;
