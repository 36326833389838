import classNames from 'classnames';
import React, { useCallback } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { Controller, FieldErrors, useFormContext, useWatch } from 'react-hook-form';
import {
  getMaxLengthValidationRule,
  getRequiredValidationRule,
  getValidationProps,
  isCanadaSelected,
  isUSSelected,
  FormProps,
  getAddressControlName,
  countryFieldName,
} from 'util/Form';
import { defaultGridItemProps } from 'util/Layout';
import CountryFormItem from './CountryFormItem';
import StateFormItem from './StateFormItem';
import PostalCodeFormItem from './PostalCodeFormItem';

import commonStyles from 'styles/common.module.scss';
import externalStyles from 'components/profile/EditProfilePageView/EditProfileForm/EditProfileForm.module.scss';

interface AddressFormSectionProps extends FormProps {
  addressFieldName?: string;
}

const AddressFormSection: React.FunctionComponent<AddressFormSectionProps> = (props) => {
  const {
    inputProps,
    rowProps = defaultGridItemProps,
    required = false,
    disabled = false,
    addressFieldName = 'address',
  } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const getControlName = useCallback(
    (label: string) => getAddressControlName(addressFieldName, label),
    [addressFieldName]
  );
  const validationProps = useCallback(
    (label: string) => getValidationProps(label, addressFieldName ? (errors[addressFieldName] as FieldErrors) : errors),
    [errors, addressFieldName]
  );
  const countryValue: string = useWatch({ control, name: getControlName(countryFieldName) });

  return (
    <Grid container>
      <Grid {...rowProps} xs={12}>
        {/* <h5 className={styles.fieldName}>Current Address</h5> */}
        <Typography variant={'h5'} className={externalStyles.fieldName}>
          Street Address
        </Typography>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              {...inputProps}
              {...validationProps('street1')}
              //label={'Address Line 1'}
              required={required}
              disabled={disabled}
              inputProps={{ 'data-testid': 'street1-input' }}
              data-testid={'street1-text-field'}
              className={externalStyles.inputField}
              FormHelperTextProps={{ 'data-testid': 'street1-helper-text' } as any}
            />
          )}
          name={getControlName('street1')}
          control={control}
          rules={{
            required: getRequiredValidationRule('address', false, required),
            maxLength: getMaxLengthValidationRule(150),
          }}
        />
      </Grid>
      <Grid {...rowProps} md={6}>
        <Typography variant={'h5'} className={externalStyles.fieldName}>
          City
        </Typography>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              {...inputProps}
              {...validationProps('city')}
              //label={'City'}
              required={required}
              disabled={disabled}
              className={externalStyles.inputField}
              inputProps={{ 'data-testid': 'city-input' }}
              data-testid={'city-text-field'}
              FormHelperTextProps={{ 'data-testid': 'city-helper-text' } as any}
            />
          )}
          name={getControlName('city')}
          control={control}
          rules={{
            required: getRequiredValidationRule('city', false, required),
            maxLength: getMaxLengthValidationRule(40),
          }}
        />
      </Grid>
      <Grid
        {...rowProps}
        md={6}
        className={classNames(
          {
            [commonStyles.hidden]: !(isUSSelected(countryValue) || isCanadaSelected(countryValue)),
          }
          //externalStyles.selectFieldWrapper
        )}
      >
        <Typography variant={'h5'} className={externalStyles.fieldName}>
          State
        </Typography>
        <StateFormItem {...props} addressFieldName={addressFieldName} />
      </Grid>
      <Grid {...rowProps} md={6}>
        <Typography variant={'h5'} className={externalStyles.fieldName}>
          Country
        </Typography>
        <CountryFormItem {...props} addressFieldName={addressFieldName} />
      </Grid>
      <Grid {...rowProps} md={6}>
        <Typography variant={'h5'} className={externalStyles.fieldName}>
          Zip Code
        </Typography>
        <PostalCodeFormItem {...props} addressFieldName={addressFieldName} />
      </Grid>
    </Grid>
  );
};
export default AddressFormSection;
