import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Box, Button, Grid, Link, Typography } from '@mui/material';

import commonStyles from 'styles/common.module.scss';
import styles from '../BadgeDetails/BadgeDetails.module.scss';
import styles2 from '../PublicBadgeView/PublicBadgeView.module.scss';
import { useParams, useLocation } from 'react-router-dom';
import greenCheckCircle from 'assets/icons/greenCheckCircle.svg';
import NCCER_logo_wordmark_positive_RGB from 'assets/images/nccer/NCCER_logo_wordmark_positive_RGB.png';

// interface CredentialDetailsProps {
//   credentialName?: string;
// }

const PublicBadgeView: React.FunctionComponent = () =>
  //   {
  //   credentialName
  // }
  {
    //const { nccerCardNumber } = useContext(UserContext);
    const [loading, setLoading] = useState<boolean>(false);
    const { search } = useLocation();
    //const values = queryString.parse(search);
    // useParams<testParams>();

    const skills = [
      'Recognize the benefits of careers in construction',
      'Recognize common geometric shapes',
      'Describe training and apprenticeship options',
      'Identify and properly use common hand tools',
    ];

    return (
      <>
        <div className={styles2.ovHeader}>
          <Grid container>
            <Grid item xs={2}>
              <img src={NCCER_logo_wordmark_positive_RGB} className={styles2.ovLogo} />
            </Grid>
            <Grid item xs={8}>
              <Typography variant={'h1'} className={styles2.searchedTitle}>
                {'Credential Search'}
              </Typography>
              <Typography variant={'h5'} className={styles2.searchedDescription}>
                {"Search and verify individuals' credentials"}
              </Typography>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </div>
        <div className={styles2.ovSearchedContent}>
          <Grid container>
            <Grid xs={2}></Grid>
            <Grid xs={8} className={commonStyles.contentCard}>
              <Typography variant={'body2'}>Badge Name</Typography>
              <Typography variant={'body1'}></Typography>

              <Typography variant={'body2'}>Badge Type</Typography>
              <Typography variant={'body1'}></Typography>

              <Typography variant={'body2'}>Badge Description</Typography>
              <Typography variant={'body1'}></Typography>
            </Grid>
            <Grid xs={2}></Grid>
            <Grid xs={2}></Grid>
            <Grid xs={8} className={commonStyles.contentCard}>
              <Typography variant={'h2'}>Skills</Typography>
              <Typography variant={'body1'} className={styles.skillSectionLabel}>
                {'The individual in possession of this badge has demonstrated the ability to:'}
              </Typography>
              <Grid container>
                {skills.map((value, key) => {
                  return (
                    <Grid xs={12} md={6} key={key} className={styles.skillDescription}>
                      <img src={greenCheckCircle} />
                      <Typography paragraph={false} variant={'body1'} className={styles.text}>
                        {value}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid xs={2}></Grid>
          </Grid>
        </div>
      </>
    );
  };
export default PublicBadgeView;
