/* Located in separate file to avoid circular dependencies. */

import { createContext } from 'react';

export interface ReportContext {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  closeModal: () => void;
  refreshDocumentList: () => void;
  getSuperintendentReport: (nccerNumbers: string) => void;
  handleProgress: (programId: number | string, reportId: number) => void;
}

export const ReportContext = createContext<ReportContext>({
  setLoading: () => undefined,
  closeModal: () => undefined,
  refreshDocumentList: () => undefined,
  getSuperintendentReport: () => undefined,
  handleProgress: () => undefined,
});
