interface DocumentRetrievalBase {
  programId: string;
  programName: string;
  reportName: string;
  dateRange: string;
  dateGenerated: Date | null | undefined;
  status: string;
  error: string | undefined;
}

export enum DocumentSource {
  CredentialEngine = 'CredentialEngine',
  PerformanceApp = 'PerformanceApp',
}

export enum DocumentStatusPerformance {
  Queued = 'Queued',
  Running = 'Running',
  Complete = 'Complete',
  Failed = 'Failed',
}

export interface DocumentRetrievalCredentialEngine extends DocumentRetrievalBase {
  documentSource: DocumentSource.CredentialEngine;
  contentTypeId: string | number;
  link: string | undefined;
}

export interface DocumentRetrievalPerformance extends DocumentRetrievalBase {
  documentSource: DocumentSource.PerformanceApp;
  documentId: string;
  status: DocumentStatusPerformance;
}

export type DocumentRetrieval = DocumentRetrievalCredentialEngine | DocumentRetrievalPerformance;

export function isErrorStatus(document: DocumentRetrieval) {
  return document.status === 'error' || document.status === DocumentStatusPerformance.Failed;
}

export function isRetrievable(document: DocumentRetrieval) {
  return document.documentSource === DocumentSource.CredentialEngine
    ? Boolean(document.link)
    : document.status === DocumentStatusPerformance.Complete;
}
