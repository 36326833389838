import { Button, Typography } from '@mui/material';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import CredentialService from 'services/api/CredentialService';
import { useSnackbar } from 'notistack';
import craftProStamp from 'assets/images/craftPro/craftProCredCardStamp.svg';

import commonStyles from 'styles/common.module.scss';
import styles from 'components/credentials/CompletionCard/CompletionCard.module.scss';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import routes from 'store/configs/Routes';
import { Completion } from 'store/types/Completions';
import type { Credential } from 'store/types/Credentials';
import { CredentialDocument } from 'store/types/CredentialDocument';

interface CraftProCardProps {
  item: Credential | Completion;
  showContentType?: boolean;
}
interface TypeSafeCredentialDetails {
  Name: string;
  Date: string;
  ContentType: string;
  ExpireDate?: string;
  ContentFormat?: string;
  ContentFamily?: string;
  ContentId?: string;
  Document: CredentialDocument;
  ShowBadge: boolean;
  Description?: string;
  CertificateLogoUrl?: string;
}

const CraftProCard: React.FunctionComponent<CraftProCardProps> = ({ item, showContentType = true }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const getTypeSafeItem = (item: Credential | Completion): TypeSafeCredentialDetails => {
    if ('CredentialName' in item) {
      const temp = item as Credential;
      return {
        Name: temp.CredentialName,
        Date: temp.CredentialDate,
        ExpireDate: temp.ExpireDate,
        ContentType: temp.ContentType,
        Document: temp.Documents[0] ?? null,
        ShowBadge: false,
      };
    } else {
      const temp = item as Completion;
      return {
        Name: temp.Content.Name,
        Date: temp.SubmissionDate,
        ContentType: temp.Content.Type,
        ContentId: temp.Content.ContentId,
        ContentFamily: temp.Content.Family,
        Document: temp.Document ?? null,
        ShowBadge:
          temp.Content.CertificateLogoUrl != undefined &&
          temp.Content.CertificateLogoUrl != null &&
          temp.Content.CertificateLogoUrl != '',
        Description: temp.Content.Description,
        CertificateLogoUrl: temp.Content.CertificateLogoUrl,
      };
    }
  };
  const typeSafeItem: TypeSafeCredentialDetails = getTypeSafeItem(item);
  const docLink = typeSafeItem.Document?.Link;

  const handlePrintClick = useCallback(
    (docLink) => {
      setLoading(true);
      CredentialService.getDocumentLink(docLink)
        .then((response) => {
          setLoading(false);
          window.location.assign(response);
        })
        .catch((error) => {
          setLoading(false);
          enqueueSnackbar(error?.body, { variant: 'error' });
        });
    },
    [enqueueSnackbar]
  );

  return (
    <div className={classNames(commonStyles.contentCard, styles.credentialCard)}>
      {typeSafeItem.ShowBadge && typeSafeItem.CertificateLogoUrl && (
        <img src={typeSafeItem.CertificateLogoUrl} className={styles.cardBadge} />
      )}
      <Typography className={styles.cardTitle} variant={'body1'}>
        {!typeSafeItem.ShowBadge && <img src={craftProStamp} className={styles.stamp} />}
        {typeSafeItem.Name}
      </Typography>
      {typeSafeItem.Date && (
        <Typography variant={'body2'}>Date Earned: {moment(typeSafeItem.Date).format('L')}</Typography>
      )}
      {typeSafeItem.ExpireDate && (
        <Typography variant={'body2'}>Expiration Date: {moment(typeSafeItem.ExpireDate).format('L')}</Typography>
      )}
      {typeSafeItem.ContentFamily && <Typography variant={'body2'}>{typeSafeItem.ContentFamily}</Typography>}
      {/* <br />
      <NavLink
        to={routes.badgeDetails + '?name=' + typeSafeItem.Name + (docLink ? '&docLink=' + docLink : '')}
        className={styles.detailsLink}
      >
        <Button variant={'contained'} color={'primary'} fullWidth={true} className={styles.printCredentialBtn}>
          {docLink ? 'View/Print' : 'View Details'}
        </Button>
      </NavLink> */}
      {/* {item.Documents && item.Documents[0]?.Link ? (
        <Button
          onClick={() => handlePrintClick(item.Documents[0].Link)}
          variant={"contained"}
          color={"primary"}
          className={styles.printCredentialBtn}
        >
          {"View/Print"}
        </Button>
      ) : (
        <></>
      )} */}
    </div>
  );
};
export default CraftProCard;
