import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import AuthService from 'services/AuthService';
import routes from 'store/configs/Routes';

const ProtectedRoute: React.FunctionComponent<RouteProps> = (props) => (
  <Route {...props}>
    {AuthService.isAuthenticated() ? props.children : <Redirect to={routes.login} data-testid={'redirect'} />}
  </Route>
);
export default ProtectedRoute;
