import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Button, Grid } from '@mui/material';
import AuthService from 'services/AuthService';
import Spinner from 'components/shared/Spinner';
import { useHistory } from 'react-router';
import { defaultFormProps } from 'util/Form';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';
import LoginForm from 'components/login/LoginForm';
import { LoginFormValues } from 'store/types/FormValues';
import { UserContext } from 'components/UserGuard';
import { tenantConfig } from 'config';
import { getDefaultHomeRoute, isInternalIdpType } from 'util/Route';
import { ConfigContext } from 'components/ConfigGuard';
import ForgotPasswordModal from 'components/login/ForgotPasswordModal';

import pageStyles from '../LoginPage.module.scss';
import styles from './LoginSection.module.scss';

interface LoginSectionProps {
  onSignupClick: () => void;
}

const LoginSection: React.FunctionComponent<LoginSectionProps> = ({ onSignupClick }) => {
  const history = useHistory();
  const { enabledModules } = useContext(ConfigContext);
  const { refetchUser } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<LoginFormValues>({
    ...defaultFormProps,
    defaultValues: {
      username: '',
      password: '',
    },
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState<boolean>(false);
  const { handleSubmit } = form;
  const isInternalIdp = isInternalIdpType(tenantConfig);
  const defaultHomeRoute: string = useMemo(() => getDefaultHomeRoute(enabledModules), [enabledModules]);

  const handleSignupClick = useCallback(() => {
    onSignupClick();
  }, [onSignupClick]);

  const handleResetPasswordModalClose = useCallback(() => {
    setResetPasswordModalOpen(false);
  }, []);

  const handleResetLinkClick = useCallback(() => {
    setResetPasswordModalOpen(true);
  }, []);

  const submitForm = useCallback(
    ({ username, password }: LoginFormValues) => {
      setLoading(true);
      AuthService.login({ username, password })
        .then(() => {
          setLoading(false);
          refetchUser();
          history.replace(defaultHomeRoute);
        })
        .catch((error: string) => {
          setLoading(false);
          enqueueSnackbar(error, { variant: 'error' });
        });
    },
    [history, enqueueSnackbar, defaultHomeRoute, refetchUser]
  );

  return (
    <>
      <Spinner loading={loading} fullPage={true}>
        <FormProvider {...form}>
          <form className={styles.form}>
            <Grid {...defaultGridContainerProps} alignItems={'center'}>
              <Grid {...defaultGridItemProps}>
                <LoginForm />
              </Grid>
              <Grid {...defaultGridItemProps} sm={'auto'}>
                <Button
                  variant={'contained'}
                  color={'secondary'}
                  type={'submit'}
                  className={pageStyles.button}
                  onClick={handleSubmit(submitForm)}
                  fullWidth={true}
                >
                  {'Log in'}
                </Button>
              </Grid>
              <Grid {...defaultGridItemProps} sm={'auto'}>
                <Button
                  variant={'outlined'}
                  color={'secondary'}
                  className={pageStyles.button}
                  onClick={handleSignupClick}
                  fullWidth={true}
                >
                  {'Sign Up'}
                </Button>
              </Grid>
              {isInternalIdp && (
                <Grid {...defaultGridItemProps} sm={'auto'} className={styles.linkButtonWrapper}>
                  <Button
                    variant={'text'}
                    color={'primary'}
                    fullWidth={true}
                    onClick={handleResetLinkClick}
                    className={styles.linkButton}
                  >
                    {'Forgot Password'}
                  </Button>
                </Grid>
              )}
            </Grid>
          </form>
        </FormProvider>
      </Spinner>
      <ForgotPasswordModal open={resetPasswordModalOpen} onClose={handleResetPasswordModalClose} />
    </>
  );
};
export default LoginSection;
