import React, { useCallback, useContext, useRef, useState } from 'react';
import { UserContext } from 'components/UserGuard';
import Spinner from 'components/shared/Spinner';
import SiteModule from 'store/enums/SiteModule';
import { getPageTitle } from 'util/Layout';
import { ConfigContext } from 'components/ConfigGuard';

import styles from './SettingsPage.module.scss';
import commonStyles from 'styles/common.module.scss';
import ProfileForm from 'components/profile/ProfileForm';
import { Button, Tab, Tabs, Typography } from '@mui/material';
import EditProfileForm from 'components/profile/EditProfilePageView/EditProfileForm/EditProfileForm';
import Header from 'components/layout/Header';
import { tenantConfig } from 'config';
import largeComputer from 'assets/icons/largeComputer.svg';
import { useWindowSize } from 'util/Window';
import classNames from 'classnames';
import TabPanel from 'components/shared/TabPanel';

const SettingsPage: React.FunctionComponent = () => {
  const { isMobile } = useWindowSize();
  const { sidebar } = useContext(ConfigContext);
  const { userLoading = false, ...user } = useContext(UserContext); // userLoading = initial call
  const [loading, setEditLoading] = useState<boolean>(false); // loading = flag for manual profile updates
  const [editFlag, setEditFlag] = useState<boolean>(false);
  const submitRef = useRef<HTMLButtonElement>(null);
  const [value, setValue] = React.useState(0);
  const { changeEmailUrl = '', confirmEmailUrl = '', changePasswordUrl = '' } = tenantConfig;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const setEditFlagTrue = useCallback(() => {
    setEditFlag(true);
  }, []);
  const setEditFlagFalse = useCallback(() => {
    setEditFlag(false);
  }, []);

  return (
    <>
      {!isMobile && <Header />}
      <div className={styles.settingsHeader}>
        <Typography variant={'h2'} className={commonStyles.pageTitle} py={'24px'}>
          {getPageTitle(sidebar, SiteModule.Settings) || 'Account Settings'}
        </Typography>

        {/* TABS */}
        <Tabs className={commonStyles.bottomBorder} value={value} onChange={handleChange}>
          <Tab label={'Profile'} className={commonStyles.tabText} />
          <Tab label={'Email'} className={commonStyles.tabText} />
          <Tab label={'Password'} className={commonStyles.tabText} />
        </Tabs>
      </div>

      {/* Tab 1 Header */}
      <TabPanel
        value={value}
        index={0}
        className={classNames(styles.settingsHeader, styles.sticky, commonStyles.bottomBorder)}
      >
        <div className={classNames(styles.subHeaderTextWrapper, commonStyles.subHeaderTextWrapper)}>
          <Typography variant={'body1'} className={commonStyles.subHeaderDescription}>
            {'Update your contact info, personal details, alternate ID, and photo.'}
          </Typography>
          {editFlag ? (
            <div className={classNames(commonStyles.subHeaderActions, styles.subHeaderActions)}>
              <Button
                onClick={() => submitRef.current?.click()}
                color={'primary'}
                variant={'contained'}
                disabled={loading}
              >
                {'Save'}
              </Button>
              <Button onClick={setEditFlagFalse} color={'secondary'} variant={'outlined'} disabled={loading}>
                {'Cancel'}
              </Button>
            </div>
          ) : (
            <div className={classNames(commonStyles.subHeaderActions, styles.subHeaderActions)}>
              <Button onClick={setEditFlagTrue} color={'primary'} variant={'contained'}>
                {'Edit Profile'}
              </Button>
            </div>
          )}
        </div>
      </TabPanel>

      {/* Tab 2 Header */}
      <TabPanel value={value} index={1} className={classNames(styles.settingsHeader, commonStyles.bottomBorder)}>
        <div className={classNames(styles.subHeaderTextWrapper, commonStyles.subHeaderTextWrapper)}>
          <Typography variant={'body1'} className={commonStyles.subHeaderDescription}>
            {'Change your email through the link below.'}
          </Typography>
        </div>
      </TabPanel>

      {/* Tab 3 Header */}
      <TabPanel value={value} index={2} className={classNames(styles.settingsHeader, commonStyles.bottomBorder)}>
        <div className={classNames(styles.subHeaderTextWrapper, commonStyles.subHeaderTextWrapper)}>
          <Typography variant={'body1'} className={commonStyles.subHeaderDescription}>
            {'Change your password through the link below.'}
          </Typography>
        </div>
      </TabPanel>

      {/* Content */}
      <Spinner loading={userLoading || loading} transparent={true}>
        <div className={classNames(commonStyles.pageContent, styles.settingsContent)}>
          <TabPanel value={value} index={0}>
            {editFlag ? (
              <EditProfileForm submitRef={submitRef} editFlag={setEditFlagFalse} setLoading={setEditLoading} />
            ) : (
              <ProfileForm setLoading={setEditLoading} />
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {changeEmailUrl && (
              <div className={commonStyles.contentCard}>
                <div className={commonStyles.computerIconBox}>
                  <img src={largeComputer} />
                </div>
                <Button
                  color={'primary'}
                  variant={'contained'}
                  type={'submit'}
                  fullWidth={true}
                  href={changeEmailUrl + encodeURIComponent(user.email)}
                >
                  {'Change Email'}
                </Button>
              </div>
            )}
            {confirmEmailUrl && (
              <div className={commonStyles.contentCard}>
                <div className={commonStyles.computerIconBox}>
                  <img src={largeComputer} />
                </div>
                <Button color={'primary'} variant={'contained'} type={'submit'} fullWidth={true} href={confirmEmailUrl}>
                  {'Confirm Email'}
                </Button>
              </div>
            )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {changePasswordUrl && (
              <div className={commonStyles.contentCard}>
                <div className={commonStyles.computerIconBox}>
                  <img src={largeComputer} />
                </div>
                <Button
                  color={'primary'}
                  variant={'contained'}
                  type={'submit'}
                  fullWidth={true}
                  href={changePasswordUrl + encodeURIComponent(user.email)}
                >
                  {'Change Password'}
                </Button>
              </div>
            )}
          </TabPanel>
        </div>
      </Spinner>
    </>
  );
};
export default SettingsPage;
