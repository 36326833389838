import { API_URL, tenantConfig } from 'config';
import AuthService from 'services/AuthService';
import { HttpError, HttpService } from 'services/HttpService';

class RegistryService {
  public static getScoreReportIndividual(nccerNumber: string): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      HttpService.getFileDownload(`${API_URL}/reports/score/${nccerNumber}`, {
        headers: {
          TenantId: tenantConfig.id,
          authorization: AuthService.getBearerToken(),
        },
      })
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error: HttpError) => {
          if (error?.code == 404) reject('No data was found for this NCCER number.');
          if (error?.code == 500) reject('An unexpected error occurred.');
          reject;
        });
    });
  }
}

export default RegistryService;
