import React, { useCallback, useContext, useEffect, useState } from 'react';
import Header from 'components/layout/Header';
import {
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';

import commonStyles from 'styles/common.module.scss';
import styles from './ViewMyCredentials.module.scss';
import CredentialService from 'services/api/CredentialService';
import { UserContext } from 'components/UserGuard';
import Spinner from 'components/shared/Spinner';
import clipboards from 'assets/icons/clipboards.svg';
import { Completion, Completions } from 'store/types/Completions';
import { useSnackbar } from 'notistack';
import PrintTranscriptButton from 'components/credentials/PrintTranscriptButton';
import { useWindowSize } from 'util/Window';
import { defaultGridContainerProps, defaultGridItemProps, getPageTitle } from 'util/Layout';
import { errorMessage } from 'util/Request';
import TabPanel from 'components/shared/TabPanel';
import CompletionCard from 'components/credentials/CompletionCard';
import CredentialCard from 'components/credentials/CredentialCard';
import { TableColumn } from 'store/types/Table';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import Table from 'components/shared/Table';
import { Credential } from 'store/types/Credentials';
import moment from 'moment';
import { pipelineTypes } from 'store/configs/CredentialTypes';
import SiteModule from 'store/enums/SiteModule';
import { ConfigContext } from 'components/ConfigGuard';
import CraftProCard from '../CraftProCard';
import { GridView, InfoOutlined, ViewAgenda, ViewAgendaOutlined } from '@mui/icons-material';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import CredlyOptInModal from 'components/shared/CredlyOptInModal';

const ViewMyCredentials: React.FunctionComponent = () => {
  const { isMobile } = useWindowSize();
  const { nccerCardNumber } = useContext(UserContext);
  const { sidebar } = useContext(ConfigContext);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [credsState, setCreds] = useState<Completions>();
  const [tabValue, setTabValue] = React.useState(0);

  const [credlyOptInOpen, setCredlyOptInOpen] = React.useState<boolean>(false);

  const [viewType, setViewType] = React.useState<boolean>(true); // false = list/grid, true = tile
  const paginationLength = 10;

  const trainingModules = credsState?.Completions?.filter(
    (item) => item.Content.Type === 'Module' && !item.Content.Format && !pipelineTypes.includes(item.Content.Family)
  )
    .sort((a, b) => a.Content.ContentId.localeCompare(b.Content.ContentId))
    .sort((a, b) => a.Content.Family?.localeCompare(b.Content.Family));
  const trainingLevels = credsState?.Completions?.filter((item) => item.Content.Type === 'Level').sort((a, b) =>
    a.Content.Name.localeCompare(b.Content.Name)
  );
  const trainingCrafts = credsState?.Completions?.filter((item) => item.Content.Type === 'Craft').sort((a, b) =>
    a.Content.Name.localeCompare(b.Content.Name)
  );
  const trainingTests = credsState?.Credentials?.filter((item) => item.ContentType == 'Test').sort((a, b) =>
    a.CredentialName.localeCompare(b.CredentialName)
  );
  const trainingCount =
    (trainingModules?.length ?? 0) +
    (trainingLevels?.length ?? 0) +
    (trainingCrafts?.length ?? 0) +
    (trainingTests?.length ?? 0);

  const knowledgeVerifications = credsState?.Credentials?.filter(
    (item) => item.ContentType == 'Knowledge Verified'
  ).sort((a, b) => a.CredentialName.localeCompare(b.CredentialName));
  const performanceVerifications = credsState?.Credentials?.filter(
    (item) => item.ContentType == 'Performance Verified'
  ).sort((a, b) => a.CredentialName.localeCompare(b.CredentialName));
  const jlaCount = (knowledgeVerifications?.length ?? 0) + (performanceVerifications?.length ?? 0);

  const craftCertifications = credsState?.Credentials?.filter((item) => item.ContentType == 'Certified').sort((a, b) =>
    a.CredentialName.localeCompare(b.CredentialName)
  );
  const personnelCertifications = credsState?.Credentials?.filter(
    (item) => item.ContentType == 'Program Personnel'
  ).sort((a, b) => a.CredentialName.localeCompare(b.CredentialName));
  const certificateCount = (craftCertifications?.length ?? 0) + (personnelCertifications?.length ?? 0);

  const fullModulePipelineCompletions = credsState?.Completions?.filter(
    (item) => pipelineTypes.includes(item.Content.Family) && !item.Content.Format
  )
    .sort((a, b) => a.Content.Name.localeCompare(b.Content.Name))
    .sort((a, b) => a.Content.ContentId.localeCompare(b.Content.ContentId));
  const writtenPipelineCompletions = credsState?.Completions?.filter(
    (item) => pipelineTypes.includes(item.Content.Family) && item.Content.Format === 'Written'
  )
    .sort((a, b) => a.Content.Name.localeCompare(b.Content.Name))
    .sort((a, b) => a.Content.ContentId.localeCompare(b.Content.ContentId));
  const performancePipelineCompletions = credsState?.Completions?.filter(
    (item) => pipelineTypes.includes(item.Content.Family) && item.Content.Format === 'Performance'
  )
    .sort((a, b) => a.Content.Name.localeCompare(b.Content.Name))
    .sort((a, b) => a.Content.ContentId.localeCompare(b.Content.ContentId));
  const pipelineCount =
    (fullModulePipelineCompletions?.length ?? 0) +
    (writtenPipelineCompletions?.length ?? 0) +
    (performancePipelineCompletions?.length ?? 0);

  const cpCourses = credsState?.Completions?.filter(
    (item) => item.Content.Type.localeCompare('Craftpro Course', undefined, { sensitivity: 'base' }) === 0
  ).sort((a, b) => a.Content.Name.localeCompare(b.Content.Name));
  const cpLearningPaths = credsState?.Completions?.filter(
    (item) => item.Content.Type.localeCompare('Craftpro Path', undefined, { sensitivity: 'base' }) === 0
  ).sort((a, b) => a.Content.Name.localeCompare(b.Content.Name));
  const craftProCount = (cpCourses?.length ?? 0) + (cpLearningPaths?.length ?? 0);

  const pageLoad = async () => {
    setLoading(true);
    try {
      const completions = await CredentialService.getCompletions(nccerCardNumber ?? '', 'false', true);
      setCreds(completions);
    } catch (error) {
      enqueueSnackbar(errorMessage(error), { variant: 'error' });
      setCreds(undefined);
    }
    setLoading(false);
  };

  useEffect(() => {
    pageLoad();
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleDropdownChange = (event: SelectChangeEvent) => {
    setTabValue(Number(event.target.value));
  };

  const handleListView = useCallback(() => {
    setViewType(false);
  }, []);

  const handleGridView = useCallback(() => {
    setViewType(true);
  }, []);

  const getListIcon = () => {
    if (viewType) {
      return <ViewAgendaOutlined className={commonStyles.listViewIcon} />;
    } else {
      return <ViewAgenda className={commonStyles.listViewIcon} />;
    }
  };

  const getGridIcon = () => {
    if (viewType) {
      return <GridViewRoundedIcon className={commonStyles.gridViewIcon} />;
    } else {
      return <GridView className={commonStyles.gridViewIcon} />;
    }
  };

  const handlePrintClick = useCallback(
    (docLink) => {
      setLoading(true);
      CredentialService.getDocumentLink(docLink)
        .then((response) => {
          setLoading(false);
          window.location.assign(response);
        })
        .catch((error) => {
          setLoading(false);
          enqueueSnackbar(errorMessage(error), { variant: 'error' });
        });
    },
    [enqueueSnackbar]
  );

  const pipelineCompletionColumns: Array<TableColumn<Completion>> = [
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Module ID</Typography>,
      render: (_, record) => <Typography>{record.Content.ContentId}</Typography>,
    },
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Module Name</Typography>,
      render: (_, record) => <Typography>{record.Content.Name}</Typography>,
    },
    {
      dataIndex: 'SubmissionDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.SubmissionDate ? moment(record.SubmissionDate).format('L') : null}</Typography>
      ),
    },
  ];

  const credentialColumns: Array<TableColumn<Credential>> = [
    {
      dataIndex: 'CredentialName',
      label: <Typography fontWeight="bold">Name</Typography>,
      render: (_, record) => <Typography>{record.CredentialName}</Typography>,
    },
    {
      dataIndex: 'CredentialDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.CredentialDate ? moment(record.CredentialDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'ExpireDate',
      label: <Typography fontWeight="bold">Expiration Date</Typography>,
      render: (_, record) => (
        <Typography>{record.ExpireDate ? moment(record.ExpireDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'Documents',
      label: <Typography fontWeight="bold">Document</Typography>,
      render: (_, record) =>
        record.Documents && record.Documents[0]?.Link ? (
          <Button
            onClick={() => handlePrintClick(record.Documents[0].Link)}
            variant={'contained'}
            color={'primary'}
            className={styles.printCredentialBtn}
          >
            {'View/Print'}
          </Button>
        ) : (
          <Typography className={commonStyles.noDocumentMarker}>None</Typography>
        ),
    },
  ];

  const assessmentColumns: Array<TableColumn<Credential>> = [
    {
      dataIndex: 'CredentialName',
      label: <Typography fontWeight="bold">Credential</Typography>,
      render: (_, record) => <Typography>{record.CredentialName}</Typography>,
    },
    {
      dataIndex: 'CredentialDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.CredentialDate ? moment(record.CredentialDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'Documents',
      label: <Typography fontWeight="bold">Document</Typography>,
      render: (_, record) =>
        record.Documents && record.Documents[0]?.Link ? (
          <Button
            onClick={() => handlePrintClick(record.Documents[0].Link)}
            variant={'contained'}
            color={'primary'}
            className={styles.printCredentialBtn}
          >
            {'View/Print'}
          </Button>
        ) : (
          <Typography className={commonStyles.noDocumentMarker}>None</Typography>
        ),
    },
  ];

  const craftCompletionColumns: Array<TableColumn<Completion>> = [
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Name</Typography>,
      render: (_, record) => <Typography>{record.Content.Name}</Typography>,
    },
    {
      dataIndex: 'SubmissionDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.SubmissionDate ? moment(record.SubmissionDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'Document',
      label: <Typography fontWeight="bold">Document</Typography>,
      render: (_, record) =>
        record.Document?.Link ? (
          <Button
            onClick={() => handlePrintClick(record.Document.Link)}
            variant={'contained'}
            color={'primary'}
            className={styles.printCredentialBtn}
          >
            {'View/Print'}
          </Button>
        ) : (
          <Typography className={commonStyles.noDocumentMarker}>None</Typography>
        ),
    },
  ];

  const levelCompletionColumns: Array<TableColumn<Completion>> = [
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Name</Typography>,
      render: (_, record) => <Typography>{record.Content.Name}</Typography>,
    },
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Craft</Typography>,
      render: (_, record) => <Typography>{record.Content.Family}</Typography>,
    },
    {
      dataIndex: 'SubmissionDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.SubmissionDate ? moment(record.SubmissionDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'Document',
      label: <Typography fontWeight="bold">Document</Typography>,
      render: (_, record) =>
        record.Document?.Link ? (
          <Button
            onClick={() => handlePrintClick(record.Document.Link)}
            variant={'contained'}
            color={'primary'}
            className={styles.printCredentialBtn}
          >
            {'View/Print'}
          </Button>
        ) : (
          <Typography className={commonStyles.noDocumentMarker}>None</Typography>
        ),
    },
  ];

  const moduleCompletionColumns: Array<TableColumn<Completion>> = [
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Module ID</Typography>,
      render: (_, record) => <Typography>{record.Content.ContentId}</Typography>,
    },
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Module Name</Typography>,
      render: (_, record) => <Typography>{record.Content.Name}</Typography>,
    },
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Craft</Typography>,
      render: (_, record) => <Typography>{record.Content.Family}</Typography>,
    },
    {
      dataIndex: 'SubmissionDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.SubmissionDate ? moment(record.SubmissionDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'Document',
      label: <Typography fontWeight="bold">Document</Typography>,
      render: (_, record) =>
        record.Document?.Link ? (
          <Button
            onClick={() => handlePrintClick(record.Document.Link)}
            variant={'contained'}
            color={'primary'}
            className={styles.printCredentialBtn}
          >
            {'View/Print'}
          </Button>
        ) : (
          <Typography className={commonStyles.noDocumentMarker}>None</Typography>
        ),
    },
  ];

  const trainingTestColumns: Array<TableColumn<Credential>> = [
    {
      dataIndex: 'CredentialName',
      label: <Typography fontWeight="bold">Credential</Typography>,
      render: (_, record) => <Typography>{record.CredentialName}</Typography>,
    },
    {
      dataIndex: 'CredentialDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.CredentialDate ? moment(record.CredentialDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'Documents',
      label: <Typography fontWeight="bold">Document</Typography>,
      render: (_, record) =>
        record.Documents && record.Documents[0]?.Link ? (
          <Button
            onClick={() => handlePrintClick(record.Documents[0].Link)}
            variant={'contained'}
            color={'primary'}
            className={styles.printCredentialBtn}
          >
            {'View/Print'}
          </Button>
        ) : (
          <Typography className={commonStyles.noDocumentMarker}>None</Typography>
        ),
    },
  ];

  const craftProCourseColumns: Array<TableColumn<Completion>> = [
    {
      dataIndex: 'Content',
      label: <Typography fontWeight="bold">Name</Typography>,
      render: (_, record) => <Typography>{record.Content.Name}</Typography>,
    },
    // {
    //   dataIndex: "Content",
    //   label: <Typography fontWeight="bold">Craft</Typography>,
    //   render: (_, record) => <Typography>{record.Content.Family}</Typography>,
    // },
    {
      dataIndex: 'SubmissionDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.SubmissionDate ? moment(record.SubmissionDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'Document',
      label: <Typography fontWeight="bold">Document</Typography>,
      render: (_, record) =>
        record.Document?.Link ? (
          <Button
            onClick={() => handlePrintClick(record.Document.Link)}
            variant={'contained'}
            color={'primary'}
            className={styles.printCredentialBtn}
          >
            {'View/Print'}
          </Button>
        ) : (
          <Typography className={commonStyles.noDocumentMarker}>None</Typography>
        ),
    },
  ];

  const craftProLearningPathColumns: Array<TableColumn<Credential>> = [
    {
      dataIndex: 'CredentialName',
      label: <Typography fontWeight="bold">Credential</Typography>,
      render: (_, record) => <Typography>{record.CredentialName}</Typography>,
    },
    {
      dataIndex: 'CredentialDate',
      label: <Typography fontWeight="bold">Date Earned</Typography>,
      render: (_, record) => (
        <Typography>{record.CredentialDate ? moment(record.CredentialDate).format('L') : null}</Typography>
      ),
    },
    {
      dataIndex: 'Documents',
      label: <Typography fontWeight="bold">Document</Typography>,
      render: (_, record) =>
        record.Documents && record.Documents[0]?.Link ? (
          <Button
            onClick={() => handlePrintClick(record.Documents[0].Link)}
            variant={'contained'}
            color={'primary'}
            className={styles.printCredentialBtn}
          >
            {'View/Print'}
          </Button>
        ) : (
          <Typography className={commonStyles.noDocumentMarker}>None</Typography>
        ),
    },
  ];

  const handleCredlyOptInModalClose = useCallback(() => {
    setCredlyOptInOpen(false);
  }, []);

  const handleCredlyOptInModalOpen = useCallback(() => {
    setCredlyOptInOpen(true);
  }, []);

  const hasCredentials = credsState && (credsState?.Completions?.length !== 0 || credsState?.Credentials?.length !== 0);

  return (
    <>
      {!isMobile && <Header />}
      <div className={styles.myCredentialsHeader}>
        <Grid {...defaultGridContainerProps}>
          <Grid {...defaultGridItemProps} md={hasCredentials ? 7 : 12}>
            <Typography variant={'h2'} className={commonStyles.pageTitle}>
              {getPageTitle(sidebar, SiteModule.Credentials) || 'My Credentials'}
            </Typography>
            <Typography variant={'body1'} className={styles.myCredsDescription}>
              {'Click '}
              <Link to={'#'} className={styles.credlyLink} onClick={handleCredlyOptInModalOpen}>
                {'here'}
              </Link>
              {' to activate your Credly account and share your digital credentials.'}
            </Typography>
          </Grid>
          <Grid
            {...defaultGridItemProps}
            {...defaultGridContainerProps}
            md={hasCredentials ? 5 : 0}
            justifyContent={'flex-end'}
          >
            {hasCredentials && (
              <>
                <Grid {...defaultGridItemProps} md={'auto'}>
                  {tabValue == 0 && (
                    <PrintTranscriptButton
                      providedNccerNumber={nccerCardNumber ?? ''}
                      isFullWidth={false}
                      loading={loading}
                      setLoading={setLoading}
                      className={styles.printTranscriptButton}
                    />
                  )}
                  {tabValue == 4 && (
                    <PrintTranscriptButton
                      providedNccerNumber={nccerCardNumber ?? ''}
                      isFullWidth={false}
                      loading={loading}
                      setLoading={setLoading}
                      className={styles.printTranscriptButton}
                      isPipeline={true}
                    />
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </Grid>

        {!isMobile && (
          <>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons={false}
              visibleScrollbar={true}
            >
              <Tab
                label={'Accredited Training'}
                icon={<Typography className={commonStyles.credentialCounter}>{trainingCount.toString()}</Typography>}
                iconPosition={'end'}
                className={commonStyles.tabText}
              />
              <Tab
                label={'CraftPro'}
                icon={<Typography className={commonStyles.credentialCounter}>{craftProCount.toString()}</Typography>}
                iconPosition={'end'}
                className={commonStyles.tabText}
              />
              <Tab
                label={'Journey-Level Assessments'}
                icon={<Typography className={commonStyles.credentialCounter}>{jlaCount.toString()}</Typography>}
                iconPosition={'end'}
                className={commonStyles.tabText}
              />
              <Tab
                label={'Certifications'}
                icon={<Typography className={commonStyles.credentialCounter}>{certificateCount.toString()}</Typography>}
                iconPosition={'end'}
                className={commonStyles.tabText}
              />
              <Tab
                label={'Pipeline OQ'}
                icon={<Typography className={commonStyles.credentialCounter}>{pipelineCount.toString()}</Typography>}
                iconPosition={'end'}
                className={commonStyles.tabText}
              />
            </Tabs>
            <Grid {...defaultGridContainerProps}>
              <Grid {...defaultGridItemProps} lg={10} md={9} sm={8}>
                <Typography variant={'body2'} className={commonStyles.tabDescriptor}>
                  {tabValue == 0 &&
                    'This training is delivered by organizations that have met the NCCER accreditation and personnel training requirements. NCCER curriculum is modular in format and presented as a multi-level curriculum that can be used to meet the DOL Office of Apprenticeship time requirements.'}
                  {tabValue == 1 &&
                    'CraftPro training consists of NCCER online courses selected by a company based on their unique project and skill requirements, and do not require accreditation.'}
                  {tabValue == 2 &&
                    'These assessments and performance verifications are intended to measure the knowledge and skills of experienced craft professionals.'}
                  {tabValue == 3 &&
                    'Certifications represent completion of both knowledge and performance evaluations. Note: this section also displays program personnel certifications.'}
                  {tabValue == 4 &&
                    'Pipeline OQ are written and/or performance completions needed to qualify individuals to perform activities on a pipeline according to operator qualification requirements.'}
                </Typography>
              </Grid>
              <Grid {...defaultGridItemProps} lg={2} md={3} sm={4} className={commonStyles.viewSelectorWrapper}>
                <ButtonGroup variant={'contained'} className={commonStyles.viewSelector}>
                  <IconButton
                    onClick={handleGridView}
                    className={classNames(commonStyles.gridViewButton, viewType ? commonStyles.activeViewButton : '')}
                  >
                    {getGridIcon()}
                    <Typography variant={'body2'} className={commonStyles.viewSelectorText}>
                      Grid
                    </Typography>
                  </IconButton>
                  <IconButton
                    onClick={handleListView}
                    className={classNames(commonStyles.listViewButton, !viewType ? commonStyles.activeViewButton : '')}
                  >
                    {getListIcon()}
                    <Typography variant={'body2'} className={commonStyles.viewSelectorText}>
                      List
                    </Typography>
                  </IconButton>
                </ButtonGroup>
              </Grid>
            </Grid>
          </>
        )}

        {isMobile && (
          <>
            <div className={styles.divider}></div>
            <FormControl fullWidth>
              <div>
                <Select sx={{ width: '87%' }} value={tabValue.toString()} onChange={handleDropdownChange}>
                  <MenuItem value={0}>{`Accredited Training  (${trainingCount.toString()})`}</MenuItem>
                  <MenuItem value={1}>{`CraftPro  (${craftProCount.toString()})`}</MenuItem>
                  <MenuItem value={2}>{`Journey-Level Assessments  (${jlaCount.toString()})`}</MenuItem>
                  <MenuItem value={3}>{`Certifications  (${certificateCount.toString()})`}</MenuItem>
                  <MenuItem value={4}>{`Pipeline OQ  (${pipelineCount.toString()})`}</MenuItem>
                </Select>
                {!viewType ? (
                  <IconButton sx={{ width: '13%' }} onClick={handleGridView}>
                    <GridViewRoundedIcon />
                  </IconButton>
                ) : (
                  <IconButton sx={{ width: '13%' }} onClick={handleListView}>
                    <FormatListBulletedRoundedIcon />
                  </IconButton>
                )}
              </div>
            </FormControl>
          </>
        )}
      </div>
      <Spinner loading={loading}>
        <div className={commonStyles.pageContent}>
          <TabPanel value={tabValue} index={0}>
            <Grid {...defaultGridContainerProps}>
              {!viewType && (
                <Grid {...defaultGridItemProps}>
                  {
                    <Table
                      list={trainingCrafts}
                      columns={craftCompletionColumns}
                      noResultsText="No crafts earned"
                      showPagination={trainingCrafts && trainingCrafts.length > paginationLength}
                      category="Completed Crafts"
                      className={commonStyles.listViewTable}
                    />
                  }
                  {
                    <Table
                      list={trainingLevels}
                      columns={levelCompletionColumns}
                      noResultsText="No levels earned"
                      showPagination={trainingLevels && trainingLevels.length > paginationLength}
                      category="Completed Levels"
                      className={commonStyles.listViewTable}
                    />
                  }
                  {
                    <Table
                      list={trainingModules}
                      columns={moduleCompletionColumns}
                      noResultsText="No modules earned"
                      showPagination={trainingModules && trainingModules.length > paginationLength}
                      category="Completed Modules"
                      className={commonStyles.listViewTable}
                    />
                  }
                  {
                    <Table
                      list={trainingTests}
                      columns={trainingTestColumns}
                      noResultsText="No tests completed"
                      showPagination={trainingTests && trainingTests.length > paginationLength}
                      category="Specialized Test Certificates"
                      className={commonStyles.listViewTable}
                    />
                  }
                </Grid>
              )}

              {viewType && (
                <Grid {...defaultGridItemProps}>
                  <Typography variant={'h6'} className={commonStyles.credentialCategory}>
                    Craft Completions
                  </Typography>
                </Grid>
              )}
              {viewType &&
                trainingCrafts &&
                trainingCrafts!.map((item, index) => (
                  <Grid {...defaultGridItemProps} md={3} key={index}>
                    <CompletionCard item={item} />
                  </Grid>
                ))}

              {viewType && (
                <Grid {...defaultGridItemProps}>
                  <Typography variant={'h6'} className={commonStyles.credentialCategory}>
                    Level Completions
                  </Typography>
                </Grid>
              )}
              {viewType &&
                trainingLevels &&
                trainingLevels.map((item, index) => (
                  <Grid {...defaultGridItemProps} md={3} key={index}>
                    <CompletionCard item={item} />
                  </Grid>
                ))}

              {viewType && (
                <Grid {...defaultGridItemProps}>
                  <Typography variant={'h6'} className={commonStyles.credentialCategory}>
                    Module Completions
                  </Typography>
                </Grid>
              )}
              {viewType &&
                trainingModules &&
                trainingModules.map((item, index) => (
                  <Grid {...defaultGridItemProps} md={3} key={index}>
                    <CompletionCard item={item} />
                  </Grid>
                ))}

              {viewType && (
                <Grid {...defaultGridItemProps}>
                  <Typography variant={'h6'} className={commonStyles.credentialCategory}>
                    Specialized Test Certificates
                  </Typography>
                </Grid>
              )}
              {viewType &&
                trainingTests &&
                trainingTests.map((item, index) => (
                  <Grid {...defaultGridItemProps} md={3} key={index}>
                    <CredentialCard item={item} />
                  </Grid>
                ))}
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Grid {...defaultGridContainerProps}>
              {!viewType && (
                <Grid {...defaultGridItemProps}>
                  {
                    <Table
                      list={cpLearningPaths}
                      columns={craftProLearningPathColumns}
                      category="Learning Path Credentials"
                      noResultsText="No learning path credentials earned"
                      showPagination={cpLearningPaths && cpLearningPaths.length > paginationLength}
                      className={commonStyles.listViewTable}
                    />
                  }
                  {
                    <Table
                      list={cpCourses}
                      columns={craftProCourseColumns}
                      category="Course Completions"
                      noResultsText="No course completions earned"
                      showPagination={cpCourses && cpCourses.length > paginationLength}
                      className={commonStyles.listViewTable}
                    />
                  }
                </Grid>
              )}
              {viewType && (
                <Grid {...defaultGridItemProps}>
                  <Typography variant={'h6'} className={commonStyles.credentialCategory}>
                    Learning Paths
                    <Tooltip
                      title="Collections of NCCER courses, curated by companies to meet specific training goals."
                      arrow
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: '#172535',
                            '& .MuiTooltip-arrow': {
                              color: '#172535',
                            },
                            padding: '12px',
                          },
                        },
                      }}
                    >
                      <IconButton>
                        <InfoOutlined />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
              )}
              {viewType &&
                cpLearningPaths &&
                cpLearningPaths!.map((item, index) => (
                  <Grid {...defaultGridItemProps} md={3} key={index}>
                    <CraftProCard item={item} showContentType={false} />
                  </Grid>
                ))}
              {viewType && (
                <Grid {...defaultGridItemProps}>
                  <Typography variant={'h6'} className={commonStyles.credentialCategory}>
                    All Courses
                    <Tooltip
                      title="Individual training requirements that make up each Learning Path."
                      arrow
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: '#172535',
                            '& .MuiTooltip-arrow': {
                              color: '#172535',
                            },
                            padding: '12px',
                          },
                        },
                      }}
                    >
                      <IconButton>
                        <InfoOutlined />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
              )}
              {viewType &&
                cpCourses &&
                cpCourses!.map((item, index) => (
                  <Grid {...defaultGridItemProps} md={3} key={index}>
                    <CraftProCard item={item} />
                  </Grid>
                ))}
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <Grid {...defaultGridContainerProps}>
              {!viewType && (
                <Grid {...defaultGridItemProps}>
                  {
                    <Table
                      list={knowledgeVerifications}
                      columns={assessmentColumns}
                      category="Knowledge Verified"
                      noResultsText="No credentials earned"
                      showPagination={knowledgeVerifications && knowledgeVerifications.length > paginationLength}
                      className={commonStyles.listViewTable}
                    />
                  }
                  {
                    <Table
                      list={performanceVerifications}
                      columns={assessmentColumns}
                      category="Performance Verified"
                      noResultsText="No credentials earned"
                      showPagination={performanceVerifications && performanceVerifications.length > paginationLength}
                      className={commonStyles.listViewTable}
                    />
                  }
                </Grid>
              )}

              {viewType && (
                <Grid {...defaultGridItemProps}>
                  <Typography variant={'h6'} className={commonStyles.credentialCategory}>
                    Knowledge Verified
                  </Typography>
                </Grid>
              )}
              {viewType &&
                knowledgeVerifications &&
                knowledgeVerifications!.map((item, index) => (
                  <Grid {...defaultGridItemProps} md={3} key={index}>
                    <CredentialCard item={item} />
                  </Grid>
                ))}

              {viewType && (
                <Grid {...defaultGridItemProps}>
                  <Typography variant={'h6'} className={commonStyles.credentialCategory}>
                    Performance Verified
                  </Typography>
                </Grid>
              )}
              {viewType &&
                performanceVerifications &&
                performanceVerifications!.map((item, index) => (
                  <Grid {...defaultGridItemProps} md={3} key={index}>
                    <CredentialCard item={item} />
                  </Grid>
                ))}
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <Grid {...defaultGridContainerProps}>
              {!viewType && (
                <Grid {...defaultGridItemProps}>
                  {
                    <Table
                      list={personnelCertifications}
                      columns={credentialColumns}
                      category="Program Personnel Certifications"
                      noResultsText="No credentials earned"
                      showPagination={personnelCertifications && personnelCertifications.length > paginationLength}
                      className={commonStyles.listViewTable}
                    />
                  }
                  {
                    <Table
                      list={craftCertifications}
                      columns={credentialColumns}
                      category="Craft Certifications"
                      noResultsText="No credentials earned"
                      showPagination={craftCertifications && craftCertifications.length > paginationLength}
                      className={commonStyles.listViewTable}
                    />
                  }
                </Grid>
              )}
              {viewType && (
                <Grid {...defaultGridItemProps}>
                  <Typography variant={'h6'} className={commonStyles.credentialCategory}>
                    Program Personnel Certifications
                  </Typography>
                </Grid>
              )}
              {viewType &&
                personnelCertifications &&
                personnelCertifications!.map((item, index) => (
                  <Grid {...defaultGridItemProps} md={3} key={index}>
                    <CredentialCard item={item} />
                  </Grid>
                ))}

              {viewType && (
                <Grid {...defaultGridItemProps}>
                  <Typography variant={'h6'} className={commonStyles.credentialCategory}>
                    Craft Certifications
                  </Typography>
                </Grid>
              )}
              {viewType &&
                craftCertifications &&
                craftCertifications!.map((item, index) => (
                  <Grid {...defaultGridItemProps} md={3} key={index}>
                    <CredentialCard item={item} />
                  </Grid>
                ))}
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
            <Grid {...defaultGridContainerProps}>
              {!viewType && (
                <Grid {...defaultGridItemProps}>
                  {
                    <Table
                      list={fullModulePipelineCompletions}
                      columns={pipelineCompletionColumns}
                      category="Completed Modules"
                      noResultsText="No module completions earned"
                      showPagination={
                        fullModulePipelineCompletions && fullModulePipelineCompletions.length > paginationLength
                      }
                      className={commonStyles.listViewTable}
                    />
                  }
                  {
                    <Table
                      list={writtenPipelineCompletions}
                      columns={pipelineCompletionColumns}
                      category="Written Completions"
                      noResultsText="No written completions earned"
                      showPagination={
                        writtenPipelineCompletions && writtenPipelineCompletions.length > paginationLength
                      }
                      className={commonStyles.listViewTable}
                    />
                  }
                  {
                    <Table
                      list={performancePipelineCompletions}
                      columns={pipelineCompletionColumns}
                      category="Performance Completions"
                      noResultsText="No performance completions earned"
                      showPagination={
                        performancePipelineCompletions && performancePipelineCompletions.length > paginationLength
                      }
                      className={commonStyles.listViewTable}
                    />
                  }
                </Grid>
              )}
              {viewType && (
                <Grid {...defaultGridItemProps}>
                  <Typography variant={'h6'} className={commonStyles.credentialCategory}>
                    Completed Modules
                  </Typography>
                </Grid>
              )}
              {viewType &&
                fullModulePipelineCompletions &&
                fullModulePipelineCompletions!.map((item, index) => (
                  <Grid {...defaultGridItemProps} md={3} key={index}>
                    <CompletionCard item={item} />
                  </Grid>
                ))}
              {viewType && (
                <Grid {...defaultGridItemProps}>
                  <Typography variant={'h6'} className={commonStyles.credentialCategory}>
                    Written Completions
                  </Typography>
                </Grid>
              )}
              {viewType &&
                writtenPipelineCompletions &&
                writtenPipelineCompletions!.map((item, index) => (
                  <Grid {...defaultGridItemProps} md={3} key={index}>
                    <CompletionCard item={item} />
                  </Grid>
                ))}
              {viewType && (
                <Grid {...defaultGridItemProps}>
                  <Typography variant={'h6'} className={commonStyles.credentialCategory}>
                    Performance Completions
                  </Typography>
                </Grid>
              )}
              {viewType &&
                performancePipelineCompletions &&
                performancePipelineCompletions!.map((item, index) => (
                  <Grid {...defaultGridItemProps} md={3} key={index}>
                    <CompletionCard item={item} />
                  </Grid>
                ))}
            </Grid>
          </TabPanel>
          {credsState?.Completions?.length == 0 && credsState?.Credentials?.length == 0 && !loading && (
            <Grid item xs={12} className={commonStyles.clipboardBox}>
              <img src={clipboards} />
              <Typography paragraph={true} className={commonStyles.text}>
                {'There are currently no training records in our system.'}
              </Typography>
            </Grid>
          )}
        </div>
      </Spinner>
      <CredlyOptInModal open={credlyOptInOpen} onClose={handleCredlyOptInModalClose}></CredlyOptInModal>
    </>
  );
};
export default ViewMyCredentials;
