import LoginService, { LoginResponse, LoginResponseData, LoginRequestData } from 'services/api/LoginService';
import {
  AUTH_FLAG_LS_LABEL,
  getLocalStorageValue,
  IMPERSONATION_FLAG_LS_LABEL,
  setLocalStorageValue,
  SUPER_ADMIN_DETAILS_FLAG_LS_LABEL,
  SUPER_ADMIN_FLAG_LS_LABEL,
} from 'util/LocalStorage';
import { tenantConfig } from 'config';
import jwt_decode from 'jwt-decode';

interface AuthCookies {
  idToken?: string;
  accessToken?: string;
  sessionState?: string;
}

const SUPER_ADMIN_ROLE_VALUE = 'superadmin';

interface Token {
  act: string;
  r: Array<typeof SUPER_ADMIN_ROLE_VALUE> | string;
}

interface AdminDetails {
  sub?: string;
}

const ID_TOKEN_COOKIE_LABEL = 'id_token=';
const ACCESS_TOKEN_COOKIE_LABEL = 'access_token=';
const SESSION_STATE_COOKIE_LABEL = 'session_state=';

class AuthService {
  private idToken: string;
  private accessToken: string;
  private sessionState: string;

  constructor() {
    this.idToken = '';
    this.accessToken = '';
    this.sessionState = '';

    if (document.cookie) {
      const cookieList: string[] = document.cookie.split(';');

      cookieList.forEach((item: string) => {
        const cookie: string = item.trim();

        if (cookie.indexOf(ID_TOKEN_COOKIE_LABEL) === 0) {
          this.idToken = cookie.substring(ID_TOKEN_COOKIE_LABEL.length, cookie.length);
        } else if (cookie.indexOf(ACCESS_TOKEN_COOKIE_LABEL) === 0) {
          this.accessToken = cookie.substring(ACCESS_TOKEN_COOKIE_LABEL.length, cookie.length);
        } else if (cookie.indexOf(SESSION_STATE_COOKIE_LABEL) === 0) {
          this.sessionState = cookie.substring(SESSION_STATE_COOKIE_LABEL.length, cookie.length);
        }
      });
    }
  }

  public getBearerToken(): string {
    return `Bearer ${this.idToken}`;
  }

  public getIdToken(): string {
    return this.idToken;
  }

  public getAccessToken(): string {
    return this.accessToken;
  }

  public getSessionState(): string {
    return this.sessionState;
  }

  public setAuthCookies(data?: AuthCookies): void {
    let isImpersonation = false;
    let isUserSuperAdmin = false;
    const idToken = data?.idToken || '';
    const accessToken = data?.accessToken || '';
    const sessionState = data?.sessionState || '';
    if (idToken) {
      const decodedToken: Token = jwt_decode<Token>(idToken);
      const act: AdminDetails | undefined = decodedToken.act && JSON.parse(decodedToken.act);
      isImpersonation = !!act?.sub;
      isUserSuperAdmin = decodedToken.r?.includes(SUPER_ADMIN_ROLE_VALUE);
      setLocalStorageValue(SUPER_ADMIN_DETAILS_FLAG_LS_LABEL, act?.sub || '');
    }
    this.idToken = idToken;
    this.accessToken = accessToken;
    this.sessionState = sessionState;
    document.cookie = `${ID_TOKEN_COOKIE_LABEL}${idToken}`;
    document.cookie = `${ACCESS_TOKEN_COOKIE_LABEL}${accessToken}`;
    document.cookie = `${SESSION_STATE_COOKIE_LABEL}${sessionState}`;
    setLocalStorageValue(IMPERSONATION_FLAG_LS_LABEL, isImpersonation);
    setLocalStorageValue(SUPER_ADMIN_FLAG_LS_LABEL, isUserSuperAdmin);
    setLocalStorageValue(AUTH_FLAG_LS_LABEL, !!idToken);
  }

  public login(loginValues: LoginRequestData): Promise<null> {
    return new Promise<null>((resolve, reject) => {
      LoginService.login(loginValues)
        .then((response: LoginResponseData) => {
          const { IdToken, AccessToken } = response as LoginResponse;

          this.setAuthCookies({ idToken: IdToken, accessToken: AccessToken });
          resolve(null);
        })
        .catch((error: string) => {
          reject(error);
        });
    });
  }

  public logout(redirectUrl?: string, reloadPage = false): void {
    const tempToken = this.idToken;

    this.setAuthCookies();

    if (redirectUrl) {
      window.location.assign(redirectUrl);
    } else if (tenantConfig.logoutUrl) {
      window.location.assign(
        tempToken ? `${tenantConfig.logoutUrl}&id_token_hint=${tempToken}` : tenantConfig.loginUrl
      );
    }
    if (reloadPage) {
      window.location.reload();
    }
  }
  public isAuthenticated(): boolean {
    return !!getLocalStorageValue(AUTH_FLAG_LS_LABEL);
  }
}

const authClient: AuthService = new AuthService();

export default authClient;
