import { HttpError, HttpService } from 'services/HttpService';
import { httpRequestWrapper } from 'util/Request';
import { tenantConfig, CE_API_TOKEN_TEMP } from 'config';

interface ReportQueueResponse {
  ReportId: number;
}

interface ReportStatusResponse {
  ReportId: number | string;
  Message: string;
  ReportComplete: boolean;
  ReportUri: string;
  Status: number;
}

class CreditEngineService {
  public static queueReport(
    programId: number | string,
    contentTypeId: number | string,
    startDate: string,
    endDate: string
  ): Promise<ReportQueueResponse> {
    return new Promise<ReportQueueResponse>(
      (resolve: (data: ReportQueueResponse) => void, reject: (error: HttpError) => void) => {
        HttpService.post(`${tenantConfig.ceApiUrl}/api/programs/${programId}/reports/completion`, {
          headers: {
            authorization: CE_API_TOKEN_TEMP,
          },
          data: {
            content_type_id: contentTypeId,
            start_date: startDate,
            end_date: endDate,
          },
        })
          .toPromise()
          .then((rawResponse: any) => {
            const parsedResponse: ReportQueueResponse = {
              ReportId: rawResponse.report_id,
            };
            resolve(parsedResponse);
          })
          .catch((error: HttpError) => {
            reject(error);
          });
      }
    );
  }

  public static queueBulkCertificatesReport(
    programId: number | string,
    startDate: string,
    endDate: string
  ): Promise<ReportQueueResponse> {
    return new Promise<ReportQueueResponse>(
      (resolve: (data: ReportQueueResponse) => void, reject: (error: HttpError) => void) => {
        HttpService.post(`${tenantConfig.ceApiUrl}/api/programs/${programId}/reports/bulk_certificates`, {
          headers: {
            authorization: CE_API_TOKEN_TEMP,
          },
          data: {
            start_date: startDate,
            end_date: endDate,
          },
        })
          .toPromise()
          .then((rawResponse: any) => {
            const parsedResponse: ReportQueueResponse = {
              ReportId: rawResponse.report_id,
            };
            resolve(parsedResponse);
          })
          .catch((error: HttpError) => {
            reject(error);
          });
      }
    );
  }

  public static getReportStatus(programId: number | string, reportId: number | string): Promise<ReportStatusResponse> {
    return new Promise<ReportStatusResponse>(
      (resolve: (data: ReportStatusResponse) => void, reject: (error: HttpError) => void) => {
        const request = new Request(`${tenantConfig.ceApiUrl}/api/programs/${programId}/reports/${reportId}`, {
          method: 'GET',
          redirect: 'manual',
          headers: {
            authorization: CE_API_TOKEN_TEMP,
          },
        });
        fetch(request)
          .then((data: Response | any) => {
            const reportStatus: ReportStatusResponse = {
              ReportComplete: data.type === 'opaqueredirect',
              ReportUri: `${tenantConfig.ceApiUrl}/api/programs/${programId}/reports/${reportId}`,
              Message: data.message,
              ReportId: reportId,
              Status: data.status,
            };
            resolve(reportStatus);
          })
          .catch((error: any) => {
            reject(error);
          });
      }
    );
  }

  public static getVerificationReport(ids: string): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.getFileDownload(
          `${tenantConfig.ceApiUrl}/api/learners/${ids
            ?.replaceAll(' ', '')
            ?.replaceAll('\n', ',')
            ?.replaceAll(';', ',')}/completions.csv?partials=all`,
          {
            headers: {
              authorization: CE_API_TOKEN_TEMP,
            },
          }
        ),
        resolve,
        reject
      );
    });
  }
}

export default CreditEngineService;
