import React from 'react';
import { Button } from '@mui/material';
import classNames from 'classnames';

import styles from './SidebarMenuButton.module.scss';

interface SidebarCollapseButtonProps {
  collapsed?: boolean;
  menuVisible?: boolean;
  isMobile?: boolean;
  onClick: () => void;
}

const SidebarMenuButton: React.FunctionComponent<SidebarCollapseButtonProps> = ({
  onClick,
  collapsed = false,
  menuVisible = false,
  isMobile = false,
}) => (
  <Button
    variant={'text'}
    onClick={onClick}
    disableRipple={true}
    className={classNames(styles.button, {
      [styles.leftAlignView]: !isMobile && collapsed,
      [styles.rightAlignView]: !isMobile && !collapsed,
      [styles.crossView]: isMobile && menuVisible,
    })}
  >
    <span className={styles.bar} />
    <span className={styles.bar} />
    <span className={styles.bar} />
  </Button>
);
export default SidebarMenuButton;
