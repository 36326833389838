import { tenantConfig } from 'config';
import moment from 'moment';
import AuthService from 'services/AuthService';
import { HttpError, HttpService } from 'services/HttpService';
import { DocumentRetrieval, DocumentSource, DocumentStatusPerformance } from 'store/types/DocumentRetrieval';
import { isAssessmentProgram, isTrainingProgram, Program } from 'store/types/User';

type ReportPayload =
  | {
      programId: string;
      startDate: string;
      endDate: string;
      error?: null | undefined;
    }
  | { error: string };

interface ListReport {
  id: string;
  type: string;
  status: string;
  requestedBy: string;
  createdAt: Date;
  updatedAt: Date;
  payload: ReportPayload;
  error?: string;
}
export type ListReportsOutput = ListReport[];

export async function listReports(): Promise<ListReportsOutput> {
  const url = new URL('v2/api/reports', tenantConfig.performanceAppUrl);
  const headers = { Authorization: AuthService.getBearerToken() };
  try {
    return await HttpService.get(url.href, { headers }).toPromise();
  } catch (error) {
    if (error instanceof HttpError) {
      // Return empty array when unauthorized.
      if (error.code === 401) return [];

      // Improper CORS setup leads to Network Error.
      // Return empty array as workaround until CORS is fixed.
      if (error.code === 0) return [];
    }
    throw error;
  }
}

interface GetReport {
  id: string;
  type: string;
  status: string;
  requestedBy: string;
  createdAt: Date;
  updatedAt: Date;
  payload: ReportPayload;
  error?: string;
  url?: string;
}
export type GetReportOutput = GetReport;

export async function getReport(id: string): Promise<GetReportOutput> {
  const url = new URL(`v2/api/reports/${id}`, tenantConfig.performanceAppUrl);
  const headers = { Authorization: AuthService.getBearerToken() };
  return HttpService.get(url.href, { headers }).toPromise();
}

export interface ReportSubmissionInput {
  programId: string;
  startDate: string;
  endDate: string;
  moduleId?: string;
  learnerCardNumber?: string;
  evaluatorCardNumber?: string;
}

export async function reportSubmission(data: ReportSubmissionInput) {
  const url = new URL('v2/api/reports/submissions', tenantConfig.performanceAppUrl);
  const headers = { Authorization: AuthService.getBearerToken() };
  return HttpService.post(url.href, { data, headers }).toPromise();
}

export function toDocumentRetrieval(report: ListReport, programs: Program[]): DocumentRetrieval {
  const { programId, startDate, endDate } =
    report.payload.error === null || report.payload.error === undefined
      ? report.payload
      : { programId: '', startDate: null, endDate: null };

  const program = programs.find((e) => e.programId === programId);
  const programName = isTrainingProgram(program)
    ? `${program?.programName} (Training)`
    : isAssessmentProgram(program)
    ? `${program?.programName} (Assessment)`
    : program?.programName ?? '';

  const start = moment(startDate);
  const end = moment(endDate);
  const format = (date: moment.Moment) => date.format('MM/DD/YYYY');

  return {
    documentSource: DocumentSource.PerformanceApp,
    documentId: report.id,
    reportName: report.type,
    status: DocumentStatusPerformance[report.status as keyof typeof DocumentStatusPerformance],
    programId: programId,
    programName: programName,
    dateRange: start.isValid() && end.isValid() ? `${format(start)} - ${format(end)}` : 'N/A',
    dateGenerated: report.createdAt,
    error: report.error,
  };
}
