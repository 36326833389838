import React, { useCallback, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import MobileNccerLogo from 'assets/images/nccer/NCCER_logo_wordmark_positive_RGB.png';
import CraftProLogo from 'assets/images/craftPro/craftProLogo_negative_cropped.png';
import SidebarMenu from './SidebarMenu';
import SidebarMenuButton from './SidebarMenuButton';
import { getLocalStorageValue, setLocalStorageValue, SIDEBAR_COLLAPSED_LS_LABEL } from 'util/LocalStorage';
import { useWindowSize } from 'util/Window';

import styles from './Sidebar.module.scss';
import { Box, Button, Divider, Grid, Link, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Support_Portal_Link } from 'config';
import FeedbackModal from 'components/shared/FeedbackModal';
import { getUserFullName } from 'util/Format';
import { UserContext } from 'components/UserGuard';
import { UserRole } from 'store/types/User';

const Sidebar: React.FunctionComponent = () => {
  const { isMobile } = useWindowSize();
  const { firstName, lastName, nccerCardNumber, logoUrl, roles } = useContext(UserContext);
  const [menuVisible, setMenuVisible] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);

  const isUserCraftProOnly = (roleList: UserRole[]) => {
    const filterstrings = ['craftpro', 'craft pro'];
    const regex = new RegExp(filterstrings.join('|'), 'i');
    return roleList.every((current) => regex.test(current.role));
  };

  const setCollapsedValue = (value: boolean) => {
    setLocalStorageValue(SIDEBAR_COLLAPSED_LS_LABEL, value);
    setCollapsed(value);
  };

  const handleFeedbackModalClose = useCallback(() => {
    setFeedbackOpen(false);
  }, []);

  useEffect(() => {
    const lsCollapsedValue: boolean | string = getLocalStorageValue(SIDEBAR_COLLAPSED_LS_LABEL);

    if (lsCollapsedValue) {
      setCollapsed(true);
    } else if (lsCollapsedValue === '') {
      setLocalStorageValue(SIDEBAR_COLLAPSED_LS_LABEL, false);
    }
    window.addEventListener('storage', syncCollapsedValue);
    return () => {
      window.removeEventListener('storage', syncCollapsedValue);
    };
  }, []);

  useEffect(() => {
    setMenuVisible(!isMobile);
  }, [isMobile]);

  const syncCollapsedValue = () => {
    setCollapsed(!!getLocalStorageValue(SIDEBAR_COLLAPSED_LS_LABEL));
  };

  const closeMenu = () => {
    setMenuVisible(false);
  };

  const handleBarButtonClick = () => {
    if (isMobile) {
      setMenuVisible(!menuVisible);
    } else {
      setCollapsedValue(!collapsed);
    }
  };

  return (
    <aside
      className={classNames(styles.sidebar, { [styles.collapsed]: collapsed }, { [styles.menuVisible]: menuVisible })}
    >
      <div className={styles.sidebarHeader}>
        {isMobile && isUserCraftProOnly(roles ?? []) ? (
          <a href={'/'} className={styles.logo}>
            <img src={CraftProLogo} alt={'logo'} />
          </a>
        ) : (
          <a href={'/'} className={styles.logo}>
            <img src={MobileNccerLogo} alt={'logo'} />
          </a>
        )}
        <SidebarMenuButton
          onClick={handleBarButtonClick}
          collapsed={collapsed}
          menuVisible={menuVisible}
          isMobile={isMobile}
        />
      </div>

      <div className={styles.sidebarContent}>
        <div className={styles.nccerContainer}>
          {isUserCraftProOnly(roles ?? []) ? (
            <a href={'/'} className={styles.nccerLogo}>
              <img src={CraftProLogo} alt={'logo'} />
            </a>
          ) : (
            <a href={'/'} className={styles.nccerLogo}>
              <img src={MobileNccerLogo} alt={'logo'} />
            </a>
          )}
        </div>
        <div className={styles.menuViewWrapper}>
          <div className={styles.profileInfo}>
            <Grid container spacing={1} justifyContent={'right'}>
              <Grid item xs={2}>
                <div className={styles.profileImageWrapper}>
                  {logoUrl !== null ? (
                    <img className={styles.profileImage} src={logoUrl} />
                  ) : (
                    <AccountCircleIcon className={styles.profileImage}></AccountCircleIcon>
                  )}
                </div>
              </Grid>
              <Grid item xs={10} container spacing={1} className={styles.textItems}>
                <Grid item xs={12}>
                  <div className={styles.nameplate}>{getUserFullName({ firstName, lastName })}</div>
                </Grid>
                <Grid item xs={12} className={styles.nccerNumberLabel}>
                  {'NCCER Number: ' + nccerCardNumber}
                </Grid>
              </Grid>
            </Grid>

            <Divider className={styles.divider} />
          </div>

          <SidebarMenu collapsed={collapsed && !isMobile} onItemClick={closeMenu} />
        </div>

        <div className={styles.auxButtonGroup}>
          {isMobile && (
            <>
              <div className={styles.needHelpButton}>
                <Button
                  variant={'outlined'}
                  color={'secondary'}
                  className={styles.auxiliaryButtonMobile}
                  href={Support_Portal_Link}
                  target={'_blank'}
                >
                  Visit Help Center
                </Button>
              </div>
              <Link
                href="https://www.nccer.org/terms-conditions/"
                color="secondary"
                target="_blank"
                className={styles.legalInfoLink}
              >
                Terms & Conditions - NCCER
              </Link>
              <Link
                href="https://www.nccer.org/privacy-policy/"
                color="secondary"
                target="_blank"
                className={styles.legalInfoLink}
              >
                Privacy Policy - NCCER
              </Link>
            </>
          )}
          {!isMobile && (
            <>
              <Box className={styles.needHelpBox}>
                <div className={styles.needHelpTextBox}>
                  <Typography className={styles.needHelpTitle}>{'Need Help?'}</Typography>
                  <Typography className={styles.needHelpDescription}>
                    {"Head to the NCCER help center for resources, how-to's, and more."}
                  </Typography>
                </div>
                <div className={styles.needHelpButton}>
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    className={styles.auxiliaryButton}
                    href={Support_Portal_Link}
                    target={'_blank'}
                  >
                    Visit Help Center
                  </Button>
                </div>
              </Box>
              <Link
                href="https://www.nccer.org/terms-conditions/"
                color="secondary"
                target="_blank"
                className={styles.legalInfoLink}
              >
                Terms & Conditions - NCCER
              </Link>
              <Link
                href="https://www.nccer.org/privacy-policy/"
                color="secondary"
                target="_blank"
                className={styles.legalInfoLink}
              >
                Privacy Policy - NCCER
              </Link>
            </>
          )}
        </div>
      </div>
      <FeedbackModal open={feedbackOpen} onClose={handleFeedbackModalClose}></FeedbackModal>
    </aside>
  );
};
export default Sidebar;
