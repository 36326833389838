import React, { useCallback, useContext } from 'react';
import { Button, Checkbox, Grid, Link, Typography } from '@mui/material';
import Modal from 'components/shared/Modal';
import { ModalProps } from 'store/types/ComponentProps';
import { useSnackbar } from 'notistack';
import UserService from 'services/api/UserService';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { UserContext } from 'components/UserGuard';
import { useSessionTabsContext } from 'components/SessionTabsContext';
import Spinner from 'components/shared/Spinner';
import credlyLogo from 'assets/images/credly/credlyLogo.png';
import nccerLogo from 'assets/images/nccer/NCCER_logo_wordmark_positive_RGB.png';
import { Credly_Portal_Link } from 'config';

import styles from 'components/shared/CredlyOptInModal/CredlyOptInModal.module.scss';

const CredlyOptInModal: React.FunctionComponent<ModalProps> = ({ open, onClose }) => {
  const { credlyUserAcceptedFlag } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const { addOpenTab } = useSessionTabsContext();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isChecked, setIsChecked] = React.useState<boolean>(false);
  const [affirmationState, setAffirmationState] = React.useState<boolean>(credlyUserAcceptedFlag ?? false);

  const handleCheckboxClick = useCallback(() => {
    setIsChecked(!isChecked);
  }, [isChecked]);

  const handleCredlyLinkClick = useCallback(() => {
    // Open Credly webpage in a different tab
    const win = window.open(Credly_Portal_Link, '_blank');
    addOpenTab(win);
  }, [addOpenTab]);

  const affirmCredlyAccess = useCallback(async () => {
    setLoading(true);
    try {
      await UserService.affirmCredlyAccess();
      enqueueSnackbar('You have successfully completed your NCCER affirmation for Credly.', { variant: 'success' });
      setAffirmationState(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [setLoading, enqueueSnackbar, setAffirmationState]);

  return (
    <Spinner loading={loading}>
      {affirmationState ? (
        <Modal
          loading={loading}
          open={open}
          title={'Credly Activated on your NCCER Account'}
          maxWidth={'sm'}
          onClose={onClose}
          disableBackdropClick={false}
          headerBodyDivider={true}
        >
          <Grid {...defaultGridContainerProps} sx={{ padding: '24px 0 0 24px' }}>
            <Grid {...defaultGridItemProps} className={styles.logosWrapper}>
              <img src={credlyLogo} />
              <span className={styles.text}>+</span>
              <img src={nccerLogo} />
            </Grid>
            <Typography variant={'body1'}>
              {
                'Now that you have successfully granted permission to share your badge data with Credly, you can now start earning digital badges!'
              }
            </Typography>
            <br />
            <Typography variant={'body1'}>
              {
                'Upon earning your first digital badge, you will receive an email from Credly allowing you to create your Credly account and claim your badge.  Each time a new digital badge is earned, Credly will send you an email to claim your badge.'
              }
            </Typography>
          </Grid>
        </Modal>
      ) : (
        <Modal
          loading={loading}
          open={open}
          title={'Credly Badges'}
          subTitleText={'Grant NCCER permission to share data with Credly.'}
          maxWidth={'sm'}
          onClose={onClose}
          disableBackdropClick={true}
          headerBodyDivider={true}
          footerBodyDivider={true}
          actions={
            <>
              <Button
                color={'primary'}
                variant={'contained'}
                onClick={affirmCredlyAccess}
                disabled={!isChecked}
                fullWidth={true}
                sx={{ marginBottom: '8px' }}
                className={styles.actionButton}
              >
                {'Grant NCCER Permission'}
              </Button>
              <Button
                color={'secondary'}
                variant={'outlined'}
                onClick={onClose}
                fullWidth={true}
                className={styles.actionButton}
              >
                {'No Thanks'}
              </Button>
            </>
          }
        >
          <Grid {...defaultGridContainerProps} className={styles.modalBody}>
            <Grid {...defaultGridItemProps} className={styles.logosWrapper}>
              <img src={credlyLogo} />
              <span className={styles.text}>+</span>
              <img src={nccerLogo} />
            </Grid>
            <Typography variant={'body1'}>
              {
                'We have partnered with Credly to provide you with seamless access to all of your earned digital badges. This is a great way to manage and download your achievements, which you can share with future employers.'
              }
            </Typography>
            <br />
            <Typography variant={'body1'}>
              {
                'To grant permission to NCCER to share your badge data with Credly, please check the box below. For more information on the benefits of Credly, '
              }
              <Link className={styles.credlyLink} onClick={handleCredlyLinkClick}>
                {'click here'}
              </Link>
              {'.'}
            </Typography>
            <br />
            <Grid {...defaultGridItemProps} className={styles.affirmationCheckboxWrapper}>
              <Checkbox sx={{ padding: '0' }} checked={isChecked} onChange={handleCheckboxClick} />
              <Typography variant={'body2'} className={styles.text}>
                {'I agree to the statement above.'}
              </Typography>
            </Grid>
          </Grid>
        </Modal>
      )}
    </Spinner>
  );
};
export default CredlyOptInModal;
