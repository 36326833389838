import React from 'react';
import { TextField, Grid } from '@mui/material';
import { FormProps, getRequiredValidationRule, getValidationProps, LOGIN_USERNAME_FIELD_ID } from 'util/Form';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { Controller, useFormContext } from 'react-hook-form';
import { LoginFormValues } from 'store/types/FormValues';
import PasswordFormItem from 'components/login/PasswordFormItem';

interface LoginFormProps extends FormProps {
  position?: 'horizontal' | 'vertical';
}

const LoginForm: React.FunctionComponent<LoginFormProps> = ({
  position = 'horizontal',
  rowProps = defaultGridItemProps,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<LoginFormValues>();

  return (
    <Grid {...defaultGridContainerProps}>
      <Grid {...rowProps} md={position === 'horizontal' ? 6 : 12}>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              {...getValidationProps('username', errors)}
              label={'Email or Card Number'}
              required={true}
              autoComplete={'on'}
              id={LOGIN_USERNAME_FIELD_ID}
            />
          )}
          name={'username'}
          control={control}
          rules={{ required: getRequiredValidationRule('username') }}
        />
      </Grid>
      <Grid {...rowProps} md={position === 'horizontal' ? 6 : 12}>
        <PasswordFormItem />
      </Grid>
    </Grid>
  );
};
export default LoginForm;
