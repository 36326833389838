import React, { useCallback, useState } from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Controller, useFormContext } from 'react-hook-form';
import { FormProps, getRequiredValidationRule, getValidationProps } from 'util/Form';
import { LoginFormValues } from 'store/types/FormValues';

const FIELD_NAME = 'password';

const PasswordFormItem: React.FunctionComponent<FormProps> = () => {
  const [valueVisible, setValueVisible] = useState<boolean>(false);
  const {
    control,
    formState: { errors },
  } = useFormContext<LoginFormValues>();

  const handleVisibilityClick = useCallback(() => {
    setValueVisible((prevState) => !prevState);
  }, []);

  return (
    <Controller
      render={({ field }) => (
        <TextField
          {...field}
          {...getValidationProps('password', errors)}
          label={'Password'}
          required={true}
          type={valueVisible ? 'text' : 'password'}
          autoComplete={'on'}
          InputProps={{
            endAdornment: (
              <InputAdornment position={'end'}>
                <IconButton onClick={handleVisibilityClick}>
                  {valueVisible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
      name={FIELD_NAME}
      control={control}
      rules={{ required: getRequiredValidationRule('password') }}
    />
  );
};
export default PasswordFormItem;
