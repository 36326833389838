import React, { useEffect } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import moment from 'moment';
import { defaultGridItemProps } from 'util/Layout';
import { DocumentRetrieval, isErrorStatus, isRetrievable } from 'store/types/DocumentRetrieval';

import commonStyles from 'styles/common.module.scss';

interface MobilePrintRetrievalViewProps {
  documents: Array<DocumentRetrieval>;
  handlePrintClick: (document: DocumentRetrieval) => void;
  refreshDocumentList: () => void;
}

export function MobilePrintRetrievalView({
  documents,
  handlePrintClick,
  refreshDocumentList,
}: MobilePrintRetrievalViewProps) {
  // Refresh document list on page load.
  useEffect(() => {
    refreshDocumentList();
  }, [refreshDocumentList]);

  return (
    <Grid {...defaultGridItemProps}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box className={commonStyles.contentCard}>
          <Typography paragraph={true}>
            Depending on the size of your print file(s), please allow up to 24 hours for your file(s) to generate.{' '}
            <strong>Retrieve File</strong> will appear in the far right column when your print file is ready.{' '}
            <strong>Processing</strong> will appear if your print file is still generating.
          </Typography>
          <Typography paragraph={true}>
            NOTE: print files will remain on this tab for 30 days, after which time the print file will need to be
            regenerated, if needed.
          </Typography>
          <Button variant="contained" color="primary" fullWidth onClick={refreshDocumentList}>
            Refresh
          </Button>
        </Box>

        {documents.map((document, index) => (
          <Box key={`report-${index}`} className={commonStyles.contentCard}>
            <Typography variant="h5">{document.programName ?? document.programId}</Typography>
            <Typography sx={{ my: 1 }}>{document.dateRange}</Typography>
            <Typography>
              {document.dateGenerated
                ? moment(document.dateGenerated).local().format('MM/DD/yyyy h:mm A').toString()
                : 'Invalid Generation Date'}
            </Typography>
            {isErrorStatus(document) ? (
              document.error?.includes('ReportNotGenerated') ? (
                <Typography>No data found</Typography>
              ) : (
                <Typography>Failed to generate</Typography>
              )
            ) : isRetrievable(document) ? (
              <Button onClick={() => handlePrintClick(document)} variant={'text'} color={'secondary'}>
                <Typography fontWeight="bold" color={'secondary'}>
                  Retrieve File
                </Typography>
              </Button>
            ) : (
              <Typography>Processing...</Typography>
            )}
          </Box>
        ))}
      </Box>
    </Grid>
  );
}
