import { Alert } from '@mui/material';
import Spinner from 'components/shared/Spinner';
import React, { useEffect, useState } from 'react';
import UserService from 'services/api/UserService';
import SiteConfig from 'store/types/SiteConfig';
import useRequest from 'hooks/useRequest';
import SiteTheme from 'store/enums/SiteTheme';

import commonStyles from 'styles/common.module.scss';

export const defaultStaticContext: SiteConfig = {
  enabledModules: [],
  quickLinks: [],
  sidebar: [],
  theme: SiteTheme.NccerSSO,
  uiSettings: {
    hideAccountSettings: false,
    hideCardNumber: false,
    hideNotes: false,
    portalHeader: '',
  },
};

export const ConfigContext = React.createContext<SiteConfig>(defaultStaticContext);

const ConfigGuard: React.FunctionComponent = ({ children }) => {
  const [staticData, setStaticData] = useState<SiteConfig>(defaultStaticContext);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { error, firstLoading, data } = useRequest<SiteConfig>(UserService.getSiteConfig);

  useEffect(() => {
    setErrorMessage(error || '');
  }, [error]);

  useEffect(() => {
    if (data) {
      const { enabledModules = [], quickLinks = [], sidebar = [], theme = SiteTheme.NccerSSO, uiSettings } = data;
      setStaticData((prevState) => ({
        ...prevState,
        enabledModules,
        quickLinks,
        sidebar,
        uiSettings: uiSettings || defaultStaticContext.uiSettings,
        isNccerSsoApp: theme === SiteTheme.NccerSSO,
        isNccerBillingApp: theme === SiteTheme.NccerBilling,
      }));
    }
  }, [data]);

  return firstLoading ? (
    <Spinner loading={true} fullPage={true} transparent={false} />
  ) : error ? (
    <Alert severity={'error'} className={commonStyles.alert}>
      {errorMessage}
    </Alert>
  ) : (
    <ConfigContext.Provider value={staticData}>{children}</ConfigContext.Provider>
  );
};
export default ConfigGuard;
