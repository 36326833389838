import { Link, Tooltip, Typography } from '@mui/material';
import classNames from 'classnames';
import { ConfigContext } from 'components/ConfigGuard';
import { UserContext } from 'components/UserGuard';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { LinkProps, NavLink, NavLinkProps, useLocation } from 'react-router-dom';
import { getAppMenuConfig } from 'util/Route';
import SidebarMenuListButton from 'components/layout/Sidebar/SidebarMenuListButton/SidebarMenuListButton';
import MenuConfigItem from 'store/types/MenuConfigItem';
import { getIconSvg } from 'util/Layout';

import styles from '../Sidebar.module.scss';

interface SidebarMenuProps {
  collapsed?: boolean;
  onItemClick?: () => void;
}

const SidebarMenu: React.FunctionComponent<SidebarMenuProps> = ({ collapsed = false, onItemClick }) => {
  const currentUser = useContext(UserContext);
  const { sidebar, isNccerBillingApp } = useContext(ConfigContext);
  const menuConfig = useMemo(
    () => getAppMenuConfig(currentUser, sidebar, isNccerBillingApp),
    [currentUser, isNccerBillingApp, sidebar]
  );
  const location = useLocation();
  const { pathname } = location;

  const [activeIcon, setActiveIcon] = useState<string>('');

  const getNavLinkProps = ({ onClick, title, icon }: MenuConfigItem, isChild: boolean) => {
    const handleClick = (e: any) => {
      if (onClick) {
        setActiveIcon(icon || '');
        onClick(e);
      }
      if (onItemClick) {
        setActiveIcon(icon || '');
        onItemClick();
      }
    };

    const props: Pick<NavLinkProps, 'tabIndex' | 'className' | 'onClick' | 'children'> = {
      tabIndex: 0,
      className: styles.link,
      onClick: handleClick,
      children: (
        <div className={styles.content}>
          {getIconSvg(icon, activeIcon)}
          {isChild && <span className={styles.smallLabel}>{title}</span>}
          {!isChild && <span className={styles.label}>{title}</span>}
        </div>
      ),
    };
    return props;
  };

  useEffect(() => {
    // find a *much* better way to set the ActiveIcon.
    // this is not dynamic and will cause issues later on.
    if (pathname.includes('home')) {
      setActiveIcon('home');
    } else if (pathname.includes('report')) {
      setActiveIcon('chart');
    } else if (pathname.includes('setting')) {
      setActiveIcon('settings');
    }
  }, [pathname, setActiveIcon]);

  return (
    <nav className={classNames(styles.menu, { [styles.collapsed]: collapsed })}>
      {menuConfig.map(({ tooltipText, title, to, type, icon, divider, onClick, key = '', children }, index) => {
        if (children) {
          // if (children.length == 1) {
          //   // only one child, so show no list/dropdown and show child as it's own menu item (use parent's icon?)
          // }
          return (
            <SidebarMenuListButton title={title} icon={icon} key={key || `${type}-${index}`}>
              {children.map(({ tooltipText, title, to, type, icon, onClick }, index) => {
                const props = getNavLinkProps({ onClick, title, icon }, true);
                return (
                  <React.Fragment key={`${type}-${index}`}>
                    <Tooltip arrow={true} placement={'right'} disableInteractive={true} title={tooltipText || ''}>
                      {to ? (
                        <NavLink
                          {...props}
                          className={classNames(styles.link, styles.listLink)}
                          to={to}
                          activeClassName={styles.active}
                        />
                      ) : (
                        <Link {...props} className={classNames(styles.link, styles.listLink)} />
                      )}
                    </Tooltip>
                  </React.Fragment>
                );
              })}
              {/* <Typography variant={"body2"}>{title}</Typography>
            </NavLink> */}
            </SidebarMenuListButton>
          );
        } else {
          const props = getNavLinkProps({ onClick, title, icon }, false);
          return (
            <React.Fragment key={key || `${type}-${index}`}>
              {divider && <div className={styles.divider}></div>}
              <Tooltip arrow placement={'right'} title={tooltipText || ''}>
                {to ? <NavLink {...props} to={to} activeClassName={styles.active} /> : <Link {...props} />}
              </Tooltip>
            </React.Fragment>
          );
        }
      })}
    </nav>
  );
};

export default SidebarMenu;
