import { FieldErrors, UseFormProps, ValidationRule } from 'react-hook-form';
import React from 'react';
import { GridProps } from '@mui/material';
import Address from 'store/types/Address';
import { FieldError, ValidateResult } from 'react-hook-form/dist/types';

export interface InputProps {
  variant: 'outlined';
  fullWidth: boolean;
}

export interface FormProps {
  inputProps?: InputProps;
  rowProps?: GridProps;
  fieldName?: string;
  disabled?: boolean;
  required?: boolean;
}

export interface ValidationProps {
  error: boolean;
  helperText?: string | React.ReactElement;
}

export const defaultFormProps: UseFormProps<any> = {
  reValidateMode: 'onChange',
  mode: 'onChange',
  criteriaMode: 'all',
};

export const countryFieldName: keyof Address = 'countryCode';

export const EMAIL_MAX_LENGTH = 254;
export const EMAIL_REGEXP = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;
export const DEFAULT_POSTAL_CODE_REGEXP = /^[a-zA-Z0-9]{1,20}$/;
export const NCCER_NUMBER_REGEXP = /^[0-9]+$/;
export const NCCER_NUMBER_LIST_REGEXP = /^[0-9,\s]+$/;

export const LOGIN_USERNAME_FIELD_ID = 'login-username-field';
export const LOGIN_SECTION_ID = 'login-section';

export const getRequiredInputMessage = (label: string): string => `Please input ${label}`;

export const getRequiredSelectMessage = (label: string): string => `Please select ${label}`;

export const getLimitExceedInputMessage = (maxLength: number): string =>
  `Input cannot be longer than ${maxLength} character(s)`;

export const getMaxLengthValidationRule = (maxLength: number): ValidationRule<number> => ({
  value: maxLength,
  message: getLimitExceedInputMessage(maxLength),
});

export const validateEmailValue = (
  value: string,
  disabled = false,
  optional = false
): ValidateResult | Promise<ValidateResult> => {
  if (disabled) {
    return true;
  }
  if (value) {
    if (value.length > EMAIL_MAX_LENGTH) {
      return getLimitExceedInputMessage(EMAIL_MAX_LENGTH);
    } else if (!EMAIL_REGEXP.test(value)) {
      return 'Please input valid email';
    }
    return true;
  } else {
    return optional || getRequiredInputMessage('email');
  }
};

export const getRequiredValidationRule = (
  label: string,
  isSelect = false,
  ruleRequired = true
): ValidationRule<boolean> => ({
  value: ruleRequired,
  message: isSelect ? getRequiredSelectMessage(label) : getRequiredInputMessage(label),
});

export const getValidationProps = (label: string, errors?: FieldErrors): ValidationProps =>
  !!errors && errors.hasOwnProperty(label) && errors[label] !== undefined
    ? {
        error: true,
        helperText: (errors[label] as any).message || undefined,
      }
    : {
        error: false,
        helperText: undefined,
      };

export const getValidationPropsFromError = (error?: FieldError): ValidationProps =>
  error
    ? {
        error: true,
        helperText: error?.message,
      }
    : {
        error: false,
        helperText: undefined,
      };

export const isCanadaSelected = (countryCode: string): boolean => countryCode === 'CA';
export const isUSSelected = (countryCode: string): boolean => countryCode === 'US';

export const getAddressControlName = (addressFieldName: string, label: string): string =>
  `${addressFieldName}.${label}`;

export const getAddressFormValues = (address?: Partial<Address>): Address =>
  address
    ? {
        addressee: address.addressee || '',
        city: address.city || '',
        stateRegion: address.stateRegion || '',
        postalCode: address.postalCode || '',
        country: address.country || '',
        countryCode: address.countryCode || '',
        street1: address.street1 || '',
        street2: address.street2 || '',
        label: address.label || '',
      }
    : {
        city: '',
        stateRegion: '',
        postalCode: '',
        country: '',
        countryCode: '',
        street1: '',
        street2: '',
        label: '',
        addressee: '',
      };

export const willAddressShip = (address?: Address): boolean => {
  return Boolean(address && address.street1 && address.city && address.stateRegion && address.country);
};

export const isAddressInternational = (address?: Address): boolean => {
  // Non-international countries/territories: US, Guam, Puerto Rico, Virgin Islands
  const nonInternationalCountryCodes = ['US', 'GU', 'PR', 'VI'];
  const countryCode = address?.countryCode;
  return Boolean(countryCode && !nonInternationalCountryCodes.includes(countryCode));
};

export const getUnsavedChangesPromptMessage = (formName: string, editMode?: boolean): string =>
  editMode
    ? `Are you sure you want to cancel the changes made for the ${formName}?`
    : `Are you sure you want to cancel the ${formName} creation?`;
