enum SiteModule {
  Home = 'home',
  Profile = 'profile',
  Settings = 'settings',
  Announcements = 'announcements',
  Notice = 'notice',
  Credentials = 'credentials',
  VerifyCredentials = 'verify-credentials',
  PrintCredentials = 'print-credentials',
  Reports = 'reports',
}
export default SiteModule;
