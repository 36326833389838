export function isDefined<T>(obj: T | null | undefined): obj is T {
  return obj !== null && obj !== undefined;
}

export function isRejected<T>(input: PromiseSettledResult<T>): input is PromiseRejectedResult {
  return input.status === 'rejected';
}

export function isFulfilled<T>(input: PromiseSettledResult<T>): input is PromiseFulfilledResult<T> {
  return input.status === 'fulfilled';
}
