import { TextField, TextFieldProps } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment, { Moment } from 'moment-timezone';
import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { getRequiredValidationRule, getValidationProps } from 'util/Form';
import ComponentProps from 'store/types/ComponentProps';

import styles from './DateFormItem.module.scss';

interface DateFormItemProps extends Pick<TextFieldProps, 'size' | 'disabled'>, ComponentProps {
  fieldName: string;
  label?: string;
  required?: boolean;
  maxDateErrorMessage?: string;
  minDateErrorMessage?: string;
  minDate?: Moment;
  maxDate?: Moment;
}

const DateFormItem: React.FunctionComponent<DateFormItemProps> = ({
  fieldName,
  label = '',
  minDate,
  maxDate,
  required = true,
  size,
  disabled,
  maxDateErrorMessage,
  minDateErrorMessage,
  className,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const validateField = useCallback(
    (inputValue?: Date) => {
      if (inputValue) {
        if (!moment(inputValue).isValid()) {
          return 'Please input valid date';
        } else if (minDate && moment(inputValue).isBefore(moment(minDate))) {
          return minDateErrorMessage || 'Date should not be before minimal date';
        } else if (maxDate && moment(inputValue).isAfter(moment(maxDate))) {
          return maxDateErrorMessage || 'Date should not be after maximal date';
        }
      }
    },
    [minDate, maxDate, maxDateErrorMessage, minDateErrorMessage]
  );
  return (
    <Controller
      render={({ field: { onChange, value } }) => (
        <DatePicker
          views={['month', 'year', 'day']}
          label={label}
          disabled={disabled}
          minDate={minDate}
          maxDate={maxDate}
          value={value || null}
          onChange={onChange}
          OpenPickerButtonProps={{ className: styles.keyboardButton }}
          renderInput={(props) => (
            <TextField
              {...props}
              size={size}
              fullWidth={true}
              className={className}
              {...getValidationProps(fieldName, errors)}
              data-testid={'date-text-field'}
              inputProps={{
                ...props.inputProps,
                'data-testid': 'date-input',
              }}
              FormHelperTextProps={
                {
                  'data-testid': 'date-helper-text',
                } as any
              }
            />
          )}
        />
      )}
      name={fieldName}
      control={control}
      rules={{ required: getRequiredValidationRule(label.toLowerCase(), false, required), validate: validateField }}
    />
  );
};
export default DateFormItem;
