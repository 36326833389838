import { Grid, TextField } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormProps, getRequiredValidationRule, getValidationProps } from 'util/Form';
import { defaultGridItemProps } from 'util/Layout';
import { SetPasswordFormValues } from 'store/types/FormValues';
import PasswordHintsList from './PasswordHintsList';

interface SetPasswordFormSectionProps extends FormProps {
  onValidChange: (valid: boolean) => void;
  labelPrefix?: string;
}

const FIELD_NAME = 'newPassword';
const CONFIRM_FIELD_NAME = 'confirmNewPassword';

const SetPasswordFormSection: React.FunctionComponent<SetPasswordFormSectionProps> = ({
  rowProps = defaultGridItemProps,
  onValidChange,
  labelPrefix = '',
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<SetPasswordFormValues>();
  const label = `${`${labelPrefix} ` || ''}Password`;

  return (
    <>
      <Grid {...rowProps}>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              {...getValidationProps(FIELD_NAME, errors)}
              label={label}
              type={'password'}
              required={true}
              autoComplete={'new-password'}
              inputProps={{ 'data-testid': `${FIELD_NAME}-input` }}
              data-testid={`${FIELD_NAME}-text-field`}
              FormHelperTextProps={{ 'data-testid': `${FIELD_NAME}-helper-text` } as any}
            />
          )}
          name={FIELD_NAME}
          control={control}
          rules={{
            required: getRequiredValidationRule('new password'),
          }}
        />
      </Grid>
      <Grid {...rowProps}>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              {...getValidationProps(CONFIRM_FIELD_NAME, errors)}
              label={`Confirm ${label}`}
              type={'password'}
              required={true}
              autoComplete={'new-password'}
              inputProps={{ 'data-testid': `${CONFIRM_FIELD_NAME}-input` }}
              data-testid={`${CONFIRM_FIELD_NAME}-text-field`}
              FormHelperTextProps={{ 'data-testid': `${CONFIRM_FIELD_NAME}-helper-text` } as any}
            />
          )}
          name={CONFIRM_FIELD_NAME}
          control={control}
          rules={{
            required: getRequiredValidationRule('new password'),
          }}
        />
      </Grid>
      <Grid {...rowProps} data-testid={'hints-list-wrapper'}>
        <PasswordHintsList
          fieldName={FIELD_NAME}
          confirmFieldName={CONFIRM_FIELD_NAME}
          onValidChange={onValidChange}
          label={label}
        />
      </Grid>
    </>
  );
};
export default SetPasswordFormSection;
