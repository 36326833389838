import React, { useCallback, useState } from 'react';
import Header from 'components/layout/Header';
import {
  Box,
  Button,
  Grid,
  TableContainer,
  Typography,
  Table as MuiTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

import commonStyles from 'styles/common.module.scss';
import styles from './BadgeDetails.module.scss';
import { useLocation } from 'react-router-dom';
import CredentialService from 'services/api/CredentialService';
import Spinner from 'components/shared/Spinner';
import { useSnackbar } from 'notistack';
import { useWindowSize } from 'util/Window';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import sampleBadge from 'assets/images/craftPro/craftProSampleBadge.svg';
import { Add, Remove } from '@mui/icons-material';

const BadgeDetails: React.FunctionComponent = () => {
  const { isMobile } = useWindowSize();
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const badgeName = searchParams.get('name');
  const docLink = searchParams.get('docLink');
  const [expanded, setExpanded] = React.useState<string | false>('');

  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handlePrintClick = useCallback(() => {
    if (docLink) {
      setLoading(true);
      CredentialService.getDocumentLink(docLink)
        .then((response) => {
          setLoading(false);
          window.location.assign(response);
        })
        .catch((error) => {
          setLoading(false);
          enqueueSnackbar(error, { variant: 'error' });
        });
    }
  }, [docLink, enqueueSnackbar]);

  // get badge info from CE

  interface CredentialCourse {
    title: string;
    craft: string;
    description: string;
  }
  const courseList: CredentialCourse[] = [
    {
      title: 'Wall Systems',
      craft: 'Carpentry',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    },
    {
      title: 'Safety 101',
      craft: 'Core',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    },
    {
      title: 'Floor Systems',
      craft: 'Carpentry',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    },
    {
      title: 'Wiring',
      craft: 'Electrical',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    },
    {
      title: 'Building Envelopes',
      craft: 'Carpentry',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    },
  ];
  const categoryText = `Courses (${courseList.length})`;

  return (
    <Spinner loading={loading}>
      {!isMobile && <Header />}
      <div className={commonStyles.pageContent}>
        <Box className={commonStyles.contentCard} sx={{ mb: 3 }}>
          <Grid {...defaultGridContainerProps}>
            <Grid {...defaultGridItemProps} xl={2} lg={3} md={4}>
              {isMobile ? (
                <div className={styles.mobileBadgeWrapper}>
                  <img src={sampleBadge} className={styles.badgeImage} />
                </div>
              ) : (
                <img src={sampleBadge} className={styles.badgeImage} />
              )}
            </Grid>
            <Grid {...defaultGridItemProps} xl={10} lg={9} md={8}>
              <Typography variant={'body2'} className={styles.infoCategoryHeader}>
                Credential Name
                {docLink && !isMobile && (
                  // <Grid {...defaultGridItemProps} md={2} alignItems={"center"}>
                  <Button
                    color={'primary'}
                    variant={'contained'}
                    className={styles.printBtn}
                    onClick={handlePrintClick}
                  >
                    {'Print Credential'}
                  </Button>
                  // </Grid>
                )}
              </Typography>
              <Typography variant={'h6'}>{badgeName}</Typography>
              <br />
              <Typography variant={'body2'} className={styles.infoCategoryHeader}>
                Description
              </Typography>
              <Typography variant={'body1'}>
                {
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
                }
              </Typography>
              {docLink && isMobile && (
                // <Grid {...defaultGridItemProps} md={2} alignItems={"center"}>
                <>
                  <br />
                  <Button
                    color={'primary'}
                    variant={'contained'}
                    className={styles.printBtn}
                    onClick={handlePrintClick}
                  >
                    {'Print Credential'}
                  </Button>
                </>
                // </Grid>
              )}
            </Grid>
          </Grid>
        </Box>

        {/* COURSES TABLE */}
        {!isMobile && (
          <TableContainer className={styles.coursesTable}>
            <MuiTable size={'medium'}>
              <TableHead>
                <TableRow className={styles.titleWrapper}>
                  <TableCell className={styles.title}>
                    <Typography variant={'h6'}>Courses ({courseList.length})</Typography>
                  </TableCell>
                </TableRow>
                <TableRow className={styles.columnHeader}>
                  <TableCell className={styles.headerCellWrapper}>
                    <Typography variant={'body2'} className={styles.headerText}>
                      Title
                    </Typography>
                  </TableCell>
                  <TableCell className={styles.headerCellWrapper}>
                    <Typography variant={'body2'} className={styles.headerText}>
                      Craft
                    </Typography>
                  </TableCell>
                  <TableCell className={styles.headerCellWrapper}>
                    <Typography variant={'body2'} className={styles.headerText}>
                      Overview
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {courseList.map((item, index) => (
                  <TableRow className={styles.dataRow} key={index}>
                    <TableCell className={styles.columnOne}>
                      <Typography variant={'body2'} className={styles.text}>
                        {item.title}
                      </Typography>
                    </TableCell>
                    <TableCell className={styles.columnTwo}>
                      <Typography variant={'body2'}>{item.craft}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant={'body2'}>{item.description}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </MuiTable>
          </TableContainer>
        )}

        {/* MOBILE COURSES TABLE */}
        {isMobile && (
          <TableContainer className={styles.mobileCoursesTable}>
            <MuiTable size={'small'}>
              <TableHead>
                <TableRow className={styles.titleWrapper}>
                  <TableCell className={styles.title}>
                    <Typography variant={'h6'} className={styles.mobileTitle}>
                      {categoryText}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className={styles.headerWrapper}>
                  <TableCell className={styles.header}>
                    <Typography variant={'body2'} className={styles.headerText}>
                      Title
                    </Typography>
                  </TableCell>
                </TableRow>

                {courseList.map((item, index) => (
                  <TableRow className={styles.mobileDataRow} key={index}>
                    <Accordion
                      expanded={expanded === 'accordion' + index}
                      onChange={handleAccordionChange('accordion' + index)}
                      className={styles.accordionBox}
                    >
                      <AccordionSummary
                        expandIcon={expanded === 'accordion' + index ? <Remove /> : <Add />}
                        className={
                          expanded === 'accordion' + index
                            ? styles.expandedAccordionHeader
                            : styles.collapsedAccordionHeader
                        }
                      >
                        <Typography variant={'body2'} className={styles.accordionFaceplate}>
                          {item.title}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className={styles.accordionContent}>
                        <Typography variant={'body2'} className={styles.accordionCategory}>
                          Craft
                        </Typography>
                        <Typography variant={'body2'} className={styles.accordionCategoryValue}>
                          {item.craft}
                        </Typography>
                        <Typography variant={'body2'} className={styles.accordionCategory}>
                          Overview
                        </Typography>
                        <Typography variant={'body2'} className={styles.accordionCategoryValue}>
                          {item.description}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </TableRow>
                ))}
              </TableBody>
            </MuiTable>
          </TableContainer>
        )}
      </div>
    </Spinner>
  );
};
export default BadgeDetails;
