import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Card, CardContent, CardHeader, Grid, IconButton } from '@mui/material';
import classNames from 'classnames';

import styles from './HomeAnnouncementsCard.module.scss';
import { Close } from '@mui/icons-material';
import { UserContext } from 'components/UserGuard';
import { ANNOUNCEMENTS_COLLAPSED_LS_LABEL, getExpDate } from 'util/LocalStorage';
import { useWindowSize } from 'util/Window';
import { defaultGridItemProps } from 'util/Layout';

const HomeAnnouncementsCard: React.FunctionComponent = () => {
  const { isMobile } = useWindowSize();
  const { nccerCardNumber } = useContext(UserContext);
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const LocalStorageLabel = ANNOUNCEMENTS_COLLAPSED_LS_LABEL + nccerCardNumber;

  const setCollapsedStoredValue = useCallback(
    (value: boolean) => {
      const storedValue = {
        value: value,
        expiry: getExpDate(5),
      };
      localStorage.setItem(LocalStorageLabel, JSON.stringify(storedValue));
      setCollapsed(value);
    },
    [LocalStorageLabel]
  );

  const onClose = () => {
    setCollapsedStoredValue(true);
  };

  useEffect(() => {
    const storedValueStr = localStorage.getItem(LocalStorageLabel);
    if (storedValueStr) {
      const storedValue = JSON.parse(storedValueStr);
      const now = new Date();

      if (storedValue.expiry > now.getTime() && storedValue.value) {
        setCollapsed(true);
      }
    }
  }, [LocalStorageLabel]);

  return (
    <>
      {!collapsed && (
        <Grid {...defaultGridItemProps}>
          <Card className={classNames(styles.card)}>
            <Box className={styles.cardContainer}>
              <div className={styles.highlight}></div>
              <div className={styles.content}>
                <CardHeader
                  disableTypography={true}
                  className={classNames(styles.cardHeader)}
                  // action={
                  //   <IconButton
                  //     className={styles.cardHeaderAction}
                  //     onClick={onClose}
                  //     color={'primary'}
                  //     data-testid={'modal-close'}
                  //   >
                  //     <Close />
                  //   </IconButton>
                  // }
                  // title={
                  //   <Typography variant={'body1'} className={styles.cardTitle}>
                  //     Click here to view system announcements
                  //   </Typography>
                  // }
                />
                <CardContent className={styles.cardContent}>
                  {/* <Typography>
                  <a href="https://www.nccer.org/motd/">https://www.nccer.org/motd/</a>
                </Typography> */}
                  {isMobile ? (
                    <iframe
                      src="https://www.nccer.org/motd/"
                      width={'100%'}
                      frameBorder={0}
                      height={120}
                      scrolling={'no'}
                    />
                  ) : (
                    <iframe
                      src="https://www.nccer.org/motd/"
                      width={'100%'}
                      frameBorder={0}
                      height={80}
                      scrolling={'no'}
                    />
                  )}
                </CardContent>
              </div>
              <div>
                <IconButton
                  className={styles.cardHeaderAction}
                  onClick={onClose}
                  color={'primary'}
                  data-testid={'modal-close'}
                >
                  <Close />
                </IconButton>
              </div>
            </Box>
          </Card>
        </Grid>
      )}
    </>
  );
};

export default HomeAnnouncementsCard;
