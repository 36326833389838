import classNames from 'classnames';
import React, { useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import Close from '@mui/icons-material/Close';
import Check from '@mui/icons-material/Check';
import HintConfigItem from 'store/types/HintConfigItem';

import styles from './PasswordHintsList.module.scss';

interface PasswordHintsListProps {
  fieldName: string;
  confirmFieldName: string;
  onValidChange: (valid: boolean) => void;
  label?: string;
}

// eslint-disable-next-line no-useless-escape
const specialCharPattern = /[!@#$%^&*(),._?"`'~:;{}\[\]|<>/\\]/;
export const hintsConfig: HintConfigItem[] = [
  {
    text: 'Be minimum 8 characters',
    checked: (password = ''): boolean => password.length >= 8,
  },
  {
    text: 'Have at least 1 number',
    checked: (password = ''): boolean => /\d/.test(password),
  },
  {
    text: 'Contain at least 1 capital letter',
    checked: (password = ''): boolean => password.toLowerCase() !== password,
  },
  {
    text: 'Contain at least 1 lower case letter',
    checked: (password = ''): boolean => password.toUpperCase() !== password,
  },
  {
    text: 'Contain at least 1 special character (e.g. ^$*.[]{}()?"!@#%&/\\,><\':;|_~`)',
    checked: (password = ''): boolean => specialCharPattern.test(password),
  },
  {
    text: 'Passwords must match',
    checked: (password = '', confirmPassword = ''): boolean =>
      !!password && !!confirmPassword && password === confirmPassword,
  },
];

const PasswordHintsList: React.FunctionComponent<PasswordHintsListProps> = ({
  fieldName,
  confirmFieldName,
  onValidChange,
  label = '',
}) => {
  const { control } = useFormContext();
  const [value, confirmValue] = useWatch({ control, name: [fieldName, confirmFieldName] });
  const hintsValid = useMemo(
    () => hintsConfig.every(({ checked }) => checked(value, confirmValue)),
    [value, confirmValue]
  );

  useEffect(() => {
    onValidChange(hintsValid);
  }, [hintsValid, onValidChange]);

  return (
    <div className={styles.hints}>
      <span data-testid={'hints-title'}>{`Your ${label.toLowerCase() || 'value'} must:`}</span>
      <ul data-testid={'hints-list'}>
        {hintsConfig.map(({ text, checked }, index) => {
          const key = `hint-${index}`;

          return (
            <li key={key} data-testid={key}>
              {text}
              {checked(value, confirmValue) ? (
                <Check className={classNames(styles.hintIcon, styles.checked)} data-testid={`${key}-check`} />
              ) : (
                <Close className={classNames(styles.hintIcon, styles.unchecked)} data-testid={`${key}-close`} />
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default PasswordHintsList;
