import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Alert, Checkbox, Grid, Stack, Typography } from '@mui/material';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';
import { UserFormValues } from 'store/types/FormValues';
import { defaultFormProps } from 'util/Form';
import { emptyUserFormValues } from 'util/User';
import { UserContext } from 'components/UserGuard';
import UserService from 'services/api/UserService';
import { alternateIds } from 'store/configs/AlternateIDs';
import Roles from 'store/enums/Roles';
import { useWindowSize } from 'util/Window';

import styles from './ProfileForm.module.scss';

interface ProfileFormProps {
  setLoading: (flag: boolean) => void;
}

const ProfileForm: React.FunctionComponent<ProfileFormProps> = ({ setLoading }) => {
  const { isMobile } = useWindowSize();
  const { enqueueSnackbar } = useSnackbar();
  const userData = useContext(UserContext);
  const form = useForm<UserFormValues>({ ...defaultFormProps, defaultValues: { ...emptyUserFormValues, ...userData } });

  // Force re-render when user accepts the CLMA release.
  form.watch('releaseCLMA');

  const [releaseClmaNotice, setReleaseClmaNotice] = useState('');

  const isInstructor = useMemo(
    () => userData.roles?.some((role) => role.roleIsActive && role.role === Roles.Instructor),
    [userData.roles]
  );

  useEffect(() => {
    form.setValue('releaseCLMA', userData.releaseCLMA);
  }, [form, userData.releaseCLMA]);

  const getAltIdType = (altIdCode: string | null) => {
    if (altIdCode == null) return null;
    return alternateIds.find((i) => i.code === altIdCode);
  };

  const handleChangeReleaseClma = () => {
    if (form.getValues('releaseCLMA') === true) {
      // Display notice if user is already accepted.
      setReleaseClmaNotice("To opt-out please contact NCCER's customer support team.");
    } else {
      // Set CLMA release acceptance.
      form.setValue('releaseCLMA', true, { shouldDirty: true, shouldTouch: true });
      saveUserProfile();
    }
  };

  const saveUserProfile = useCallback(async () => {
    setLoading(true);
    const userToSave = {
      id: userData.id,
      ...form.getValues(),
    };

    try {
      await UserService.updateCurrentUser(userToSave);
      userData.refetchUser();
      setLoading(false);
      enqueueSnackbar('Updates saved successfully.');
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [enqueueSnackbar, form, setLoading, userData]);

  return (
    <FormProvider {...form}>
      <div className={styles.profileViewContainer}>
        {/* NCCER Number */}
        <Grid {...defaultGridContainerProps} className={styles.row}>
          <Grid {...defaultGridItemProps} md={5}>
            <Typography variant={'h6'} className={styles.rowLabel}>
              NCCER Number
            </Typography>
          </Grid>
          <Grid {...defaultGridItemProps} md={7}>
            {userData.nccerCardNumber}
          </Grid>
        </Grid>
        {!isMobile && <div className={styles.divider}></div>}
        {/* Title */}
        <Grid {...defaultGridContainerProps} className={styles.row}>
          <Grid {...defaultGridItemProps} md={5}>
            <Typography variant={'h6'} className={styles.rowLabel}>
              Title
            </Typography>
          </Grid>
          <Grid {...defaultGridItemProps} md={7}>
            {userData.title}
          </Grid>
        </Grid>
        {!isMobile && <div className={styles.divider}></div>}
        {/* Contact Information Section */}
        <Grid {...defaultGridContainerProps} className={styles.row}>
          <Grid {...defaultGridItemProps} md={5}>
            <Typography variant={'h6'} className={styles.rowLabel}>
              Contact Information
            </Typography>
          </Grid>
          <Grid {...defaultGridItemProps} {...defaultGridContainerProps} md={7}>
            <Grid {...defaultGridItemProps} md={6}>
              <Typography variant={'h5'} className={styles.fieldName}>
                First Name
              </Typography>
              <Typography className={styles.fieldValue}>{userData.firstName}</Typography>
            </Grid>
            <Grid {...defaultGridItemProps} md={6}>
              <Typography variant={'h5'} className={styles.fieldName}>
                Last Name
              </Typography>
              <Typography paragraph={true} className={styles.fieldValue}>
                {userData.lastName}
              </Typography>
            </Grid>
            <Grid {...defaultGridItemProps} md={6}>
              <Typography variant={'h5'} className={styles.fieldName}>
                Email Address
              </Typography>
              <Typography paragraph={true} className={styles.fieldValue}>
                {userData.email}
              </Typography>
            </Grid>
            <Grid {...defaultGridItemProps} md={6}>
              <Typography variant={'h5'} className={styles.fieldName}>
                Phone Number
              </Typography>
              <Typography paragraph={true} className={styles.fieldValue}>
                {userData.phone}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {!isMobile && <div className={styles.divider}></div>}
        {/* Personal Information */}
        <Grid {...defaultGridContainerProps} className={styles.row}>
          <Grid {...defaultGridItemProps} md={5}>
            <Typography variant={'h6'} className={styles.rowLabel}>
              Personal Details
            </Typography>
          </Grid>
          <Grid {...defaultGridItemProps} {...defaultGridContainerProps} md={7}>
            <Grid {...defaultGridItemProps}>
              <Typography variant={'h5'} className={styles.fieldName}>
                Date of Birth
              </Typography>
              <Typography paragraph={true} className={styles.fieldValue}>
                {userData.birthDate?.toDateString()}
              </Typography>
            </Grid>
            <Grid {...defaultGridItemProps} md={4}>
              <Typography variant={'h5'} className={styles.fieldName}>
                Race
              </Typography>
              <Typography paragraph={true} className={styles.fieldValue}>
                {userData.race}
              </Typography>
            </Grid>
            <Grid {...defaultGridItemProps} md={4}>
              <Typography variant={'h5'} className={styles.fieldName}>
                Ethnicity
              </Typography>
              <Typography paragraph={true} className={styles.fieldValue}>
                {userData.ethnicity}
              </Typography>
            </Grid>
            <Grid {...defaultGridItemProps} md={4}>
              <Typography variant={'h5'} className={styles.fieldName}>
                Gender
              </Typography>
              <Typography paragraph={true} className={styles.fieldValue}>
                {userData.gender === 'Undisclosed' ? 'Prefer not to say' : userData.gender}
              </Typography>
            </Grid>
            <Grid {...defaultGridItemProps}>
              <Typography variant={'h5'} className={styles.fieldName}>
                Primary Language
              </Typography>
              <Typography paragraph={true} className={styles.fieldValue}>
                {userData.nativeLanguage}
              </Typography>
            </Grid>
            {/* bottom of section */}
            {!userData.applyFerpaAgeRestriction && (
              <Grid {...defaultGridItemProps} md={4}>
                <Typography variant={'h5'} className={styles.fieldName}>
                  Current Address
                </Typography>
                <Typography paragraph={true} className={styles.fieldValue}>
                  {userData.address?.street1}
                </Typography>
                <Typography paragraph={true} className={styles.fieldValue}>
                  {userData.address?.city} {userData.address?.stateRegion} {userData.address?.postalCode}
                </Typography>
                <Typography paragraph={true} className={styles.fieldValue}>
                  {userData.address?.country}
                </Typography>
              </Grid>
            )}
            <Grid {...defaultGridItemProps} md={4}>
              <Typography variant={'h5'} className={styles.fieldName}>
                Birth Place
              </Typography>
              <Typography paragraph={true} className={styles.fieldValue}>
                {userData.birthCity}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {!isMobile && <div className={styles.divider}></div>}
        {/* Construction Labor Market Analyzer (CLMA) Release */}
        {isInstructor && (
          <>
            <Stack spacing={2}>
              <Typography variant={'h6'} className={styles.rowLabel}>
                Construction Labor Market Analyzer (CLMA) Release
                <Typography variant={'caption'} pl={1} fontStyle={'italic'}>
                  (For secondary, post secondary, technical, and career college instructors.)
                </Typography>
              </Typography>
              {releaseClmaNotice && <Alert severity={'info'}>{releaseClmaNotice}</Alert>}
              <Stack direction={'row'} spacing={1} alignItems={'start'}>
                <Checkbox checked={form.getValues('releaseCLMA')} onChange={handleChangeReleaseClma} />
                <Stack spacing={1}>
                  <Typography>
                    I hereby authorize NCCER to share the personal identifiable information (PII) listed in the contact
                    information section of my profile with Construction Industry Resources, LLC (CIR) for use in the
                    Construction Labor Market Analyzer (CLMA) platform. The CLMA is a powerful, predictive analytics
                    platform that helps owners, contractors, and labor providers confidently understand the construction
                    labor market. Through data analysis, the CLMA can foresee labor shortages in specific sectors of the
                    construction industry.
                  </Typography>
                  <Typography>
                    By sharing my information with CIR for use in the CLMA platform, construction stakeholders
                    (contractors, owners, etc.) will be able to contact me to offer great career opportunities to my
                    students to help bridge that gap.
                  </Typography>
                  <Typography>
                    To opt-out after checking the box, please contact NCCER's customer support team.
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            {!isMobile && <div className={styles.divider}></div>}
          </>
        )}
        {/* Alternate ID */}
        <Grid {...defaultGridContainerProps} className={styles.row}>
          <Grid {...defaultGridItemProps} md={5}>
            <Typography variant={'h6'} className={styles.rowLabel}>
              Alternate ID
            </Typography>
          </Grid>
          <Grid {...defaultGridItemProps} {...defaultGridContainerProps} md={7}>
            <Grid {...defaultGridItemProps} md={6}>
              <Typography variant={'h5'} className={styles.fieldName}>
                Type
              </Typography>
              <Typography paragraph={true} className={styles.fieldValue}>
                {getAltIdType(userData.alternateIdType)?.name}
              </Typography>
            </Grid>
            <Grid {...defaultGridItemProps} md={6}>
              <Typography variant={'h5'} className={styles.fieldName}>
                Alternate ID Number
              </Typography>
              <Typography paragraph={true} className={styles.fieldName}>
                {userData.alternateId}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {!isMobile && <div className={styles.divider}></div>}
        {/* Photo */}
        <Grid {...defaultGridContainerProps} className={styles.row}>
          <Grid {...defaultGridItemProps} md={5}>
            <Typography variant={'h6'} className={styles.rowLabel}>
              Photo
            </Typography>
          </Grid>
          <Grid {...defaultGridItemProps} md={7} className={styles.photoDisplay}>
            {userData.logoUrl && <img className={styles.photo} src={userData.logoUrl} />}
          </Grid>
        </Grid>
      </div>
    </FormProvider>
  );
};

export default ProfileForm;
