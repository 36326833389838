import React, { SyntheticEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Grid, MenuItem, Select, Typography } from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import styles from './GenerateDocumentModal.module.scss';
import { UserContext } from 'components/UserGuard';
import Modal from 'components/shared/Modal';
import { ModalProps } from 'store/types/ComponentProps';
import DateFormItem from 'components/shared/DateFormItem';
import { GenerateReportFormValues } from 'store/types/FormValues';
import { defaultFormProps, getValidationPropsFromError } from 'util/Form';
import moment from 'moment';
import { Program } from 'store/types/User';
import Spinner from 'components/shared/Spinner';

interface GenerateDocumentModalProps extends ModalProps {
  onSubmit: (generateReportValues: GenerateReportFormValues) => void;
  title: string;
  subTitle: string;
  contentTypeId: number;
}

const defaultValues: GenerateReportFormValues = {
  programId: '',
  startDate: null,
  endDate: null,
  format: 'pdf',
  showFailures: false,
};

const GenerateDocumentModal: React.FunctionComponent<GenerateDocumentModalProps> = ({
  title,
  subTitle,
  contentTypeId,
  open,
  onSubmit,
  onClose,
  loading,
}) => {
  const { programs } = useContext(UserContext);

  const getProgramTypeText = (program: Program): string => {
    if (program.programType === 'Training Program') {
      return '(Training)';
    } else if (program.programType === 'Assessment Program') {
      return '(Assessment)';
    } else {
      return '';
    }
  };

  const determineProgramList = (program: Program): boolean => {
    // only allow Registered (no accredited)
    return program.programDesignation == 'Registered';
  };

  const methods = useForm<GenerateReportFormValues>({
    ...defaultFormProps,
    defaultValues,
  });
  const { control, formState, handleSubmit, reset, watch } = methods;

  const startDateValue = watch('startDate');
  const endDateValue = watch('endDate');

  const submitButtonDisabled: boolean = useMemo(
    () => !formState.isValid || !formState.isDirty || loading || !startDateValue || !endDateValue,
    [formState.isValid, formState.isDirty, loading, startDateValue, endDateValue]
  );

  useEffect(() => {
    reset(defaultValues);
  }, [title, contentTypeId, reset]);

  const handleUpdateMethod = useCallback(
    (formValues: GenerateReportFormValues) => {
      onSubmit(formValues);
    },
    [onSubmit]
  );

  const handleFormSubmit = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      handleSubmit((data: GenerateReportFormValues) => handleUpdateMethod(data))();
    },
    [handleSubmit, handleUpdateMethod]
  );

  return (
    <Modal
      maxWidth={'md'}
      loading={loading}
      open={open}
      title={title}
      subTitleText={subTitle}
      headerBodyDivider={true}
      onClose={onClose}
      actions={
        <>
          <Button color={'primary'} variant={'outlined'} onClick={onClose}>
            Cancel
          </Button>
          <Button
            color={'primary'}
            variant={'contained'}
            type={'submit'}
            onClick={handleFormSubmit}
            disabled={submitButtonDisabled}
          >
            {contentTypeId == 0 ? 'Generate File' : 'Generate Report'}
          </Button>
        </>
      }
    >
      <FormProvider {...methods}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant={'h6'}>{contentTypeId == 0 ? 'Program(s)' : 'Location(s)'}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <Select
                  {...field}
                  {...getValidationPropsFromError(error)}
                  inputRef={field.ref}
                  placeholder={'Select a program'}
                  fullWidth={true}
                  displayEmpty={true}
                >
                  <MenuItem value={''} disabled>
                    {'Select a program'}
                  </MenuItem>
                  {programs
                    ?.filter((p) => determineProgramList(p))
                    .sort((a, b) => a.programName.localeCompare(b.programName))
                    .map((value) => (
                      <MenuItem key={value.programId} value={value.programId}>
                        {`${value.programName} ${getProgramTypeText(value)}`}
                      </MenuItem>
                    ))}
                </Select>
              )}
              name={'programId'}
              control={control}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant={'h6'} display="inline">
              {'Date Range '}
            </Typography>
            <Typography variant={'body2'} display="inline">
              {'(select/enter up to 12 month date range)'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <DateFormItem
              disabled={false}
              fieldName={'startDate'}
              minDate={endDateValue ? moment(endDateValue).subtract(12, 'months') : undefined}
              maxDate={endDateValue ? moment(endDateValue) : moment(new Date()).startOf('day')}
              required={false}
              className={styles.inputField}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DateFormItem
              disabled={false}
              fieldName={'endDate'}
              minDate={startDateValue ? moment(startDateValue) : undefined}
              maxDate={startDateValue ? moment(startDateValue).add(12, 'months') : undefined}
              required={false}
              className={styles.inputField}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography paragraph={true} sx={{ marginBottom: '0px' }}>
              {'After selecting '}
              <strong>{'Generate File'}</strong>
              {' go to the '}
              <strong>{'Retrieve File'}</strong>
              {
                " tab to view and print your program's certificates. Depending on the file size, please allow up to 24 hours for your file to generate."
              }
            </Typography>
          </Grid>
        </Grid>
      </FormProvider>
    </Modal>
  );
};
export default GenerateDocumentModal;
