import React from 'react';
import PublicPageLayout from 'components/layout/PublicPageLayout';

interface NotFoundPageProps {
  publicPage?: boolean;
}

const NotFoundPage: React.FunctionComponent<NotFoundPageProps> = ({ publicPage = false }) =>
  publicPage ? (
    <PublicPageLayout>
      <h4 data-testid={'public-page-not-found'}>Page Not Found</h4>
    </PublicPageLayout>
  ) : (
    <div style={{ height: '100%' }} data-testid={'page-not-found'}>
      <h4>Page Not Found</h4>
    </div>
  );
export default NotFoundPage;
