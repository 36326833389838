import Pagination from '@mui/material/Pagination';
import Skeleton from '@mui/material/Skeleton';
import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Table as MuiTable,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  Paper,
  TableContainer,
  Divider,
} from '@mui/material';
import ComponentProps from 'store/types/ComponentProps';
import { SortConfig, SortDirection, TableColumn } from 'store/types/Table';
import { getStringValue } from 'util/Format';

import commonStyles from 'styles/common.module.scss';
import styles from './Table.module.scss';

interface TableRowProps<T> {
  selected: (record: T) => boolean;
}

interface TableProps<T> extends ComponentProps {
  list: T;
  columns: Array<TableColumn<T>>;
  sort?: SortConfig<T>;
  onSortChange?: (sortConfig: SortConfig<T>) => void;
  rowProps?: TableRowProps<T>;
  itemsPerPage?: number;
  showPagination?: boolean;
  loading?: boolean;
  submitLoading?: boolean;
  cellClassName?: string;
  noResultsText?: string;
  category?: string;
}

type TableComponentType<T = any> = React.FunctionComponent<TableProps<T>>;

const getListByPage = (initialList = [], itemsPerPage: number, page = 1) => {
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = page * itemsPerPage;

  return initialList.slice(startIndex, endIndex);
};

const Table: TableComponentType = ({
  list = [],
  columns,
  sort,
  onSortChange,
  className = '',
  cellClassName = '',
  rowProps,
  itemsPerPage = 10,
  showPagination = false,
  loading = false,
  submitLoading = false,
  noResultsText = 'Orders not found',
  category = '',
}) => {
  const [page, setPage] = useState<number>(1);
  const pagesCount = Math.ceil(list.length / itemsPerPage);
  const pageList = showPagination ? getListByPage(list, itemsPerPage, page) : list;

  useEffect(() => {
    setPage(1);
  }, [list]);

  const handleSortChange = useCallback(
    (column: string, direction?: SortDirection) => () => {
      const newDirection = !direction ? 'asc' : direction === 'asc' ? 'desc' : undefined;

      if (onSortChange) {
        onSortChange({ column: newDirection ? column : undefined, direction: newDirection });
      }
    },
    [onSortChange]
  );

  const handlePageChange = useCallback((_: any, newPage: number) => {
    setPage(newPage);
  }, []);

  return loading && !submitLoading && !list.length ? (
    <>
      <Skeleton width={'100%'} variant={'text'} className={styles.skeleton} />
      <Divider />
      <Skeleton width={'100%'} variant={'text'} className={styles.skeleton} />
      <Divider />
      <Skeleton width={'100%'} variant={'text'} className={styles.skeleton} />
      <Divider />
    </>
  ) : (
    <TableContainer component={Paper} className={classNames(commonStyles.card, className)}>
      <MuiTable size={'medium'}>
        <TableHead>
          {category && (
            <TableRow className={styles.category}>
              <TableCell colSpan={6}>
                <h3 className={styles.text}>{category}</h3>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            {columns.map(({ dataIndex = '', label, align, sortable = false, key }) => {
              const hasSort: boolean = sortable && !!sort && sort.column === dataIndex;
              const sortDirection: SortDirection | undefined = hasSort ? sort && sort.direction : undefined;
              const columnName = dataIndex.toString() || key;

              return (
                <TableCell
                  align={align}
                  key={`table-header-cell-${columnName}`}
                  sortDirection={sortDirection || false}
                  className={cellClassName}
                >
                  <TableSortLabel
                    active={hasSort}
                    hideSortIcon={!sortable}
                    direction={sortDirection}
                    onClick={sortable && dataIndex ? handleSortChange(dataIndex.toString(), sortDirection) : undefined}
                  >
                    {label}
                  </TableSortLabel>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {pageList.length ? (
            pageList.map((record: any, index: number) => {
              const tableRowProps = rowProps
                ? {
                    role: 'checkbox',
                    selected: rowProps.selected(record),
                    hover: true,
                  }
                : {};

              return (
                <TableRow
                  classes={{
                    root: styles.row,
                    selected: styles.selected,
                  }}
                  key={`table-row-${index}`}
                  {...tableRowProps}
                >
                  {columns.map(({ render, dataIndex = '', key = '', align }) => (
                    <TableCell
                      align={align}
                      key={`table-cell-${dataIndex ? dataIndex.toString() : key}`}
                      className={cellClassName}
                    >
                      {render ? render(record[dataIndex], record, index) : getStringValue(record[dataIndex])}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })
          ) : (
            <TableRow key={`no-data-row`}>
              <TableCell align={'center'} colSpan={columns.length} className={cellClassName}>
                <div className={styles.noData}>{noResultsText}</div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </MuiTable>
      {showPagination && (
        <div className={styles.paginationContainer}>
          <Pagination
            disabled={!list.length}
            showFirstButton={true}
            showLastButton={true}
            count={pagesCount}
            page={page}
            onChange={handlePageChange}
          />
        </div>
      )}
    </TableContainer>
  );
};
export default Table;
