import User, { NewUser, PublicUser } from 'store/types/User';
import { HttpError, HttpService } from 'services/HttpService';
import { API_URL, tenantConfig } from 'config';
import AuthService from 'services/AuthService';
import JobFunction from 'store/types/JobFunction';
import { httpRequestWrapper } from 'util/Request';
import SiteConfig from 'store/types/SiteConfig';
import Feedback from 'store/types/Feedback';
import { CreateNccerNumberFormValues } from 'store/types/FormValues';

type JobFunctionResponseData = JobFunction[];
type UsersSearchResponseData = PublicUser[];

class UserService {
  public static getCurrentUser(): Promise<User> {
    return new Promise<User>((resolve: (data: User) => void, reject: (error: HttpError) => void) => {
      HttpService.get(`${API_URL}/profiles`, {
        headers: {
          TenantId: tenantConfig.id,
          authorization: AuthService.getBearerToken(),
        },
      })
        .toPromise()
        .then((data: User) => {
          resolve(data);
        })
        .catch((error: HttpError) => {
          reject(error);
        });
    });
  }

  public static updateCurrentPhoto(data: File): Promise<string> {
    const form = new FormData();
    form.append('data', data);
    return new Promise<string>((resolve: (response: string) => void, reject: (error: string) => void) => {
      httpRequestWrapper<string>(
        HttpService.post(`${API_URL}/profiles/logo`, {
          data: form,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static updateCurrentUser(data: NewUser): Promise<User> {
    return new Promise<User>((resolve: (data: User) => void, reject: (error: string) => void) => {
      httpRequestWrapper<User>(
        HttpService.put(`${API_URL}/profiles`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static affirmCredlyAccess(): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.put(`${API_URL}/profiles/affirmCredlyAccess`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getJobFunctionList(): Promise<JobFunctionResponseData> {
    return new Promise<JobFunctionResponseData>(
      (resolve: (data: JobFunctionResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<JobFunctionResponseData>(
          HttpService.get(`${API_URL}/configuration/functions`, {
            headers: {
              TenantId: tenantConfig.id,
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getSiteConfig(): Promise<SiteConfig> {
    return new Promise<SiteConfig>((resolve: (data: SiteConfig) => void, reject: (error: string) => void) => {
      httpRequestWrapper<SiteConfig>(
        HttpService.get(`${API_URL}/configuration/site`, {
          headers: {
            TenantId: tenantConfig.id,
          },
        }),
        resolve,
        reject
      );
    });
  }

  /////////////////
  // Auxillary methods -- should likely be relocated
  /////////////////
  public static submitFeedback(data: Feedback): Promise<string> {
    return new Promise<string>((resolve: (data: string) => void, reject: (error: string) => void) => {
      httpRequestWrapper<string>(
        HttpService.post(`${API_URL}/profiles/feedback`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static submitNccerCardNumberForm(data: CreateNccerNumberFormValues): Promise<string> {
    return new Promise<string>((resolve: (data: string) => void, reject: (error: string) => void) => {
      httpRequestWrapper<string>(
        HttpService.post(`${API_URL}/profiles/createCardNumber`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  /////////////////
  // LEGACY
  /////////////////
  public static updateCurrentUserEmail(email: string): Promise<string> {
    return new Promise<string>((resolve: (newEmail: string) => void, reject: (error: string) => void) => {
      httpRequestWrapper<string>(
        HttpService.put(`${API_URL}/account/email`, {
          data: { email },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static updateCurrentUserPassword(oldPassword: string, newPassword: string): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.put(`${API_URL}/account/password`, {
          data: { oldPassword, newPassword, authToken: AuthService.getAccessToken() },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static searchUser(name: string): Promise<UsersSearchResponseData> {
    return new Promise<UsersSearchResponseData>(
      (resolve: (data: UsersSearchResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<UsersSearchResponseData>(
          HttpService.get(`${API_URL}/profiles/search`, {
            queryParams: { name },
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }
}
export default UserService;
