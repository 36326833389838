import { Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import moment, { Moment } from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import User, { CurrentUser, SetCurrentUserValue, UserView } from 'store/types/User';
import UserService from 'services/api/UserService';
import useRequest from 'hooks/useRequest';
import { emptyUserFormValues } from 'util/User';
import Spinner from 'components/shared/Spinner';
import authClient from 'services/AuthService';
import { HttpError, HttpUtils } from 'services/HttpService';
import routes, { noAuthRoutes } from 'store/configs/Routes';
import RequestErrorCode from 'store/enums/RequestErrorCode';
import { getHashRouteUrl } from 'util/Route';

import commonStyles from 'styles/common.module.scss';

const getBirthDate = (initialDate: Moment | string | null): Date | null =>
  initialDate ? (moment.isMoment(initialDate) ? initialDate : moment(initialDate)).toDate() : null;

export const UserContext = React.createContext<CurrentUser>({} as CurrentUser);

const UNAUTHORIZED_ERROR_CODE = 401;

const UserGuard: React.FunctionComponent = ({ children }) => {
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { data, loading, refetch, firstLoading, error } = useRequest<User, HttpError>(
    pathname.includes(routes.redirect) ? undefined : UserService.getCurrentUser
  );
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [user, setUser] = useState<UserView>({ ...emptyUserFormValues, id: '' });

  useEffect(() => {
    if (error) {
      const newErrorMessage: string = HttpUtils.getErrorMessage(error);
      const errorCode = HttpUtils.getErrorCode(error);

      if (errorCode === RequestErrorCode.ProfileNotFound || errorCode === RequestErrorCode.ProfileNotValid) {
        setErrorMessage(newErrorMessage);
      } else {
        if (error.code !== UNAUTHORIZED_ERROR_CODE) {
          enqueueSnackbar(newErrorMessage, { variant: 'error' });
        }
        let shouldLogout = true;
        noAuthRoutes.forEach((route) => {
          if (pathname.includes(route)) shouldLogout = false;
        });
        if (shouldLogout) {
          window.location.assign(getHashRouteUrl(routes.logout));
        }
      }
    } else {
      setErrorMessage('');
    }
  }, [enqueueSnackbar, error, pathname]);

  useEffect(() => {
    if (data) {
      setUser({
        ...data,
        birthDate: getBirthDate(data.birthDate),
      });
    }
  }, [data]);

  const setCurrentUser = useCallback((newValue: SetCurrentUserValue) => {
    setUser((prevState: UserView) => {
      const newState = { ...prevState, ...newValue };

      return { ...newState, birthDate: getBirthDate(moment(newState.birthDate)) };
    });
  }, []);

  const refetchUser = useCallback(() => {
    refetch();
  }, [refetch]);

  const handleLogoutClick = useCallback(() => {
    authClient.logout();
  }, []);

  return firstLoading ? (
    <Spinner loading={true} fullPage={true} transparent={false} />
  ) : errorMessage ? (
    <Alert
      severity={'error'}
      className={commonStyles.alert}
      action={
        <Button color={'inherit'} size={'small'} className={commonStyles.uppercase} onClick={handleLogoutClick}>
          {'Logout'}
        </Button>
      }
    >
      {errorMessage}
    </Alert>
  ) : (
    <UserContext.Provider value={{ ...user, setCurrentUser, refetchUser, userLoading: loading }}>
      {children}
    </UserContext.Provider>
  );
};
export default UserGuard;
