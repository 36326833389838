import moment, { Moment } from 'moment-timezone';
import User from 'store/types/User';
import React from 'react';
import { Link } from '@mui/material';
import { Completion } from 'store/types/Completions';

export const EMPTY_STRING_VALUE = '-';

export const NUMBER_DATE_FORMAT = 'MM/DD/YYYY';
export const STRING_DATE_FORMAT = 'MMMM DD, YYYY';

export const ISO_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export const getUserFullName = (
  user?: Pick<User, 'firstName' | 'lastName' | 'middleInitial'>,
  includeMiddleInitial?: boolean,
  emptyValue = EMPTY_STRING_VALUE
): string => {
  const firstName = user?.firstName || '';
  const lastName = user?.lastName || '';
  const middleInitial = user?.middleInitial || '';
  if (lastName || firstName) {
    return includeMiddleInitial ? `${firstName} ${middleInitial} ${lastName}` : `${firstName} ${lastName}`;
  } else {
    return emptyValue;
  }
};

export const getFormattedDate = (date: string, format?: string): string =>
  moment(date).format(format || STRING_DATE_FORMAT);

export const getEmailLink = (value: string, className?: string): React.ReactNode => (
  <Link href={`mailto:${value}`} className={className}>
    {value}
  </Link>
);

export const getPhoneLink = (value: string, className?: string): React.ReactNode => (
  <Link href={`tel:${value}`} className={className}>
    {value}
  </Link>
);

export const getISODateString = (date?: Moment | null, startOfDay = true): string | null =>
  date ? (startOfDay ? date.clone().startOf('day').format(ISO_FORMAT) : date.clone().format(ISO_FORMAT)) : null;

export const getStringValue = (value?: string, dateStringFormat: boolean | string = false): string => {
  return value
    ? dateStringFormat
      ? getFormattedDate(value, typeof dateStringFormat === 'string' ? dateStringFormat : undefined)
      : value
    : EMPTY_STRING_VALUE;
};

export function getDocLink(item: Completion) {
  let docLink = '';
  if (item.Document) {
    docLink = '&docLink=' + encodeURIComponent(item.Document.Link);
  }
  return docLink;
}
