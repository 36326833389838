import { useCallback, useContext } from 'react';
import { Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';
import { UserContext } from 'components/UserGuard';
import ReportService from 'services/api/ReportService';
import { defaultFormProps } from 'util/Form';
import { errorMessage } from 'util/Request';
import { ActionButtons, CardNumberInput } from './components';
import { ReportContext } from '../ReportsPage/context';

type Values = typeof defaultValues;
const defaultValues = {
  nccerNumber: '',
};

interface ConstructionForemanReportFormProps {
  selectNccerNumber?: boolean;
}

export function ConstructionForemanReportForm({ selectNccerNumber }: ConstructionForemanReportFormProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { closeModal, setLoading } = useContext(ReportContext);
  const { nccerCardNumber = '' } = useContext(UserContext);
  const form = useForm<Values>({ ...defaultFormProps, defaultValues });
  const { formState, handleSubmit } = form;

  const submitDisabled = selectNccerNumber ? !formState.isValid : false;

  const handleFormSubmit = useCallback(
    async (data: Values) => {
      setLoading(true);
      try {
        const nccerNumber = selectNccerNumber ? data.nccerNumber : nccerCardNumber;
        const file = await ReportService.getConstructionForemanScoreReport(nccerNumber);
        window.location.assign(file);
        enqueueSnackbar('Downloading file', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar(errorMessage(error), { variant: 'error' });
      } finally {
        closeModal();
        setLoading(false);
      }
    },
    [closeModal, enqueueSnackbar, nccerCardNumber, selectNccerNumber, setLoading]
  );

  return (
    <FormProvider {...form}>
      {selectNccerNumber && (
        <Grid container spacing={4} pb={6}>
          <CardNumberInput name="nccerNumber" label="NCCER Number" required />
        </Grid>
      )}
      <ActionButtons
        submitText="Generate File"
        disabled={submitDisabled}
        onClose={closeModal}
        onSubmit={handleSubmit(handleFormSubmit)}
      />
    </FormProvider>
  );
}
