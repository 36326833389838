import React, { useCallback, useContext, useState } from 'react';

export interface SessionTabsContextType {
  openTabs: Window[];
  addOpenTab: (tab: Window | null) => void;
}

export const SessionTabsContext = React.createContext<SessionTabsContextType>({} as SessionTabsContextType);

const WaiverContextProvider: React.FunctionComponent = ({ children }) => {
  const [openTabs, setOpenTabs] = useState<Window[]>([]);

  const addOpenTab = useCallback(
    (tab: Window | null) => {
      if (tab) {
        openTabs.push(tab);
        setOpenTabs(openTabs);
      }
    },
    [openTabs]
  );

  const context = {
    openTabs,
    addOpenTab,
  };

  return <SessionTabsContext.Provider value={context}>{children}</SessionTabsContext.Provider>;
};
export default WaiverContextProvider;

export const useSessionTabsContext = (): SessionTabsContextType => {
  const context = useContext(SessionTabsContext);

  if (context === undefined) {
    throw new Error('SessionTabsContext was used outside of its Provider');
  }

  return context;
};
