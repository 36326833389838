import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Card, CardActions, CardContent, Grid, Link, Typography } from '@mui/material';
import learningPlatform from 'assets/icons/learningPlatform.svg';
import instructorToolBox from 'assets/icons/instructorToolBox.svg';
import journeyLevelAssessments from 'assets/icons/journeyLevelAssessments.svg';
import makePurchases from 'assets/icons/makePurchases.svg';
import payMyBill from 'assets/icons/payMyBill.svg';
import subscriptions from 'assets/icons/subscriptions.svg';
import performanceEvaluation from 'assets/icons/performanceEvaluation.svg';
import practicalExam from 'assets/icons/practicalExam.svg';
import submissions from 'assets/icons/submissions.svg';
import manageMyPrograms from 'assets/icons/manageMyPrograms.svg';
import myApplications from 'assets/icons/myApplications.svg';
import sideCarrot from 'assets/icons/sideCarrot.svg';
import craftPro from 'assets/icons/craftPro.png';
import craftProAdminPortal from 'assets/icons/craftProAdminPortal.png';
import { ConfigContext } from 'components/ConfigGuard';
import Header from 'components/layout/Header/Header';
import { UserContext } from 'components/UserGuard';
import { useSessionTabsContext } from 'components/SessionTabsContext';
import { WalletCardButton } from 'components/shared/WalletCardButton';
import CreateNccerNumberModal from 'components/shared/CreateNccerNumberModal';
import { QuickLinkConfigItem } from 'store/types/SiteConfig';
import Roles from 'store/enums/Roles';
import authClient from 'services/AuthService';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { useWindowSize } from 'util/Window';
import { handleOpenTabClick } from 'util/Route';
import HomeAnnouncementsCard from './HomeAnnouncementsCard';
import { HomeConfirmProfile } from './HomeConfirmProfile';
import styles from './HomePage.module.scss';
import commonStyles from 'styles/common.module.scss';

const HomePage: React.FunctionComponent = () => {
  const { isMobile } = useWindowSize();
  const { addOpenTab } = useSessionTabsContext();
  const { quickLinks } = useContext(ConfigContext);
  const { canClaimWalletCard, craftProJobsiteEvaluatorStatus, id, programs, roles, synchronizeWithNetSuite } =
    useContext(UserContext);
  const [ncnCreationModalOpen, setNcnCreationModalOpen] = useState(false);
  const [isSfIdAdded, setIsSfIdAdded] = useState(false);

  const handleNcnCreationModalClose = useCallback(() => {
    setNcnCreationModalOpen(false);
  }, []);

  const handleLinkClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, link: string, label: string) => {
    if ((link == null || link == undefined || link == '') && label.includes('Create NCCER Number')) {
      setNcnCreationModalOpen(true);
    } else {
      handleOpenTabClick(e, link, addOpenTab);
    }
  };

  const hasPaperProgram = useCallback(() => {
    const index = programs?.findIndex((program) => {
      return program.approvedForPaperTesting;
    });
    if (index !== undefined && index > -1) {
      return true;
    }
    return false;
  }, [programs]);

  const isDoCProgram = useCallback(() => {
    // if any program in list has DoC override, show tile
    let result = false;
    programs?.forEach((program) => {
      if (program.cardNumberCreatorOverride) result = true;
    });
    return result;
  }, [programs]);

  const linkIsVisible = (link: QuickLinkConfigItem, roleName: string) => {
    // if array has no visibility declarations, we show to all.
    return link.visibleTo == null || link.visibleTo.length == 0 || link.visibleTo.includes(roleName);
  };

  // TODO: Make this more efficient
  const quickLinksConfig: QuickLinkConfigItem[] = useMemo(() => {
    let goodExitList: QuickLinkConfigItem[] = [];
    const currentList: string[] = [];
    roles?.forEach((ur) => {
      quickLinks.forEach((link) => {
        if (!currentList.includes(link.label)) {
          if (linkIsVisible(link, ur.role)) {
            // some applications for this already use a qualifier. To make adding the auth token configurable,
            // qualifier must be made a list or a new property added to the link object ("amendment"?).
            if (link.link.endsWith('?id_token=')) {
              link.link += authClient.getIdToken();
            }
            // sensitivity param not supported in really old browsers, but this is itnl safe
            if (link.qualifier.trim().localeCompare('paper', undefined, { sensitivity: 'base' }) === 0) {
              if (hasPaperProgram()) {
                goodExitList.push(link);
                currentList.push(link.label);
              }
            } else if (link.qualifier.trim().localeCompare('doc', undefined, { sensitivity: 'base' }) === 0) {
              if (isDoCProgram()) {
                goodExitList.push(link);
                currentList.push(link.label);
              }
            } else if (link.qualifier.trim().localeCompare('sfid', undefined, { sensitivity: 'base' }) === 0) {
              if (!isSfIdAdded) {
                link.link += id;
                setIsSfIdAdded(true);
              }
              goodExitList.push(link);
              currentList.push(link.label);
            } else if (link.qualifier.trim().localeCompare('cpje', undefined, { sensitivity: 'base' }) === 0) {
              if (
                ur.role.trim().localeCompare(Roles.CraftProJobsiteEvaluator, undefined, { sensitivity: 'base' }) === 0
              ) {
                if (
                  craftProJobsiteEvaluatorStatus.trim().localeCompare('failed', undefined, { sensitivity: 'base' }) !==
                  0
                ) {
                  goodExitList.push(link);
                  currentList.push(link.label);
                }
              } else {
                goodExitList.push(link);
                currentList.push(link.label);
              }
            } else {
              goodExitList.push(link);
              currentList.push(link.label);
            }
          } else if (
            link.qualifier.trim().localeCompare('netsuiteSync', undefined, { sensitivity: 'base' }) === 0 &&
            synchronizeWithNetSuite
          ) {
            // show this tile if sync w/ NS is true, regardless of role assignment
            goodExitList.push(link);
            currentList.push(link.label);
          }
        }
      });
    });

    // sort alphabetically
    goodExitList = goodExitList.sort((a, b) => a.label.localeCompare(b.label));
    return goodExitList;
  }, [
    roles,
    quickLinks,
    synchronizeWithNetSuite,
    hasPaperProgram,
    isDoCProgram,
    isSfIdAdded,
    id,
    craftProJobsiteEvaluatorStatus,
  ]);

  const getIcon = (iconFileName: string) => {
    switch (iconFileName?.trim()) {
      case 'learningPlatform':
        return <img src={learningPlatform} alt={'LearningPlatform'} className={styles.productCardIcon} />;
      case 'journeyLevelAssessments':
        return (
          <img src={journeyLevelAssessments} alt={'Journey-LevelAssessments'} className={styles.productCardIcon} />
        );
      case 'practicalExam':
        return <img src={practicalExam} alt={'PracticalExams'} className={styles.productCardIcon} />;
      case 'payMyBill':
        return <img src={payMyBill} alt={'PayMyBill'} className={styles.productCardIcon} />;
      case 'makePurchases':
        return <img src={makePurchases} alt={'MakePurchases'} className={styles.productCardIcon} />;
      case 'subscriptions':
        return <img src={subscriptions} alt={'BuySubscriptions'} className={styles.productCardIcon} />;
      case 'performanceEvaluation':
        return <img src={performanceEvaluation} alt={'PerformanceEvaluations'} className={styles.productCardIcon} />;
      case 'submissions':
        return <img src={submissions} alt={'Submissions'} className={styles.productCardIcon} />;
      case 'instructorToolBox':
        return <img src={instructorToolBox} alt={'InstructorToolbox'} className={styles.productCardIcon} />;
      case 'manageMyPrograms':
        return <img src={manageMyPrograms} alt={'ManageMyPrograms'} className={styles.productCardIcon} />;
      case 'myApplications':
        return <img src={myApplications} alt={'MyApplications'} className={styles.productCardIcon} />;
      case 'craftPro':
        return <img src={craftPro} alt={'CraftPro'} className={styles.productCardIcon} />;
      case 'craftProAdminPortal':
        return <img src={craftProAdminPortal} alt={'CraftProAdminPortal'} className={styles.productCardIcon} />;
      default:
        return <img src={myApplications} alt={'DefaultIcon'} className={styles.productCardIcon} />;
    }
  };

  return (
    <>
      {!isMobile && <Header />}
      <Grid {...defaultGridContainerProps} className={commonStyles.pageContent}>
        <HomeAnnouncementsCard />
        <Grid {...defaultGridItemProps}>
          <Typography variant={'h2'} className={styles.title}>
            Home
          </Typography>
          <Typography paragraph={true} variant={'body1'} className={styles.subTitle}>
            {'Explore our solutions through the links below.'}
          </Typography>
          {isMobile && canClaimWalletCard && (
            <>
              <WalletCardButton />
              <br />
            </>
          )}
          <Grid {...defaultGridContainerProps}>
            {quickLinksConfig.map(({ label, link, icon, description = '' }, i) => (
              <Grid {...defaultGridItemProps} lg={4} md={6} sm={12} key={`product-card-${i}`}>
                <Link
                  component="button"
                  sx={{ width: '100%', height: '100%' }}
                  onClick={(e) => handleLinkClick(e, link, label)}
                >
                  <Card className={styles.productCard}>
                    <CardContent className={styles.contentBox}>
                      <Grid container>
                        <Grid item xs={1} className={styles.productCardIconWrapper}>
                          {getIcon(icon)}
                        </Grid>
                        <Grid item>
                          <Typography variant={'h6'} className={styles.productCardHeader}>
                            {label}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography paragraph={true} className={styles.productCardBody}>
                            {description}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions className={styles.productCardLink}>
                      <div className={styles.launchBtn}>
                        <Typography className={styles.text}>
                          {'Launch'}
                          <img src={sideCarrot} className={styles.launchBtnCarrot} />
                        </Typography>
                      </div>
                    </CardActions>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <CreateNccerNumberModal open={ncnCreationModalOpen} onClose={handleNcnCreationModalClose} />
        <HomeConfirmProfile />
      </Grid>
    </>
  );
};
export default HomePage;
