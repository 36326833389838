import { useCallback, useEffect, useState } from 'react';
import useInterval from 'hooks/useInterval';
import UseRequestData from 'store/types/UseRequestData';

export const useRequest = <T, E = string>(request?: () => Promise<T>): UseRequestData<T, E> => {
  const [data, setData] = useState<T | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [firstLoading, setFirstloading] = useState<boolean>(true);
  const [error, setError] = useState<E | undefined>(undefined);
  const [pollingInterval, setPollingInterval] = useState<number>(0);

  useEffect(() => {
    setFirstloading(loading && !(data || error));
  }, [loading, data, error]);

  useEffect(() => {
    if (!request) {
      setLoading(false);
    }
  }, [request]);

  const refetch = useCallback(() => {
    if (request) {
      setLoading(true);
      request()
        .then((result: T) => {
          setData(result);
          setError(undefined);
        })
        .catch((errorMessage: E) => {
          setError(errorMessage);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [request]);

  useInterval(() => {
    if (request) {
      request()
        .then((result: T) => {
          setData(result);
        })
        .catch((errorMessage: E) => {
          setError(errorMessage);
        });
    }
  }, pollingInterval);

  useEffect(() => {
    if (request) {
      let cleanupFunction = false;

      setLoading(true);
      request()
        .then((result: T) => {
          if (!cleanupFunction) {
            setData(result);
            setError(undefined);
          }
        })
        .catch((errorMessage: E) => {
          if (!cleanupFunction) {
            setError(errorMessage);
          }
        })
        .finally(() => {
          if (!cleanupFunction) {
            setLoading(false);
          }
        });
      return () => {
        cleanupFunction = true;
      };
    }
    // eslint-disable-next-line
  }, [request]);

  const startPolling = useCallback((interval: number) => {
    setPollingInterval(interval);
  }, []);

  const stopPolling = useCallback(() => {
    setPollingInterval(0);
  }, []);

  return { data, loading, error, refetch, firstLoading, startPolling, stopPolling, polling: !!pollingInterval };
};
export default useRequest;
