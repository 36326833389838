export const SIDEBAR_COLLAPSED_LS_LABEL = 'sidebarCollapsed';
export const AUTH_FLAG_LS_LABEL = 'isLoggedIn';
export const IMPERSONATION_FLAG_LS_LABEL = 'isImpersonation';
export const SUPER_ADMIN_FLAG_LS_LABEL = 'isUserSuperAdmin';
export const SUPER_ADMIN_DETAILS_FLAG_LS_LABEL = 'superAdminDetails';
export const ANNOUNCEMENTS_COLLAPSED_LS_LABEL = 'announcementsCollapsed';
export const WALLET_CARD_POPUP_HIDDEN_LABEL = 'walletCardPopupHidden';

export const getLocalStorageValue = (prop: string): boolean | string => {
  const localStorageValue = localStorage.getItem(prop) || '';

  try {
    return JSON.parse(localStorageValue);
  } catch (e) {
    return '';
  }
};

export const setLocalStorageValue = (prop: string, value: boolean | string): void => {
  localStorage.setItem(prop, JSON.stringify(value));
};

export const getExpDate = (timeToLive: number): number => {
  const date = new Date();
  return date.setDate(date.getDate() + timeToLive);
};
