import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Button, Grid } from '@mui/material';
import Modal from 'components/shared/Modal';
import { FormProvider, useForm } from 'react-hook-form';
import { defaultFormProps } from 'util/Form';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { UserFormValues } from 'store/types/FormValues';
import EmailFormItem from 'components/profile/EmailFormItem';
import { ModalProps } from 'store/types/ComponentProps';
import LoginService from 'services/api/LoginService';
import SuccessModalMessage from 'components/login/SuccessModalMessage';
import { EMAIL_FIELD_NAME } from 'store/configs/FormFieldNames';

import styles from './ForgotPasswordModal.module.scss';

const ForgotPasswordModal: React.FunctionComponent<ModalProps> = ({ open, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [step, setStep] = useState<1 | 2>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const form = useForm<Pick<UserFormValues, 'email'>>({
    ...defaultFormProps,
    defaultValues: { [EMAIL_FIELD_NAME]: '' },
  });
  const { handleSubmit, formState, reset } = form;

  const handleEmailSubmit = ({ email }: Pick<UserFormValues, 'email'>) => {
    setLoading(true);
    LoginService.forgotPassword(email)
      .then(() => {
        setLoading(false);
        reset();
        setStep(2);
      })
      .catch((errorMessage: string) => {
        enqueueSnackbar(errorMessage, { variant: 'error' });
        setLoading(false);
      });
  };

  const handleClose = () => {
    onClose();
    setStep(1);
  };

  return (
    <Modal
      loading={loading}
      open={open}
      title={'Forgot Password?'}
      onClose={handleClose}
      maxWidth={'xs'}
      actions={
        step === 1 ? (
          <Button
            color={'secondary'}
            variant={'contained'}
            type={'submit'}
            onClick={handleSubmit(handleEmailSubmit)}
            disabled={!formState.isValid || !formState.isDirty || loading}
            className={styles.button}
          >
            Reset Password
          </Button>
        ) : null
      }
    >
      <Grid {...defaultGridContainerProps}>
        <Grid {...defaultGridItemProps}>
          {step === 1 && (
            <>
              <p className={styles.description}>
                Don’t worry. Resetting your password is easy. Enter your email address and check your mailbox for the
                steps to reset your password.
              </p>
              <FormProvider {...form}>
                <EmailFormItem rowProps={defaultGridItemProps} />
              </FormProvider>
            </>
          )}
          {step === 2 && (
            <SuccessModalMessage message={'Please check your mailbox for the steps to reset your password.'} />
          )}
        </Grid>
      </Grid>
    </Modal>
  );
};
export default ForgotPasswordModal;
