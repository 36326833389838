import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Grid, Link } from '@mui/material';
import ProfileForm from 'components/profile/ProfileForm';
import { UserFormValues } from 'store/types/FormValues';
import { FormProvider, useForm } from 'react-hook-form';
import Modal from 'components/shared/Modal';
import { defaultFormProps, LOGIN_SECTION_ID, LOGIN_USERNAME_FIELD_ID } from 'util/Form';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { ModalProps } from 'store/types/ComponentProps';
import LoginService from 'services/api/LoginService';
import SuccessModalMessage from 'components/login/SuccessModalMessage';
import { emptyUserFormValues } from 'util/User';
import { HttpError, HttpUtils } from 'services/HttpService';
import RequestErrorCode from 'store/enums/RequestErrorCode';
import { getEmailLink, getPhoneLink } from 'util/Format';

import commonStyles from 'styles/common.module.scss';
import styles from './SignupModal.module.scss';

const defaultValues: UserFormValues = { ...emptyUserFormValues };

interface SignupModalProps extends Pick<ModalProps, 'open'> {
  onClose: () => void;
}

const SignupModal: React.FunctionComponent<SignupModalProps> = ({ open, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<UserFormValues>({ ...defaultFormProps, defaultValues });
  const [loading, setLoading] = useState<boolean>(false);
  const { handleSubmit, formState } = form;
  const submitButtonDisabled = !formState.isValid || !formState.isDirty || loading;
  const [step, setStep] = useState<1 | 2>(1);

  useEffect(() => {
    if (!open && step !== 1) {
      setStep(1);
    }
  }, [open, step]);

  const handleLoginLinkClick = useCallback(() => {
    onClose();
    setTimeout(() => {
      const loginElement = document.getElementById(LOGIN_SECTION_ID);
      const loginFieldElement = document.getElementById(LOGIN_USERNAME_FIELD_ID);

      if (loginElement) {
        loginElement.scrollIntoView({ behavior: 'smooth' });
      }
      if (loginFieldElement) {
        loginFieldElement.focus();
      }
    });
  }, [onClose]);

  const emailAlreadyExistsError: React.ReactNode = useMemo(
    () => (
      <span>
        {'This email address is already being used. Please '}
        <Link onClick={handleLoginLinkClick} className={styles.loginLink}>
          Log In
        </Link>
        .
      </span>
    ),
    [handleLoginLinkClick]
  );

  const handleFormSubmit = useCallback(
    (formValues: UserFormValues) => {
      setLoading(true);
      LoginService.signup(formValues)
        .then(() => {
          setLoading(false);
          setStep(2);
        })
        .catch((error: HttpError) => {
          const errorCode = HttpUtils.getErrorCode(error);

          setLoading(false);
          if (errorCode === RequestErrorCode.EmailAlreadyRegistered) {
            enqueueSnackbar(emailAlreadyExistsError, { variant: 'error' });
          } else if (errorCode === RequestErrorCode.CannotMergeAccount) {
            enqueueSnackbar(
              'We found your information but there was an error merging your account. Please try again.',
              {
                variant: 'error',
              }
            );
          } else {
            enqueueSnackbar(HttpUtils.getErrorMessage(error), { variant: 'error' });
          }
        });
    },
    [enqueueSnackbar, emailAlreadyExistsError]
  );

  return (
    <FormProvider {...form}>
      {open ? (
        <Modal
          open={open}
          onClose={onClose}
          keepMounted={true}
          title={'Create New Account'}
          maxWidth={step === 1 ? 'lg' : 'xs'}
          loading={loading}
          footerClassName={classNames({ [styles.footer]: step === 1 })}
          actions={
            step === 1 ? (
              <>
                <Button onClick={onClose} className={styles.returnButton}>
                  {'Return to Sign In'}
                </Button>
                <Button
                  color={'secondary'}
                  variant={'contained'}
                  type={'submit'}
                  disabled={submitButtonDisabled}
                  onClick={handleSubmit(handleFormSubmit)}
                >
                  Save
                </Button>
              </>
            ) : null
          }
        >
          <Grid {...defaultGridContainerProps}>
            {step === 1 && (
              <>
                <Grid {...defaultGridItemProps}>
                  <p className={commonStyles.text}>
                    <>
                      {
                        'Welcome to NCCER’s new single sign-on portal. To get started, please complete the information below. If you need assistance, our Customer Service Team is happy to help. You can reach us by phone at '
                      }
                      {getPhoneLink('(888) 622-3720')}
                      {' or by email at '}
                      {getEmailLink('support@nccer.org')}.
                    </>
                    )
                  </p>
                </Grid>
                <Grid {...defaultGridItemProps}>
                  <Grid {...defaultGridContainerProps}>
                    <ProfileForm setLoading={setLoading}/>
                  </Grid>
                </Grid>
                <Grid {...defaultGridItemProps}>
                  <p className={commonStyles.text}>
                    {
                      'Your email address is used as the account login. You will be asked to confirm your email before setting up your password.'
                    }
                  </p>
                </Grid>
              </>
            )}
            {step === 2 && (
              <Grid {...defaultGridItemProps}>
                <SuccessModalMessage
                  message={'User successfully created. Please check your mailbox for the next steps.'}
                />
              </Grid>
            )}
          </Grid>
        </Modal>
      ) : null}
    </FormProvider>
  );
};
export default SignupModal;
